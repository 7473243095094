const TextAreaField = ({ label, name, value, onChange, placeholder }) => (
  <div className="pb-4">
    <label className="block text-gray-700">{label}</label>
    <textarea
      name={name}
      value={value}
      onChange={onChange}
      className="border p-2 w-full rounded"
      placeholder={placeholder}
    />
  </div>
);
export default TextAreaField;
