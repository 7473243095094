// src/redux/slices/userSlice.js

import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUsers,
  createUser,
  fetchUserById,
  modifyUser,
  updateUserClinicianInfo,
  uploadUserPicture,
  deleteUserPicture,
  fetchUserPicture,
} from "../thunks/userThunk"; // Import thunks

const userSlice = createSlice({
  name: "users",
  initialState: {
    users: [], // List of users
    currentUser: null,
    profilePicture: null, // Profile picture state
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetching users
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle creating a user
      .addCase(createUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users.push(action.payload);
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle fetching a single user
      .addCase(fetchUserById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle modifying a user
      .addCase(modifyUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(modifyUser.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.users.findIndex(
          (user) => user._id === action.payload._id
        );
        if (index !== -1) {
          state.users[index] = action.payload;
        }
        if (state.currentUser && state.currentUser._id === action.payload._id) {
          state.currentUser = action.payload;
        }
      })
      .addCase(modifyUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle updating user clinician information
      .addCase(updateUserClinicianInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserClinicianInfo.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.users.findIndex(
          (user) => user._id === action.payload._id
        );
        if (index !== -1) {
          state.users[index] = action.payload;
        }
        if (state.currentUser && state.currentUser._id === action.payload._id) {
          state.currentUser = action.payload;
        }
      })
      .addCase(updateUserClinicianInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Upload Profile Picture
      .addCase(uploadUserPicture.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadUserPicture.fulfilled, (state, action) => {
        state.loading = false;
        state.profilePicture = action.payload.profilePicture;
      })
      .addCase(uploadUserPicture.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete Profile Picture
      .addCase(deleteUserPicture.fulfilled, (state) => {
        state.profilePicture = null;
      })

      // Fetch Profile Picture
      .addCase(fetchUserPicture.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserPicture.fulfilled, (state, action) => {
        state.loading = false;
        state.profilePicture = action.payload;
      })
      .addCase(fetchUserPicture.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
