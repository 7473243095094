import { useEffect, useState } from "react";
import { CalendarDaysIcon, CashIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";

const DashboardCards = ({ appointments, waagData }) => {
    const [selectedCard, setSelectedCard] = useState(null);

    const cards = [
        {
            name: "Last Month's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: waagData?.appointments?.lastMonth || 'N/A',
        },
        {
            name: "Last Week's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: waagData?.appointments?.lastWeek || 'N/A',
        },
        {
            name: "Previous Week's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: waagData?.appointments?.prevWeek || 'N/A',
        },
        {
            name: "MTD Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: waagData?.appointments?.mtd || 'N/A',
        },
        {
            name: "Last Month's Revenue",
            href: "#",
            icon: CurrencyDollarIcon,
            amount: waagData?.revenue?.lastMonthRevenue ? `$${waagData.revenue.lastMonthRevenue.toFixed(2)}` : 'N/A',
        },
        {
            name: "Last Week's Revenue",
            href: "#",
            icon: CurrencyDollarIcon,
            amount: waagData?.revenue?.lastWeekRevenue ? `$${waagData.revenue.lastWeekRevenue.toFixed(2)}` : 'N/A',
        },
        {
            name: "This Week's Revenue",
            href: "#",
            icon: CurrencyDollarIcon,
            amount: waagData?.revenue?.thisWeekRevenue ? `$${waagData.revenue.thisWeekRevenue.toFixed(2)}` : 'N/A',
        },
        {
            name: "MTD Revenue",
            href: "#",
            icon: CurrencyDollarIcon,
            amount: waagData?.revenue?.mtdRevenue ? `$${waagData.revenue.mtdRevenue.toFixed(2)}` : 'N/A',
        },
    ];

    return (
        <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8 2xl:max-w-7xl">
            <h2 className="text-lg font-medium leading-6 text-gray-900">
                Overview
            </h2>
            <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5">
                {cards.map((card) => (
                    <div
                        key={card.name}
                        className={`overflow-hidden rounded-lg bg-white shadow cursor-pointer ${
                            selectedCard === card.name
                                ? "ring-2 ring-indigo-500"
                                : ""
                        }`}
                        onClick={() => {
                            setSelectedCard(card.name);
                        }}
                    >
                        <div className="p-5">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <card.icon
                                        className="h-6 w-6 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="ml-5 w-0 flex-1">
                                    <dl>
                                        <dt className="truncate text-sm font-medium text-gray-500">
                                            {card.name}
                                        </dt>
                                        <dd>
                                            <div className="text-lg font-medium text-gray-900">
                                                {card.amount}
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DashboardCards;
