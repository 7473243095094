import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../applicationUi/components/Table";
import SOAPForm from "../../applicationUi/components/SOAPForm";
import {
  fetchAppointmentsByClientId,
  fetchAppointmentById,
  fetchAppointmentsByClinicianId,
} from "../../store/thunks/appointmentsThunk";

const ClientSoapNotes = () => {
  const [appointments, setAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSoapForm, setShowSoapForm] = useState(false);
  const itemsPerPage = 10;

  const dispatch = useDispatch();
  const client = useSelector((state) => state.clients.selectedClient);

  const fetchAppointments = () => {
    if (client?._id) {
      setLoading(true);
      dispatch(fetchAppointmentsByClientId(client._id))
        .unwrap()
        .then((response) => {
          setAppointments(response);
        })
        .catch((error) => {
          console.error("Error fetching appointments:", error);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    fetchAppointments(); // Fetch appointments when the component mounts
  }, [client, dispatch]);

  const totalAppointments = appointments.length;
  const totalPages = Math.ceil(totalAppointments / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowClick = async (appointmentId) => {
    console.log(appointmentId);
    try {
      const appointment = await dispatch(
        fetchAppointmentById(appointmentId)
      ).unwrap();
      setSelectedAppointment(appointment); // Store the full appointment object
      setShowSoapForm(true); // Show the SOAP form modal
    } catch (error) {
      console.error("Error fetching appointment by ID:", error);
    }
  };

  const handleModalClose = () => {
    setSelectedAppointment(null);
    setShowSoapForm(false);
    fetchAppointments(); // Refresh appointments when the modal closes
  };
  
  const paginatedAppointments = appointments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      {loading ? (
        <div className="p-4 text-gray-600">Loading appointments...</div>
      ) : (
        <Table
          columns={[
            { header: "Title", accessor: "title" },
            {
              header: "Service Date",
              accessor: (row) => {
                const date = new Date(row.start);
                return date.toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                });
              },
            },
            {
              header: "SOAP Note Completed",
              accessor: (row) => (row.soapNoteCompleted ? "Yes" : "No"),
            },
            {
              header: "SOAP Note Finalized",
              accessor: (row) => (row.soapNoteFinalized ? "Yes" : "No"),
            },
          ]}
          data={paginatedAppointments}
          title="Client SOAP Notes"
          description={`List of SOAP Notes for ${client.firstName} ${client.lastName}`}
          showPagination={true}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          onRowClick={(item) => handleRowClick(item)} // Pass the appointment ID
          actions={[
            {
              label: "View",
              onClick: (item) => handleRowClick(item), // Pass the appointment ID
            },
          ]}
        />
      )}

      {showSoapForm && selectedAppointment && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
          <div className="relative bg-white rounded-lg shadow-xl max-w-7xl w-full m-4">
            <SOAPForm
              appointment={selectedAppointment} // Pass the full appointment object
              onClose={handleModalClose}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ClientSoapNotes;
