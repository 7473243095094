import { createSlice } from "@reduxjs/toolkit";
import {
  fetchMeetings,
  fetchMeetingsByDate,
  fetchMeetingsForCurrentWeek,
  checkMeetingConflicts,
  createMeeting,
  updateMeetingThunk,
  deleteMeetingThunk,
  fetchMeetingById,
} from "../thunks/meetingsThunk";

const initialState = {
  meetings: [],
  loading: false,
  error: null,
  selectedMeeting: null,
  conflicts: null,  // Holds conflict data when available
  meetingUpdated: false,
};

const meetingSlice = createSlice({
  name: "meetings",
  initialState,
  reducers: {
    setSelectedMeeting: (state, action) => {
      state.selectedMeeting = action.payload;
    },
    resetError: (state) => {
      state.error = null;
    },
    resetConflicts: (state) => {
      state.conflicts = null;
    },
    setMeetingUpdated: (state, action) => {
      state.meetingUpdated = action.payload;
    },
    resetMeetingUpdated: (state) => {
      state.meetingUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Meetings
      .addCase(fetchMeetings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMeetings.fulfilled, (state, action) => {
        state.loading = false;
        state.meetings = action.payload;
      })
      .addCase(fetchMeetings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch meetings.";
      })

      // Fetch Meetings By Date
      .addCase(fetchMeetingsByDate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMeetingsByDate.fulfilled, (state, action) => {
        state.loading = false;
        state.meetings = action.payload;
      })
      .addCase(fetchMeetingsByDate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch Meetings For Current Week
      .addCase(fetchMeetingsForCurrentWeek.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMeetingsForCurrentWeek.fulfilled, (state, action) => {
        state.loading = false;
        state.meetings = action.payload;
      })
      .addCase(fetchMeetingsForCurrentWeek.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Check Meeting Conflicts
      .addCase(checkMeetingConflicts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkMeetingConflicts.fulfilled, (state, action) => {
        state.loading = false;
        state.conflicts = action.payload;  // Store conflict data
      })
      .addCase(checkMeetingConflicts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Create Meeting
      .addCase(createMeeting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createMeeting.fulfilled, (state, action) => {
        state.loading = false;
        state.meetings.push(action.payload);
        state.meetingUpdated = true;
      })
      .addCase(createMeeting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update Meeting
      .addCase(updateMeetingThunk.fulfilled, (state, action) => {
        const index = state.meetings.findIndex(
          (meeting) => meeting._id === action.payload._id
        );
        if (index !== -1) {
          state.meetings[index] = action.payload;
          state.meetingUpdated = true;
        }
      })

      // Delete Meeting
      .addCase(deleteMeetingThunk.fulfilled, (state, action) => {
        state.meetings = state.meetings.filter(
          (meeting) => meeting._id !== action.payload
        );
        state.meetingUpdated = true;
      })

      // Fetch Meeting By ID
      .addCase(fetchMeetingById.fulfilled, (state, action) => {
        state.selectedMeeting = action.payload;
      });
  },
});

export const { setSelectedMeeting, resetError, resetConflicts, setMeetingUpdated, resetMeetingUpdated } = meetingSlice.actions;
export default meetingSlice.reducer;
