import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const MultiSelect = ({
  options,
  selectedValues = [],
  onChange,
  placeholder = "Select options...",
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState(selectedValues);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setSelected(selectedValues);
  }, [selectedValues]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleOption = (option) => {
    const isSelected = selected.find((item) => item.value === option.value);
    const updatedSelected = isSelected
      ? selected.filter((item) => item.value !== option.value)
      : [...selected, option];
    setSelected(updatedSelected);
    onChange(updatedSelected);
  };

  const filteredOptions = options
    .filter(
      (option) =>
        option.label &&
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      const isASelected = selected.some((item) => item.value === a.value);
      const isBSelected = selected.some((item) => item.value === b.value);
      return isASelected === isBSelected ? 0 : isASelected ? 1 : -1;
    });

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <div
        className="flex items-center justify-between w-full px-3 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}>
        <span className="block truncate">
          {selected.length > 0 ? `${selected.length} selected` : placeholder}
        </span>
        <span className="pointer-events-none">
          <svg
            className="w-5 h-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true">
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </div>

      {isOpen && (
        <div className="absolute z-50 w-full mt-1 bg-white rounded-md shadow-lg border border-gray-200">
          <div className="p-2 border-b border-gray-200">
            <input
              type="text"
              placeholder="Search clinicians..."
              className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <ul className="max-h-60 overflow-y-auto py-1">
            {filteredOptions.map((option) => (
              <li
                key={option.value}
                className={`px-3 py-2 text-sm cursor-pointer hover:bg-gray-100 flex items-center justify-between
                  ${
                    selected.find((item) => item.value === option.value)
                      ? "bg-primary/10 text-primary"
                      : "text-gray-700"
                  }`}
                onClick={() => toggleOption(option)}>
                <span>{option.label}</span>
                {selected.find((item) => item.value === option.value) && (
                  <svg
                    className="w-4 h-4"
                    viewBox="0 0 20 20"
                    fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </li>
            ))}
          </ul>
          {selected.length > 0 && (
            <div className="p-2 border-t border-gray-200">
              <button
                className="w-full px-3 py-2 text-sm text-red-600 hover:bg-red-50 rounded-md transition-colors duration-150"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelected([]);
                  onChange([]);
                }}>
                Clear all
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default MultiSelect;
