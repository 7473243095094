import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClinicianDashboardComponent from "./clinician/ClinicianDashboardComponent";
import LockScreen from "../../auth/lockScreen";
import QuickAddModal from "../../modals/QuickAddModals";
import ClinicScheduleModal from "../schedulePage/ClinicScheduleModal";
import AdminDashboardComponent from "./admin/AdminDashboardComponent";
import OfficeManagerDashboardComponent from "./officeManager/OfficeManagerDashboardComponent";
import OwnerDashboardComponent from "./owner/OwnerDashboardComponent";
import { fetchUsers } from "../../store/thunks/userThunk";
import AppointmentSummary from "../../applicationUi/components/appointments/AppointmentSummary";
import { setSelectedAppointment } from "../../store/slices/appointmentsSlice";
import { setClient } from "../../store/slices/clientsSlice";

function DashboardPage({ user }) {
  const selectedAppointment = useSelector((state) => state.appointments.selectedAppointment);
  const selectedClient = useSelector((state) => state.clients.selectedClient);
  const [isWideSidePanelOpen, setWideSidePanelOpen] = useState(false);
  const [isScreenLocked, setIsScreenLocked] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [rescheduleData, setRescheduleData] = useState(null);
  const [selectedDashboard, setSelectedDashboard] = useState("clinician");
  const users = useSelector((state) => state.users.users || []);
  const dispatch = useDispatch();
  const [mimicUser, setMimicUser] = useState(null);
  const selectedUser = useSelector((state) => state.auth.user);

  // Use mimicUser if set, otherwise use selectedUser
  const effectiveUser = mimicUser || selectedUser;

  // console.log(effectiveUser.role);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleAppointmentClick = useCallback((appointment) => {
    console.log("dashboard appointment click", appointment);
    dispatch(setSelectedAppointment(appointment));
    setWideSidePanelOpen(true);
  }, [dispatch]);

  const handleClientClick = useCallback((client) => {
    dispatch(setClient(client));
    setWideSidePanelOpen(true);
  }, [dispatch]);

  const handleCloseWideSidePanel = () => {
    setWideSidePanelOpen(false);
  };

  const handleReschedule = useCallback((appointment) => {
    setRescheduleData(appointment);
    setWideSidePanelOpen(false);
    setActiveModal("schedule");
  }, []);

  const handleCloseQuickAddModal = () => {
    setActiveModal(null);
  };
  console.log("userRole", effectiveUser?.role);
  const renderDashboard = useMemo(() => {
    const userRoles =
      effectiveUser?.role?.map((role) => role.toLowerCase()) || [];
    let dashboardComponents = [];

    if (userRoles.includes("clinician")) {
      dashboardComponents.push(
        <ClinicianDashboardComponent
          key="clinician-dashboard"
          handleAppointmentClick={handleAppointmentClick}
          handleClientClick={handleClientClick}
          handleReschedule={handleReschedule}
          clinician={effectiveUser}
        />
      );
    }

    if (userRoles.includes("admin")) {
      dashboardComponents.push(
        <AdminDashboardComponent
          key="admin-dashboard"
          handleAppointmentClick={handleAppointmentClick}
          handleClientClick={handleClientClick}
          handleReschedule={handleReschedule}
          clinician={effectiveUser}
        />
      );
    }
console.log(userRoles);
    if (userRoles.includes("officemanager")) {
      dashboardComponents.push(
        <OfficeManagerDashboardComponent
          key="officeManager-dashboard"
          handleAppointmentClick={handleAppointmentClick}
          handleClientClick={handleClientClick}
          handleReschedule={handleReschedule}
          clinician={effectiveUser}
        />
      );
    }

    if (userRoles.includes("owner")) {
      dashboardComponents.push(
        <OwnerDashboardComponent
          key="owner-dashboard"
          handleAppointmentClick={handleAppointmentClick}
          handleClientClick={handleClientClick}
          handleReschedule={handleReschedule}
          clinician={effectiveUser}
        />
      );
    }

    if (dashboardComponents.length === 0) {
      return <div>No recognized roles</div>;
    }

    return <>{dashboardComponents}</>;
  }, [
    effectiveUser,
    handleAppointmentClick,
    handleClientClick,
    handleReschedule,
  ]);

  return (
    <>
      {isScreenLocked && <LockScreen />}
      {process.env.NODE_ENV === 'development' && (
        <div>
          <label>Select User:</label>
          <select
            value={effectiveUser?._id || ""}
            onChange={(e) =>
              setMimicUser(
                users.find((u) => u._id === e.target.value) || null
              )
            }
          >
            {Array.isArray(users) &&
              users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.firstName} {user.lastName}
                </option>
              ))}
          </select>

          <label>Select Dashboard:</label>
          <select
            value={selectedDashboard}
            onChange={(e) => setSelectedDashboard(e.target.value)}
          >
            <option value="clinician">Clinician</option>
            <option value="admin">Admin</option>
            <option value="owner">Owner</option>
            <option value="officeManager">Office Manager</option>
          </select>
        </div>
      )}

      {renderDashboard}

      {selectedAppointment && (
        <AppointmentSummary
          title={"Appointment Summary"}
          open={isWideSidePanelOpen}
          onClose={handleCloseWideSidePanel}
          appointment={selectedAppointment}
          onAppointmentClick={handleAppointmentClick}
          onReschedule={handleReschedule}
        />
      )}
      <QuickAddModal
        activeModal={activeModal}
        onClose={handleCloseQuickAddModal}
      />
      {activeModal === "schedule" && (
        <ClinicScheduleModal
          setModalOpen={(state) => {
            setActiveModal(state ? "schedule" : null);
          }}
          selectedClient={rescheduleData?.client}
          selectedService={rescheduleData?.service}
          selectedProduct={rescheduleData?.product}
          selectedClinician={rescheduleData?.clinician}
          rescheduleData={rescheduleData}
        />
      )}
    </>
  );
}

export default DashboardPage;
