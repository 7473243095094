const BeginIntakeSection = ({ onStart }) => {
  return (
    <div className="text-center">
      <h1 className="text-xl font-bold mb-4">Start Intake Form</h1>
      <p className="mb-6">
        Your progress will be saved after each section. You can return to this
        link anytime to complete the form.
      </p>
      <button onClick={onStart} className="btn btn-primary">
        Start
      </button>
    </div>
  );
};

export default BeginIntakeSection;
