import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchQboConnectionStatus } from "../../store/thunks/qboThunk";
import QuickBooksConnectButton from "./QuickBooksConnectButton";
import QuickBooksSuccess from "./QuickBookSuccess";
import QuickBooksCashFlow from "./QuickBookCashFlow";
import QuickBooksSalesReport from "./QuickBookSalesReport";
import QuickBooksInvoices from "./QuickBooksInvoices";
import UploadSchedule from "./UploadSchedule";
import UploadClients from "./UploadClients";
import UploadProducts from "./UploadProducts";
import UploadAppointments from "./UploadAppointments";
import UploadServices from "./UploadServices";

const MODULES = [
  { name: "Cash Flow Summary", component: <QuickBooksCashFlow /> },
  { name: "Sales Report", component: <QuickBooksSalesReport /> },
  { name: "Invoices", component: <QuickBooksInvoices /> },
  { name: "Schedule", component: <UploadSchedule /> },
  { name: "Clients", component: <UploadClients /> },
  { name: "Products", component: <UploadProducts /> },
  { name: "Services", component: <UploadServices /> },
  { name: "Appointments", component: <UploadAppointments /> },
];

function QuickBooks() {
  const dispatch = useDispatch();
  const isConnected = useSelector((state) => state.qbo.isConnected); // Connection status from Redux
  const status = useSelector((state) => state.qbo.status); // Loading/success/failure status
  const [selectedModule, setSelectedModule] = useState(null);

  // Fetch QuickBooks connection status on mount
  useEffect(() => {
    dispatch(fetchQboConnectionStatus());
  }, [dispatch, isConnected]);

  return (
    <div>
      <div className="container mx-auto mt-5 p-4 bg-white rounded-lg shadow-lg text-center">
        <h1 className="text-2xl font-semibold mb-4">QuickBooks Integration</h1>
        {status === "loading" ? (
          <p className="text-gray-500">Checking QuickBooks connection...</p>
        ) : (
          <>
            <QuickBooksConnectButton />
            <QuickBooksSuccess />
          </>
        )}
      </div>

      <div className="flex justify-center gap-2 mt-4">
        {MODULES.map((module) => (
          <button
            key={module.name}
            onClick={() => setSelectedModule(module.name)}
            className="rounded bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
          >
            {module.name}
          </button>
        ))}
      </div>

      <div className="mt-4">
        {MODULES.map(
          (module) =>
            selectedModule === module.name && (
              <div key={module.name}>{module.component}</div>
            )
        )}
      </div>
    </div>
  );
}

export default QuickBooks;
