import CheckboxGroup from "../../applicationUi/components/intake/CheckboxGroup";
import RadioGroup from "../../applicationUi/components/intake/RadioGroup";
import InputField from "../../applicationUi/components/intake/InputField";
import TextAreaField from "../../applicationUi/components/intake/TextAreaField"; // Assuming you have this component for text areas

const PreschoolLanguageSection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold">A - Preschool Language</h2>

    {/* Less than 50 Words RadioGroup */}
    <RadioGroup
      label="Does your child have less than 50 words?"
      name="preschoolLanguage.lessThan50Words"
      options={[
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
      ]}
      selectedValue={formData.preschoolLanguage.lessThan50Words}
      onChange={handleChange}
    />

    {/* If No, move to Section B */}
    {formData.preschoolLanguage.lessThan50Words === "no" && (
      <p className="text-sm text-gray-500">Please proceed to Section B.</p>
    )}

    {/* Show only if less than 50 words */}
    {formData.preschoolLanguage.lessThan50Words === "yes" && (
      <>
        {/* CheckboxGroup for knowledge of names, numbers, etc. */}
        <CheckboxGroup
          label="Does your child know the following?"
          namePrefix="preschoolLanguage"
          options={[
            { name: "knowsName", label: "Their Name" },
            { name: "knowsNumbers1to10", label: "Numbers 1-10" },
            { name: "knowsBodyParts", label: "Body Parts" },
            { name: "knowsBasicColors", label: "Basic Colors" },
          ]}
          selectedOptions={formData.preschoolLanguage}
          onChange={handleChange}
        />

        {/* CheckboxGroup for understanding short requests, stories, etc. */}
        <CheckboxGroup
          label="Does your child understand the following?"
          namePrefix="preschoolLanguage"
          options={[
            {
              name: "understandsShortRequests",
              label: "Short requests (e.g., 'Get your shoes')",
            },
            {
              name: "understandsStories",
              label: "Stories like 'The Three Bears'",
            },
            {
              name: "understandsChildrensTvPrograms",
              label: "Children's TV programs",
            },
            {
              name: "understandsAdultConversations",
              label: "Adult conversations",
            },
          ]}
          selectedOptions={formData.preschoolLanguage}
          onChange={handleChange}
        />

        {/* CheckboxGroup for communication methods */}
        <CheckboxGroup
          label="Does your child communicate in the following ways? (Check all that apply)"
          namePrefix="preschoolLanguage.communicates"
          options={[
            {
              name: "eyeContactBodyMovements",
              label: "Eye contact / Body movements",
            },
            { name: "sounds", label: "Sounds" },
            { name: "gestures", label: "Gestures" },
          ]}
          selectedOptions={formData.preschoolLanguage.communicates}
          onChange={handleChange}
        />

        {/* Speech Understanding - Drop-down */}
        <InputField
          label="Can you understand your child's speech?"
          name="preschoolLanguage.understandingSpeech"
          value={formData.preschoolLanguage.understandingSpeech}
          onChange={handleChange}
        />

        {/* Communication with Other Children */}
        <CheckboxGroup
          label="How does your child communicate and interact with other children? (Check all that apply)"
          namePrefix="preschoolLanguage.communicatesWithOtherChildren"
          options={[
            {
              name: "eyeContactBodyMovements",
              label: "Eye contact / Body movements",
            },
            { name: "playsAlone", label: "Not at all; plays alone" },
          ]}
          selectedOptions={
            formData.preschoolLanguage.communicatesWithOtherChildren
          }
          onChange={handleChange}
        />

        <TextAreaField
          label="Other (please specify)"
          name="preschoolLanguage.communicatesWithOtherChildren.other"
          value={formData.preschoolLanguage.communicatesWithOtherChildren.other}
          onChange={handleChange}
        />

        {/* What does your child like to communicate most? */}
        <CheckboxGroup
          label="What does your child like to communicate most to you? (Check all that apply)"
          namePrefix="preschoolLanguage.whatChildLikesToCommunicate"
          options={[
            {
              name: "whatTheyAreDoing",
              label: "What they are doing at the time",
            },
            { name: "whatYouAreDoing", label: "What you are doing" },
          ]}
          selectedOptions={
            formData.preschoolLanguage.whatChildLikesToCommunicate
          }
          onChange={handleChange}
        />

        <TextAreaField
          label="Other (please specify)"
          name="preschoolLanguage.whatChildLikesToCommunicate.other"
          value={formData.preschoolLanguage.whatChildLikesToCommunicate.other}
          onChange={handleChange}
        />
      </>
    )}
  </section>
);

export default PreschoolLanguageSection;
