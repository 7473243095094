import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSelectedMenuItem, setNavigation, setClickedItem, setActiveModal } from '../slices/appStateSlice';

// Async thunk for setting the selected menu item
export const setSelectedMenuItemAsync = createAsyncThunk(
  'appState/setSelectedMenuItemAsync',
  async (menuItem, { dispatch }) => {
    try {
      dispatch(setSelectedMenuItem(menuItem));
      return menuItem;
    } catch (error) {
      console.error('Error setting selected menu item:', error);
      throw error;
    }
  }
);

// Async thunk for setting the navigation
export const setNavigationAsync = createAsyncThunk(
  'appState/setNavigationAsync',
  async (navigationItems, { dispatch }) => {
    try {
      dispatch(setNavigation(navigationItems));
      return navigationItems;
    } catch (error) {
      console.error('Error setting navigation:', error);
      throw error;
    }
  }
);

// Async thunk for setting the clicked item
export const setClickedItemAsync = createAsyncThunk(
  'appState/setClickedItemAsync',
  async (item, { dispatch }) => {
    try {
      dispatch(setClickedItem(item));
      return item;
    } catch (error) {
      console.error('Error setting clicked item:', error);
      throw error;
    }
  }
);

// Async thunk for handling menu item click
export const handleMenuItemClickAsync = createAsyncThunk(
  'appState/handleMenuItemClickAsync',
  async (name, { dispatch }) => {
    try {
      await dispatch(setSelectedMenuItemAsync(name));
      await dispatch(setClickedItemAsync(null));
      return name;
    } catch (error) {
      console.error('Error handling menu item click:', error);
      throw error;
    }
  }
);

// Async thunk for setting the active modal
export const setActiveModalAsync = createAsyncThunk(
  'appState/setActiveModalAsync',
  async (modalName, { dispatch }) => {
    try {
      dispatch(setActiveModal(modalName));
      return modalName;
    } catch (error) {
      console.error('Error setting active modal:', error);
      throw error;
    }
  }
);

// Async thunk for handling search result click
export const handleSearchResultClickAsync = createAsyncThunk(
  'appState/handleSearchResultClickAsync',
  async (clickedItem, { dispatch }) => {
    try {
      if (typeof clickedItem === 'object' && clickedItem !== null) {
        switch (clickedItem.type) {
          case "Client":
            await dispatch(setSelectedMenuItemAsync("Clients"));
            await dispatch(setClickedItemAsync(clickedItem));
            break;
          case "Dashboard":
          case "Clinicians":
          case "Schedule":
          case "Forms":
          case "Reports":
          case "HR":
          case "Manager":
          case "QuickBooks":
          case "Clinic":
          case "Payroll":
          case "HR Payroll":
          case "Services":
          case "Invoices":
          case "Intake":
            await dispatch(handleMenuItemClickAsync(clickedItem.type));
            await dispatch(setClickedItemAsync(clickedItem));
            break;
          default:
            console.log("Unhandled item clicked:", clickedItem);
        }
      } else {
        console.log("Invalid clickedItem:", clickedItem);
      }
      return clickedItem;
    } catch (error) {
      console.error('Error handling search result click:', error);
      throw error;
    }
  }
);
