import { createSlice } from '@reduxjs/toolkit';
import { fetchRoles, fetchRoleById, createRole, updateRole, deleteRole } from '../thunks/rolesThunk';

export const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    roles: [],
    status: "idle",
    error: null,
    selectedRole: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      // Fetch all roles
      .addCase(fetchRoles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.roles = action.payload;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Fetch single role
      .addCase(fetchRoleById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRoleById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selectedRole = action.payload;
      })
      .addCase(fetchRoleById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Create role
      .addCase(createRole.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.roles.push(action.payload);
      })
      .addCase(createRole.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Update role
      .addCase(updateRole.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.roles.findIndex(role => role._id === action.payload._id);
        if (index !== -1) {
          state.roles[index] = action.payload;
        }
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Delete role
      .addCase(deleteRole.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.roles = state.roles.filter(role => role._id !== action.payload);
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default rolesSlice.reducer;
