import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClientNote,
  createClientNote,
  updateClientNote,
} from "../../store/thunks/clientNotesThunk";

const ClientNotes = ({ client }) => {
  const dispatch = useDispatch();
  const [noteContent, setNoteContent] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const note = useSelector((state) => state.clientNotes.note);
  const loading = useSelector((state) => state.clientNotes.loading);
  const error = useSelector((state) => state.clientNotes.error);

  useEffect(() => {
    dispatch(fetchClientNote(client._id));
  }, [client._id, dispatch]);

  // Set the latest content from history as the current note when note changes in the state
  useEffect(() => {
    const latestContent = note?.history?.slice(-1)[0]?.content || "";
    setNoteContent(latestContent);
  }, [note]);

  const handleNoteChange = (e) => setNoteContent(e.target.value);

  const handleSaveNote = async () => {
    if (!noteContent.trim()) return;
    setIsSubmitting(true);

    try {
      if (note) {
        await dispatch(
          updateClientNote({ clientId: client._id, content: noteContent })
        ).unwrap();
      } else {
        await dispatch(
          createClientNote({ clientId: client._id, content: noteContent })
        ).unwrap();
      }
      setIsEditing(false);
      dispatch(fetchClientNote(client._id));
    } catch (error) {
      console.error("Error saving note:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEdit = () => setIsEditing(true);

  return (
    <div className="bg-white w-full p-6 rounded-lg shadow-lg">
      <h2 className="text-xl font-semibold mb-4">{`Notes for ${client.firstName} ${client.lastName}`}</h2>

      {error && error.error && (
        <div className="text-red-500 mb-2">{error.error}</div>
      )}

      <textarea
        className="w-full border border-gray-300 p-2 rounded-md mb-4"
        placeholder="Type your note here..."
        rows="6"
        value={noteContent}
        onChange={handleNoteChange}
        disabled={!isEditing || isSubmitting || loading}
      />

      <div className="flex justify-end space-x-2">
        {isEditing ? (
          <button
            onClick={handleSaveNote}
            className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            disabled={isSubmitting || loading}>
            {isSubmitting ? "Saving..." : note ? "Save Note" : "Create Note"}
          </button>
        ) : (
          <button
            onClick={handleEdit}
            className="rounded-md bg-gray-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500">
            {note ? "Edit Note" : "Start creating note"}
          </button>
        )}
      </div>

      {note && note.history && note.history.length > 0 ? (
        <div className="mt-6">
          <h3 className="text-lg font-semibold">Note History</h3>
          {note.history
            .slice()
            .reverse()
            .map((historyItem, index) => (
              <div
                key={index}
                className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 mt-2">
                <div className="flex justify-between gap-x-4">
                  <div className="py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {historyItem.modifiedBy?.firstName}{" "}
                      {historyItem.modifiedBy?.lastName}
                    </span>{" "}
                    modified
                  </div>
                  <time
                    dateTime={historyItem.modifiedAt}
                    className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                    {new Date(historyItem.modifiedAt).toLocaleString()}
                  </time>
                </div>
                <p className="text-sm leading-6 text-gray-500">
                  {historyItem.content}
                </p>
              </div>
            ))}
        </div>
      ) : (
        <div className="mt-6 text-gray-500">No history available.</div>
      )}
    </div>
  );
};

export default ClientNotes;
