import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

// Fetch all roles
export const fetchRoles = createAsyncThunk(
  "roles/fetchAll",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
    //   const clinicId = state.clinics.selectedClinic?._id;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(`${baseURL}/api/roles`, {
        ...config,
        // params: { clinicId }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Fetch a single role by ID
export const fetchRoleById = createAsyncThunk(
  "roles/fetchById",
  async (roleId, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const clinicId = state.clinics.selectedClinic?._id;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/roles/${roleId}`,
        {
          ...config,
          params: { clinicId }
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Create a new role
export const createRole = createAsyncThunk(
  "roles/create",
  async (roleData, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const clinicId = state.clinics.selectedClinic?._id;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/roles`,
        { ...roleData, clinicId },
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Update a role
export const updateRole = createAsyncThunk(
  "roles/update",
  async ({ id, roleData }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const clinicId = state.clinics.selectedClinic?._id;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.put(
        `${baseURL}/api/roles/${id}`,
        { ...roleData, clinicId },
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Delete a role
export const deleteRole = createAsyncThunk(
  "roles/delete",
  async (roleId, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const clinicId = state.clinics.selectedClinic?._id;
      const { baseURL, config } = generateConfig({ token });

      await axios.delete(`${baseURL}/api/roles/${roleId}`, {
        ...config,
        params: { clinicId }
      });
      return roleId; // Return the deleted role's ID
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);
