import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { fetchOfficeManagerData } from "../../../store/thunks/waagThunk";
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const UserCards = ({ appointments = [], onFilterAppointments }) => {
    const dispatch = useDispatch();
    const waagData = useSelector(state => state.waag.officeManagerData) || {};
    const { counts = {}, activeClientTrend = {} } = waagData;
    const [selectedCard, setSelectedCard] = useState(null);
    const [showGraph, setShowGraph] = useState(false);

    useEffect(() => {
        dispatch(fetchOfficeManagerData());
    }, [dispatch]);

    const now = new Date();
    const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
    const startOfYesterday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 1));
    const startOfTomorrow = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1));
    const startOfWeek = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - now.getUTCDay()));
    const startOfThisWeek = new Date(startOfWeek);
    const startOfLastWeek = new Date(startOfWeek);
    startOfLastWeek.setUTCDate(startOfLastWeek.getUTCDate() - 7);
    const startOfNextWeek = new Date(startOfWeek);
    startOfNextWeek.setUTCDate(startOfNextWeek.getUTCDate() + 7);
    const startOfMonth = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1));
    const startOfLastMonth = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() - 1, 1));
    const endOfLastMonth = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 0));

    const chartData = {
        labels: activeClientTrend?.dates || [],
        datasets: [
            {
                label: 'Active Clients',
                data: activeClientTrend?.counts || [],
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
            }
        ]
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Active Clients Trend'
            }
        }
    };

    const cards = [
        {
            name: "Current Active Clients",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts?.currentActiveClients || 0,
            onClick: () => {
                setShowGraph(prev => !prev);
                setSelectedCard("Current Active Clients");
            }
        },
        {
            name: "Clients at Risk",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts?.atRisk || 0,
            filter: (appointments) => appointments.filter(
                appointment => appointment.clientHasNoFutureAppointments // Adjust as per your data logic
            ),
        },
        {
            name: "Last Month's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts?.lastMonth || 0,
            filter: (appointments) => appointments.filter(
                appointment => new Date(appointment.start) >= startOfLastMonth && new Date(appointment.start) <= endOfLastMonth
            ),
        },
        {
            name: "Last Week's Appointments", 
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts?.lastWeek || 0,
            filter: (appointments) => appointments.filter(
                appointment => new Date(appointment.start) >= startOfLastWeek && new Date(appointment.start) < startOfWeek
            ),
        },
        {
            name: "Yesterday's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts?.yesterday || 0,
            filter: (appointments) => appointments.filter(
                appointment => new Date(appointment.start) >= startOfYesterday && new Date(appointment.start) < startOfToday
            ),
        },
        {
            name: "Today's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts?.today || 0,
            filter: (appointments) => appointments.filter(
                appointment => new Date(appointment.start) >= startOfToday && new Date(appointment.start) < startOfTomorrow
            ),
        },
        {
            name: "This Week's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts?.thisWeek || 0,
            filter: (appointments) => appointments.filter(
                appointment => new Date(appointment.start) >= startOfThisWeek && new Date(appointment.start) < new Date(startOfNextWeek.getTime() + 24 * 60 * 60 * 1000)
            ),
        },
        {
            name: "Next Week's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts?.nextWeek || 0,
            filter: (appointments) => appointments.filter(
                appointment => new Date(appointment.start) >= startOfNextWeek && new Date(appointment.start) < new Date(startOfNextWeek.getTime() + 7 * 24 * 60 * 60 * 1000)
            ),
        },
    ];

    return (
        <div className="mt-8">
            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                <h2 className="text-lg font-medium leading-6 text-gray-900">Overview</h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    {cards.map((card) => (
                        <div
                            key={card.name}
                            className={`overflow-hidden rounded-lg bg-white shadow cursor-pointer ${selectedCard === card.name ? 'ring-2 ring-indigo-500' : ''}`}
                            onClick={() => {
                                if (card.onClick) {
                                    card.onClick();
                                } else {
                                    setSelectedCard(card.name);
                                    setShowGraph(false);
                                    onFilterAppointments(card.filter);
                                }
                            }}
                        >
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <card.icon
                                            className="h-6 w-6 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="truncate text-sm font-medium text-gray-500">
                                                {card.name}
                                            </dt>
                                            <dd>
                                                <div className="text-lg font-medium text-gray-900">
                                                    {card.amount}
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {showGraph && (
                    <div className="mt-6 bg-white p-6 rounded-lg shadow">
                        <Line data={chartData} options={chartOptions} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserCards;
