import axios from "axios";
import { store } from "../store/store";
import { generateConfig } from "./api";

const logLevels = {
  ERROR: "error",
  WARN: "warn",
  INFO: "info",
  DEBUG: "debug",
};

const frontendLogger = async (level, message, meta = {}) => {
  if (!Object.values(logLevels).includes(level)) {
    console.error("Invalid log level");
    return;
  }

  try {
    const state = store.getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    const logData = {
      level,
      message,
      meta: {
        ...meta,
        userAgent: navigator.userAgent,
        url: window.location.href,
        userId: state.auth.user?._id || "unauthenticated", // Include user ID if available
      },
    };

    // console.log("logData", logData);

    await axios.post(`${baseURL}/api/frontend-logs`, logData, config);
  } catch (error) {
    console.error("Error sending log to server:", error);
  }
};

export const logError = (message, meta = {}) =>
  frontendLogger(logLevels.ERROR, message, meta);
export const logWarn = (message, meta = {}) =>
  frontendLogger(logLevels.WARN, message, meta);
export const logInfo = (message, meta = {}) =>
  frontendLogger(logLevels.INFO, message, meta);
export const logDebug = (message, meta = {}) =>
  frontendLogger(logLevels.DEBUG, message, meta);

export default frontendLogger;
