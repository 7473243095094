import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const fetchHourlyPayroll = createAsyncThunk(
  "hourlyPayroll/fetch",
  async ({ userId, payPeriod }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/hourlyPayroll/${userId}/${payPeriod}`,
        config
      );

      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

export const updateHourlyPayroll = createAsyncThunk(
  "hourlyPayroll/update",
  async ({ userId, payPeriod, hours }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.patch(
        `${baseURL}/api/hourlyPayroll/${userId}/${payPeriod}`,
        { hours },
        config
      );

      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

export const submitHourlyPayroll = createAsyncThunk(
  "hourlyPayroll/submit",
  async ({ userId, payPeriod }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.patch(
        `${baseURL}/api/hourlyPayroll/submit/${userId}/${payPeriod}`,
        {},
        config
      );

      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);
