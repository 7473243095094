import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const fetchClientJournal = createAsyncThunk(
  "clientJournal/fetch",
  async (clientId, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/clientJournal/${clientId}`,
        config
      );

      if (response.status === 200) {
        return response.data; // Successfully fetched journal data
      }

      if (response.status === 404) {
        return rejectWithValue(
          "No journal data found for the specified client."
        );
      }

      // Handle unexpected statuses
      return rejectWithValue(
        `Unexpected response status: ${response.status}. Please try again.`
      );
    } catch (error) {
      // Check if it's a client-side or server-side error and handle accordingly
      if (error.response?.status === 400) {
        return rejectWithValue(
          "Invalid client ID format or missing client ID."
        );
      }

      if (error.response?.status === 404) {
        return rejectWithValue(
          "No journal data found for the specified client."
        );
      }

      return handleApiError(error, rejectWithValue);
    }
  }
);
