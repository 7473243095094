import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { fetchUsers } from '../../store/thunks/userThunk';
import { addStaffToClinic } from '../../store/thunks/clinicThunk';
import { logInfo, logError } from '../../utils/logger';
import CircularButton from '../../applicationUi/components/CircularButton';

const AddStaffToClinicModal = ({ isOpen, onClose, clinicId, currentStaff }) => {
    const dispatch = useDispatch();
    const [selectedStaff, setSelectedStaff] = useState(null);
    const { users, status: usersStatus, error: usersError } = useSelector((state) => state.users);

    useEffect(() => {
        if (isOpen) {
            dispatch(fetchUsers())
                .unwrap()
                .then(() => {
                    logInfo('Users fetched successfully for staff selection');
                })
                .catch((error) => {
                    logError('Failed to fetch users for staff selection', { error });
                });
        }
    }, [dispatch, isOpen]);

    const availableStaff = users.filter(user => 
        !currentStaff.some(staff => staff._id === user._id)
    );

    const staffOptions = availableStaff.map(user => ({
        value: user._id,
        label: `${user.name} (${user.email})`
    }));

    const handleAddStaff = () => {
        if (selectedStaff) {
            dispatch(addStaffToClinic({ clinicId, staffId: selectedStaff.value }))
                .unwrap()
                .then(() => {
                    logInfo('Staff added to clinic successfully', { clinicId, staffId: selectedStaff.value });
                    onClose();
                })
                .catch((error) => {
                    logError('Failed to add staff to clinic', { error, clinicId, staffId: selectedStaff.value });
                });
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <div className="mt-3 text-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Add Staff to Clinic</h3>
                    <div className="mt-2 px-7 py-3">
                        {usersStatus === 'loading' && <p>Loading available staff...</p>}
                        {usersError && <p className="text-red-500">Error: {usersError}</p>}
                        {availableStaff.length === 0 && <p>No available staff to add.</p>}
                        {availableStaff.length > 0 && (
                            <Select
                                value={selectedStaff}
                                onChange={setSelectedStaff}
                                options={staffOptions}
                                className="w-full mb-4"
                                placeholder="Select a staff member"
                            />
                        )}
                    </div>
                    <div className="items-center px-4 py-3">
                        <CircularButton onClick={handleAddStaff} disabled={!selectedStaff} className="mr-2">
                            Add Staff
                        </CircularButton>
                        <CircularButton onClick={onClose} variant="secondary">
                            Cancel
                        </CircularButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddStaffToClinicModal;
