import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTodos } from "../../../store/thunks/todoThunk";
import ToDoItem from "../../../applicationUi/components/toDoItem";
import Accordion from "../../../applicationUi/components/Accordion";
import { logInfo, logError } from "../../../utils/logger";

function ClinicianToDoList({ onReschedule, onWideSidePanelClose }) {
  const { todos, loading, error } = useSelector((state) => state.todos);

  useEffect(() => {
    logInfo('ClinicianToDoList rendered', {
      componentName: 'ClinicianToDoList',
      todosCount: todos ? Object.values(todos).flat().length : 0,
      loading,
      hasError: !!error
    });
  }, [todos, loading, error]);

  if (loading) {
    logInfo('ClinicianToDoList loading', { componentName: 'ClinicianToDoList' });
    return <div>Loading...</div>;
  }

  if (error) {
    logError('Error in ClinicianToDoList', {
      componentName: 'ClinicianToDoList',
      error: error.message || error
    });
    return <div>Error: {error}</div>;
  }

  if (!todos || typeof todos !== "object") {
    logInfo('No todos found in ClinicianToDoList', { componentName: 'ClinicianToDoList' });
    return <div>No To-Do items found.</div>;
  }

  const totalTodos = Object.values(todos).flat().length;

  return (
    <div>
      <h2 className="mx-auto mt-8 max-w-6xl text-lg font-medium leading-6 text-gray-900">
        To Do List - {totalTodos} items
      </h2>
      <div className="mt-2 mx-auto max-w-8xl max-h-112 overflow-y-auto divide-y divide-gray-200">
        {Object.entries(todos).map(([category, todosInCategory]) => {
          logInfo('Rendering todo category', {
            componentName: 'ClinicianToDoList',
            category,
            todoCount: Array.isArray(todosInCategory) ? todosInCategory.length : 0
          });
          return (
            <Accordion
              key={category}
              title={`${category} (${
                Array.isArray(todosInCategory) ? todosInCategory.length : 0
              })`}>
              <ul>
                {Array.isArray(todosInCategory) ? (
                  todosInCategory.map((todo) => (
                    <ToDoItem
                      key={todo._id}
                      todo={todo}
                      onReschedule={onReschedule}
                      onWideSidePanelClose={onWideSidePanelClose}
                    />
                  ))
                ) : (
                  <li>No items found in this category</li>
                )}
              </ul>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
}

export default ClinicianToDoList;
