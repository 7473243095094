import InputField from "../../applicationUi/components/intake/InputField";
import TextAreaField from "../../applicationUi/components/intake/TextAreaField";

const CodeOfConductSection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold">N - Code of Conduct</h2>

    <p className="mb-4">
      This Code for Conduct identifies the standard of behaviour which is
      expected of all HearSay clients, including client families and guests who
      may attend onsite therapies and meetings (noted as “We”).
    </p>

    <p className="mb-4">
      <strong>HEARSAY COMMITMENT</strong>
      <br />
      HearSay is committed to providing an environment in which all individuals
      are treated with respect. We shall conduct ourselves, at all times, in a
      manner consistent with the values of HearSay, which include:
    </p>

    {/* Ordered List for HearSay Commitment */}
    <ol className="list-decimal pl-6 mb-4">
      <li>Fairness</li>
      <li>Integrity</li>
      <li>Mutual Respect</li>
    </ol>

    <p className="mb-4">
      We shall not engage in any activity or behaviour which endangers the
      safety of others. HearSay staff have a duty to report if they suspect a
      child is in need of help, and they will contact the Children’s Aid
      Society.
    </p>

    {/* Client/Guardian Signature */}
    <TextAreaField
      label="Client/Parent/Guardian Name and Signature"
      name="codeOfConduct.clientSignature"
      value={formData.codeOfConduct.clientSignature}
      onChange={handleChange}
      placeholder="Client/Guardian Name, Signature, Date"
    />

    {/* Agree to Code of Conduct */}
    <div className="mt-4">
      <input
        type="checkbox"
        name="codeOfConduct.agreeToCodeOfConduct"
        checked={formData.codeOfConduct.agreeToCodeOfConduct}
        onChange={handleChange}
        className="mr-2"
      />
      <label htmlFor="codeOfConduct.agreeToCodeOfConduct">
        I have read, understand, and agree to comply with the HearSay Code of
        Conduct.
      </label>
    </div>

    {/* Date of Agreement */}
    <InputField
      label="Date of Agreement"
      name="codeOfConduct.date"
      value={formData.codeOfConduct.date}
      onChange={handleChange}
    />
  </section>
);

export default CodeOfConductSection;
