import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CalendarIcon,
  UserPlusIcon,
  PhoneIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import CircularButton from "./CircularButton";
import IncrementButton from "./IncrementButton";
import Search from "./Search";
import { format } from "date-fns";
import ProfilePictureModal from "../../modals/ProfilePictureModal";
import { fetchUserPicture } from "../../store/thunks/userThunk";

function UserBanner({
  onQuickAddClientClick,
  onQuickAddApptClick,
  onScheduleModalClick,
  user,
  onSearchResultClick,
}) {
  const dispatch = useDispatch();
  const [profilePicture, setProfilePicture] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [tooltip, setTooltip] = useState(null); // State for tooltip text

  useEffect(() => {
    const fetchPicture = async () => {
      const result = await dispatch(fetchUserPicture({ userId: user._id }));
      setProfilePicture(result.payload || "https://via.placeholder.com/150");
    };
    fetchPicture();
  }, [dispatch, user._id]);

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = async () => {
    setIsModalOpen(false);
    const result = await dispatch(fetchUserPicture({ userId: user._id }));
    setProfilePicture(result.payload || "https://via.placeholder.com/150");
  };

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    return currentHour < 12
      ? "Good Morning"
      : currentHour < 18
      ? "Good Afternoon"
      : "Good Evening";
  };

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="bg-white shadow border border-gray-200 rounded-md">
      <div className="px-4 sm:px-6 lg:mx-auto">
        <div className="py-6 md:flex md:items-center md:justify-between">
          <div className="relative flex items-center">
            <div className="relative">
              <img
                className="h-16 w-16 rounded-full object-cover"
                src={profilePicture}
                alt="Profile"
              />
              <button
                className="absolute bottom-[-6px] right-[-6px] bg-white rounded-full p-1 shadow z-10"
                onClick={handleOpenModal}>
                <PencilIcon className="h-4 w-4 text-gray-700" />
              </button>
            </div>

            <div className="ml-3">
              <h1 className="text-xl font-bold text-gray-900">
                {getGreeting()}, {user.firstName} {user.lastName}
              </h1>
              <dl className="mt-2 flex flex-wrap text-sm font-medium text-gray-500">
                {user.role?.length ? (
                  user.role.map((role, index) => (
                    <span key={index}>
                      {role}
                      {index < user.role.length - 1 && ", "}
                    </span>
                  ))
                ) : (
                  <span>No role assigned</span>
                )}
              </dl>
            </div>
          </div>

          <div className="flex items-center justify-between w-[60%]">
            {/* Quick Actions */}
            <div className="relative p-4 border border-gray-400 rounded-md w-full">
              <div className="absolute inset-x-0 top-[-10px] flex justify-center">
                <label className="bg-white px-4 text-sm font-medium text-gray-400">
                  Quick Actions
                </label>
              </div>

              <div className="flex items-center space-x-4">
                {/* Search Bar */}
                <div className="flex-grow">
                  <Search onResultClick={onSearchResultClick} />
                </div>

                {/* Quick Action Buttons */}
                <div className="flex space-x-3">
                  <div
                    onMouseEnter={() => setTooltip("Quick Add Client")}
                    onMouseLeave={() => setTooltip(null)}
                    className="relative">
                    <CircularButton
                      icon={<UserPlusIcon className="h-6 w-6" />}
                      onClick={onQuickAddClientClick}
                      ariaLabel="Quick Add Client"
                    />
                    {tooltip === "Quick Add Client" && (
                      <div className="absolute bottom-full mb-1 text-xs bg-black text-white rounded px-2 py-1">
                        Add Client
                      </div>
                    )}
                  </div>

                  {!user.role.includes("clinician") && (
                    <div
                      onMouseEnter={() =>
                        setTooltip("Daily Received Phone Calls")
                      }
                      onMouseLeave={() => setTooltip(null)}
                      className="relative">
                      <IncrementButton
                        icon={<PhoneIcon className="h-6 w-6" />}
                        onClick={() => console.log("extra call")}
                        ariaLabel="Add Phone Call"
                      />
                      {tooltip === "Daily Received Phone Calls" && (
                        <div className="absolute bottom-full mb-1 text-xs bg-black text-white rounded px-2 py-1">
                          Received Calls
                        </div>
                      )}
                    </div>
                  )}

                  <div
                    onMouseEnter={() => setTooltip("View Daily Schedule")}
                    onMouseLeave={() => setTooltip(null)}
                    className="relative">
                    <CircularButton
                      icon={<CalendarIcon className="h-6 w-6" />}
                      onClick={onScheduleModalClick}
                      ariaLabel="Show Schedule"
                    />
                    {tooltip === "View Daily Schedule" && (
                      <div className="absolute bottom-full mb-1 text-xs bg-black text-white rounded px-2 py-1">
                        Daily Schedule
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 md:mt-0 md:ml-6">
            <h1 className="text-2xl font-bold text-gray-900">
              {format(new Date(), "MMMM d yyyy")}
            </h1>
            <h1 className="text-2xl font-bold text-gray-900">
              {currentTime.toLocaleTimeString()}
            </h1>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <ProfilePictureModal userId={user._id} onClose={handleCloseModal} />
      )}
    </div>
  );
}

export default UserBanner;
