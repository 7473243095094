import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchClinicById } from "../../store/thunks/clinicThunk";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { logInfo, logError } from "../../utils/logger";
import { Formik, Form, Field } from "formik";

function ClinicDetails({ selectedClinic, onUpdateClinic }) {
  const dispatch = useDispatch();
  const {
    status: isLoading,
    error,
    selectedClinic: clinicData,
  } = useSelector((state) => state.clinics);
  const [isEditing, setIsEditing] = useState(false);

  console.log("cl;inicdata:", clinicData);

  useEffect(() => {
    if (selectedClinic?._id && !clinicData) {
      dispatch(fetchClinicById(selectedClinic._id))
        .unwrap()
        .then((fetchedClinic) => {
          logInfo("Clinic details fetched successfully", {
            clinicId: fetchedClinic._id,
          });
        })
        .catch((error) => {
          logError("Failed to fetch clinic details", {
            error,
            clinicId: selectedClinic._id,
          });
        });
    }
  }, [dispatch, selectedClinic, clinicData]);

  if (isLoading === "loading") {
    logInfo("Loading clinic details", { clinicId: selectedClinic?._id });
    return (
      <div className="w-full h-screen flex items-center justify-center">
        Loading...
      </div>
    );
  }

  if (error) {
    logError("Error displaying clinic details", {
      clinicId: selectedClinic?._id,
      error,
    });
    return (
      <div className="w-full h-screen flex items-center justify-center">
        Error: {error}
      </div>
    );
  }

  if (!clinicData) {
    logInfo("Clinic not found", { clinicId: selectedClinic?._id });
    return (
      <div className="w-full h-screen flex items-center justify-center">
        Clinic not found.
      </div>
    );
  }

  logInfo("Rendering clinic details", {
    clinicId: clinicData._id,
    clinicName: clinicData.name,
  });

  const formatHours = (hours) => {
    if (!hours || !hours.open || !hours.close) {
      return "Closed";
    }
    return `${hours.open} - ${hours.close}`;
  };

  return (
    <div className="w-full h-screen p-4 sm:p-6 md:p-8 bg-white overflow-auto">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-between mb-6">
          <h3 className="flex items-center text-2xl font-semibold text-gray-900">
            <BuildingOffice2Icon className="h-8 w-8 mr-2" aria-hidden="true" />
            Clinic Information
          </h3>
          <button
            onClick={() => setIsEditing(!isEditing)}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
            {isEditing ? "Cancel" : "Edit"}
          </button>
        </div>
        <Formik
          initialValues={clinicData}
          onSubmit={(values) => {
            const updatedValues = {
              ...values,
              paymentMethods: values.paymentMethods.map((value) =>
                clinicData.paymentMethods.find(
                  (method) => method.value === value
                )
              ),
            };
            console.log("Updating with these values:", updatedValues);
            onUpdateClinic(updatedValues);
            setIsEditing(false);
          }}>
          {({ values }) => (
            <Form className="space-y-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  {isEditing ? (
                    <Field
                      name="name"
                      type="text"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  ) : (
                    <div className="mt-1 text-gray-900">{values.name}</div>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  {isEditing ? (
                    <Field
                      name="email"
                      type="email"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  ) : (
                    <div className="mt-1 text-gray-900">{values.email}</div>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="phoneNumber"
                    className="block text-sm font-medium text-gray-700">
                    Phone Number
                  </label>
                  {isEditing ? (
                    <Field
                      name="phoneNumber"
                      type="tel"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  ) : (
                    <div className="mt-1 text-gray-900">
                      {values.phoneNumber}
                    </div>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium text-gray-700">
                    Website
                  </label>
                  {isEditing ? (
                    <Field
                      name="website"
                      type="url"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  ) : (
                    <div className="mt-1 text-gray-900">{values.website}</div>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="address.street"
                  className="block text-sm font-medium text-gray-700">
                  Address
                </label>
                {isEditing ? (
                  <>
                    <Field
                      name="address.street"
                      type="text"
                      placeholder="Street"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-2">
                      <Field
                        name="address.city"
                        type="text"
                        placeholder="City"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                      <Field
                        name="address.province"
                        type="text"
                        placeholder="Province"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                      <Field
                        name="address.postalCode"
                        type="text"
                        placeholder="Postal Code"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                      <Field
                        name="address.country"
                        type="text"
                        placeholder="Country"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    </div>
                  </>
                ) : (
                  <div className="mt-1 text-gray-900">
                    {values.address?.street}, {values.address?.city},{" "}
                    {values.address?.province} {values.address?.postalCode},{" "}
                    {values.address?.country}
                  </div>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Hours of Operation
                </label>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  {Object.entries(values.hoursOfOperation || {}).map(
                    ([day, hours]) => (
                      <div key={day} className="flex items-center">
                        <span className="w-20 text-sm">{day}:</span>
                        {isEditing ? (
                          <>
                            <Field
                              name={`hoursOfOperation.${day}.open`}
                              type="time"
                              className="mx-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            />
                            <span>-</span>
                            <Field
                              name={`hoursOfOperation.${day}.close`}
                              type="time"
                              className="mx-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            />
                          </>
                        ) : (
                          <span className="ml-2">{formatHours(hours)}</span>
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="paymentMethods"
                  className="block text-sm font-medium text-gray-700">
                  Payment Methods
                </label>
                {isEditing ? (
                  <Field
                    name="paymentMethods"
                    as="select"
                    multiple
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                    {clinicData.paymentMethods.map((method) => (
                      <option key={method._id} value={method.value}>
                        {method.englishDescription}
                      </option>
                    ))}
                  </Field>
                ) : (
                  <div className="mt-1 text-gray-900">
                    {clinicData.paymentMethods
                      .map((method) => method.englishDescription)
                      .join(", ")}
                  </div>
                )}
              </div>

              {isEditing && (
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                    Save Changes
                  </button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ClinicDetails;
