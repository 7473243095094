import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadUserPicture,
  deleteUserPicture,
  fetchUserPicture,
} from "../store/thunks/userThunk";

function ProfilePictureModal({ userId, onClose }) {
  const dispatch = useDispatch();
  const { profilePicture, loading, error } = useSelector(
    (state) => state.users
  );

  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(profilePicture);
  const [zoom, setZoom] = useState(1); // Start at 1x zoom (no zoom)
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [lastPos, setLastPos] = useState({ x: 0, y: 0 });

  const canvasRef = useRef();
  const imgRef = useRef();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(profilePicture || "https://via.placeholder.com/150");
    }
  }, [profilePicture, selectedFile]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const drawCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = imgRef.current;

    const imgWidth = img.width * zoom;
    const imgHeight = img.height * zoom;

    canvas.width = 300;
    canvas.height = 300;

    const offsetX = (canvas.width - imgWidth) / 2; // Center the image horizontally
    const offsetY = (canvas.height - imgHeight) / 2; // Center the image vertically

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(
      img,
      position.x + offsetX,
      position.y + offsetY,
      imgWidth,
      imgHeight
    );
  }, [position, zoom]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setLastPos({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    const dx = e.clientX - lastPos.x;
    const dy = e.clientY - lastPos.y;

    setPosition((pos) => ({ x: pos.x + dx, y: pos.y + dy }));
    setLastPos({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => setIsDragging(false);

  const handleUpload = async () => {
    const canvas = canvasRef.current;

    canvas.toBlob(async (blob) => {
      const formData = new FormData();
      formData.append("profilePicture", blob, "profile.jpg");

      await dispatch(uploadUserPicture({ userId, formData }));
      dispatch(fetchUserPicture({ userId }));
      onClose();
    }, "image/jpeg");
  };

  const handleDelete = async () => {
    await dispatch(deleteUserPicture({ userId }));
    dispatch(fetchUserPicture({ userId }));
    onClose();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseup", handleMouseUp);

    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    drawCanvas();
  }, [drawCanvas]);

  return (
    <div className="fixed z-10 inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-6 rounded-md shadow-md w-96">
        <h2 className="text-lg font-bold mb-4">Edit Profile Picture</h2>

        <div className="relative w-72 h-72 mb-4">
          <img
            ref={imgRef}
            src={preview}
            alt="Profile Preview"
            style={{ display: "none" }}
            onLoad={drawCanvas}
          />
          <canvas
            ref={canvasRef}
            className="rounded-full shadow-md"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          />
        </div>

        <input
          type="range"
          min={0.25}
          max={3}
          step={0.1}
          value={zoom}
          onChange={(e) => setZoom(e.target.value)}
          onInput={drawCanvas}
          className="w-full mb-4"
        />

        <input type="file" onChange={handleFileChange} className="mb-4" />

        {error && <p className="text-red-500">{error}</p>}

        <div className="flex justify-between">
          <button
            onClick={onClose}
            className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded">
            Cancel
          </button>
          <button
            onClick={handleUpload}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            disabled={loading}>
            {loading ? "Uploading..." : "Upload"}
          </button>
          <button
            onClick={handleDelete}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            disabled={loading}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProfilePictureModal;
