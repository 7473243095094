import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addFamilyRelationship } from "../../store/thunks/clientsThunk"; // Import the thunk
import SearchDropdown from "../../applicationUi/components/SearchDropdown";
import { logInfo, logError } from "../../utils/logger"; // Import logging functions
import QuickAddClientModal from "../../modals/QuickAddClientModal";

export default function AddFamilyMemberModal({
  memberType,
  clientId, // Pass the ID of the client we are adding a family member to
  onSave,
  onClose,
  onCancel,
  onUpdateClient, // New prop for updating client data
}) {
  const [selectedClient, setSelectedClient] = useState(null); // Store the selected client
  const [showQuickAddParentModal, setShowQuickAddParentModal] = useState(false); // New state for toggling modal visibility
  const dispatch = useDispatch(); // Initialize dispatch from Redux

  useEffect(() => {
    // Close the modal on Escape key press
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onCancel();
        logInfo("AddFamilyMemberModal closed with Escape key", {
          memberType,
          clientId,
        });
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onCancel, memberType, clientId]);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedClient) {
      alert("Please select a family member.");
      logInfo(
        "AddFamilyMemberModal submission failed: No family member selected",
        {
          memberType,
          clientId,
        }
      );
      return;
    }

    const relationshipData =
      memberType === "parent"
        ? { parentId: selectedClient._id, childId: clientId }
        : { parentId: clientId, childId: selectedClient._id };

    try {
      await dispatch(addFamilyRelationship(relationshipData));
      onSave({ selectedClient, relation: memberType });
      onUpdateClient(); // Ensure client data is updated
      logInfo("Family relationship added successfully", {
        memberType,
        clientId,
        selectedClientId: selectedClient._id,
        relationshipData,
      });
      onClose(); // Close the modal after saving
    } catch (error) {
      console.error("Error updating family relationship:", error);
      logError("Error updating family relationship", {
        memberType,
        clientId,
        selectedClientId: selectedClient._id,
        relationshipData,
        error: error.message,
      });
    }
  };

  // Handle selection from SearchDropdown
  const handleClientSelection = (client) => {
    setSelectedClient(client); // Update the state with selected client data
    logInfo("Family member selected in AddFamilyMemberModal", {
      memberType,
      clientId,
      selectedClientId: client._id,
    });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
      <div
        className="fixed inset-0 bg-gray-900 opacity-50"
        onClick={() => {
          onCancel();
          logInfo("AddFamilyMemberModal closed by clicking outside", {
            memberType,
            clientId,
          });
        }}
      />
      <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4 relative z-10">
        <form onSubmit={handleSubmit}>
          {/* Header */}
          <div className="border-b border-gray-900/10 pb-4 mb-4">
            <h2 className="text-lg font-semibold leading-7 text-gray-900">
              Add {memberType === "parent" ? "Parent" : "Child"}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Please search for the {memberType}.
            </p>
          </div>

          {/* SearchDropdown for selecting family member */}
          <div className="mb-6">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Search for {memberType}
            </label>
            <div className="mt-2">
              <SearchDropdown
                prefix=":client:"
                displayFields={["firstName", "lastName", "email"]}
                onSelection={handleClientSelection} // Update with selected client
                placeholder={`Search for a ${memberType}`}
              />
            </div>
          </div>

          {/* Action buttons */}
          <div className="mt-6 flex items-center justify-end gap-x-4">
            <button
              type="button"
              onClick={() => {
                onCancel();
                onUpdateClient(); // Call the new callback when cancelling
                logInfo("AddFamilyMemberModal cancelled", {
                  memberType,
                  clientId,
                });
              }}
              className="text-sm font-semibold leading-6 text-gray-900">
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
              Save
            </button>
          </div>

          {memberType === "parent" && (
            <button
              type="button"
              onClick={() => setShowQuickAddParentModal(true)} // Open modal
              className="mt-2 text-sm font-semibold leading-6 text-indigo-600">
              Can't find the parent? Add a new one
            </button>
          )}
        </form>

        {showQuickAddParentModal && (
          <QuickAddClientModal
            addParent={true}
            onClose={() => {
              setShowQuickAddParentModal(false);
              onUpdateClient(); // Call the new callback when closing QuickAddClientModal
            }}
            onSave={(newParent) => {
              setSelectedClient(newParent);
              setShowQuickAddParentModal(false);
              onUpdateClient(); // Call the new callback when saving new parent
            }}
          />
        )}
      </div>
    </div>
  );
}
