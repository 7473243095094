import React, { useState, useEffect, useRef, useCallback } from "react";
import ClinicianInfo from "./clinicianInfo";
import QuickAddAppointmentModal from "../../../modals/QuickAddAppointmentModal";
import CalendarEvent from "./CalendarEvent";
import SOAPForm from "../../../applicationUi/components/SOAPForm";
import ContextMenu from "../ContextMenu";
import { useDispatch } from "react-redux";
import {
  fetchAppointmentsByClinicianId,
  saveAppointment,
} from "../../../store/thunks/appointmentsThunk";
import QuickAddMeeting from "../../../modals/QuickAddMeeting";
import { setSelectedMeeting } from "../../../store/slices/meetingSlice";
import { useSelector } from "react-redux";
import RescheduleModal from "../../../applicationUi/components/RescheduleModal";
import { setSelectedAppointment } from "../../../store/slices/appointmentsSlice";

const ClinicianSchedule = ({
  clinician,
  appointments,
  currentDate,
  handleAppointmentClick, // Receive the handleAppointmentClick function as a prop
  clinic,
  showWorkingHours, // New prop for working hours toggle
  onReschedule, // Add this prop to handle reschedule
  rescheduleData,
  dailyNote,
  handleNoteUpdate,
  handleNoteDelete,
  handleMeetingUpdate,
  meetings,
}) => {
  const dispatch = useDispatch();
  const contextMenuRef = useRef(null);

  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [showMeetingModal, setShowMeetingModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [selectedClinicianInfo, setSelectedClinicianInfo] = useState(clinician);
  const [currentStep, setCurrentStep] = useState("add"); // 'add' or 'confirm'
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [filteredMeetings, setFilteredMeetings] = useState([]);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    appointment: null,
  });
  const [showSOAPForm, setShowSOAPForm] = useState(false);
  const [soapFormAppointment, setSoapFormAppointment] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [currentTimePosition, setCurrentTimePosition] = useState(null);
  const startHour = showWorkingHours ? 8 : 0;
  const endHour = showWorkingHours ? 20 : 24;
  const hoursToShow = endHour - startHour;
  const statusOptions = [
    "Booked",
    "Changed",
    "Cancelled",
    "Tentative",
    "Confirmed",
    "Treated",
    "Completed",
    "Invoiced",
    "Invoice Paid",
    "Soap Notes Complete",
    "Comments",
    "Paid to Clinician",
  ];
  const [selectedStatus, setSelectedStatus] = useState("");
  const [showStatusSelector, setShowStatusSelector] = useState(false);

  const [passedRescheduleData, setPassedRescheduleData] = useState({});
  const [maxColumns, setMaxColumns] = useState(1);
  const selectedClinic = useSelector(state => state.clinics.selectedClinic || null);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);

  useEffect(() => {
    if (rescheduleData && rescheduleData !== passedRescheduleData) {
      setPassedRescheduleData(rescheduleData);
    } else {
    }
  }, [rescheduleData, passedRescheduleData]);

  // Refetch appointments
  const refetchAppointments = (clinicianId) => {
    dispatch(fetchAppointmentsByClinicianId(clinicianId));
  };

  // Handler to filter appointments based on clinician and current date
  const filterAppointments = () => {
    const clinicianAppointments = appointments.filter((appointment) => {
      const appointmentDate = new Date(appointment.start);
      return (
        appointment.clinician?._id === clinician._id &&
        appointmentDate.toDateString() === currentDate.toDateString()
      );
    });
    setFilteredAppointments(clinicianAppointments);
  };

  // Call filterAppointments on component mount and when dependencies change
  useEffect(() => {
    filterAppointments();
  }, [appointments, clinician, currentDate]);

  // // Get appointments matching the current date
  // useEffect(() => {
  //   const clinicianAppointments = appointments.filter((appointment) => {
  //     const appointmentDate = new Date(appointment.start);
  //     return (
  //       appointment.clinician?._id === clinician._id &&
  //       appointmentDate.toDateString() === currentDate.toDateString()
  //     );
  //   });
  //   setFilteredAppointments(clinicianAppointments);
  // }, [appointments, clinician, currentDate]);

  // Get meetings matching the current date
  useEffect(() => {
    const clinicianMeetings = meetings.filter((meeting) => {
      const meetingDate = new Date(meeting.date).toDateString();
      const todayDate = currentDate.toDateString();
      const isClinicianAttending = meeting.attendees.some(
        (attendee) => attendee._id === clinician._id
      );
      return meetingDate === todayDate && isClinicianAttending;
    });
    setFilteredMeetings(clinicianMeetings);
  }, [meetings, clinician, currentDate]);

  // Move this logic outside of renderAppointments
  useEffect(() => {
    const appointmentsWithColumns =
      calculateAppointmentsWithColumns(filteredAppointments);
    const max = Math.max(
      ...appointmentsWithColumns.map((a) => a.maxColumns),
      1
    );
    setMaxColumns(max);
  }, [filteredAppointments]);

  const renderAppointmentsAndMeetings = () => {
    const allEvents = [
      ...filteredAppointments,
      ...filteredMeetings.map((meeting) => ({
        ...meeting,
        start: new Date(`${meeting.date.split("T")[0]}T${meeting.startTime}`),
        end: new Date(
          new Date(
            `${meeting.date.split("T")[0]}T${meeting.startTime}`
          ).getTime() +
            meeting.duration * 60000
        ),
        isMeeting: true,
      })),
    ];

    const eventsWithColumns = calculateAppointmentsWithColumns(allEvents);

    return eventsWithColumns.map((event) => (
      // console.log("THIS IS THE EVENT WITH COLUMNS", event),
      <CalendarEvent
        key={event._id}
        appointment={event}
        onClick={() => {
          if (event.isMeeting) {
            handleMeetingClick(event);
          } else {
            handleAppointmentClick(event);
          }
        }}
        onRightClick={(e) => handleRightClick(e, event)}
        column={event.column}
        maxColumns={maxColumns}
        startHour={startHour}
      />
    ));
  };

  // Helper function to calculate appointments with columns
  const calculateAppointmentsWithColumns = (appointments) => {
    const appointmentsWithColumns = [];
    const overlappingAppointments = [];

    appointments.forEach((appointment) => {
      const start = new Date(appointment.start).getTime();
      const end = new Date(appointment.end).getTime();
      let placed = false;

      for (let i = 0; i < overlappingAppointments.length; i++) {
        const overlap = overlappingAppointments[i];
        if (
          overlap.every(
            (a) =>
              start >= new Date(a.end).getTime() ||
              end <= new Date(a.start).getTime()
          )
        ) {
          overlap.push(appointment);
          appointmentsWithColumns.push({
            ...appointment,
            column: i,
            maxColumns: overlappingAppointments.length,
          });
          placed = true;
          break;
        }
      }

      if (!placed) {
        overlappingAppointments.push([appointment]);
        appointmentsWithColumns.push({
          ...appointment,
          column: overlappingAppointments.length - 1,
          maxColumns: overlappingAppointments.length,
        });
      }
    });

    return appointmentsWithColumns;
  };

  // For the red line
  useEffect(() => {
    const updateCurrentTimePosition = () => {
      const now = new Date();
      if (now.toDateString() === currentDate.toDateString()) {
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();

        if (currentHour >= startHour && currentHour < endHour) {
          const minutesSinceStart =
            (currentHour - startHour) * 60 + currentMinute;
          const position = (minutesSinceStart / (hoursToShow * 60)) * 100;
          setCurrentTimePosition(position);
        } else {
          setCurrentTimePosition(null);
        }
      } else {
        setCurrentTimePosition(null);
      }
    };

    updateCurrentTimePosition();
    const interval = setInterval(updateCurrentTimePosition, 60000);

    return () => clearInterval(interval);
  }, [currentDate, startHour, endHour, hoursToShow]);

  const handleCreateSOAPNote = () => {
    setSoapFormAppointment(contextMenu.appointment);
    setShowSOAPForm(true);
    handleContextMenuClose(); // Close context menu after option is clicked
  };

  const handleReschedule = (appointment) => {
    console.log("Reschedule clicked", appointment);
    dispatch(setSelectedAppointment(appointment));
    setShowRescheduleModal(true);
    console.log("Reschedule clicked", appointment);
  };

  const handleMarkConfirmed = (appointment) => {
    console.log("Mark confirmed clicked", appointment);
  };

  const handleMarkTentative = (appointment) => {
    console.log("Mark tentative clicked", appointment);
  };

  const handleMarkTreated = (appointment) => {
    console.log("Mark treated clicked", appointment);
  };

  const handleCreateInvoice = (appointment) => {
    console.log("Create invoice clicked", appointment);
  };

  const handleCollectPayment = (appointment) => {
    console.log("Collect payment clicked", appointment);
  };

  const handleCancel = (appointment) => {
    console.log("Cancel clicked", appointment);
  };

  const handleStatusChange = () => {
    if (!contextMenu.appointment) {
      console.error("No appointment selected.");
      return;
    }

    const updatedAppointment = {
      ...contextMenu.appointment,
      status: [selectedStatus],
      history: [
        ...(contextMenu.appointment.history || []),
        {
          status: selectedStatus,
          timestamp: new Date(),
          comments: `Marked as ${selectedStatus}`,
          user: contextMenu.appointment.clinician, // assuming the clinician is the user marking it
        },
      ],
    };

    dispatch(saveAppointment(updatedAppointment))
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          console.log(
            `Appointment marked as ${selectedStatus}:`,
            response.payload
          );
          // Refetch appointments
          refetchAppointments(contextMenu.appointment.clinician._id);
        }
      })
      .catch((error) => {
        console.error(
          `Failed to mark appointment as ${selectedStatus}:`,
          error
        );
      });

    setShowStatusSelector(false); // Close status selector
    handleContextMenuClose(); // Close context menu after action
  };

  useEffect(() => {
    if (showSOAPForm) {
      console.log("SOAP Form is open");
      handleContextMenuClose(); // Close context menu after confirming SOAP form is open
    }
  }, [showSOAPForm]);

  // Show right click menu
  const handleRightClick = (e, appointment) => {
    e.preventDefault();
    console.log("Appointment:", appointment);

    // Define context menu options based on appointment status
    const contextMenuOptions = appointment
      ? [
          ...(['Booked', 'Changed', 'Tentative', 'Confirmed'].some(status => appointment.status.includes(status)) ? [{
            label: "Reschedule",
            onClick: () => handleReschedule(appointment),
          }] : []),
          ...(['Booked', 'Changed', 'Tentative', 'Confirmed'].some(status => appointment.status.some(s => s === status)) ? [{
            label: "Cancel Appt",
            onClick: () => handleCancel(appointment),
          }] : []),
          ...(["Booked", "Changed", "Tentative"].some(status => appointment.status.some(s => s === status)) ? [{
            label: "Mark Tentative", 
            onClick: () => handleMarkTentative(appointment),
          }] : []),
          ...(["Booked", "Changed", "Tentative"].some(status => appointment.status.some(s => s === status)) ? [{
            label: "Mark Confirmed", 
            onClick: () => handleMarkConfirmed(appointment),
          }] : []),
          ...(appointment.history.some((h) => h.status.includes("Treated"))
            ? [
                {
                  label: "SOAP Note",
                  onClick: handleCreateSOAPNote,
                },
              ]
            : []),
          ...(appointment.status.some(s => s === "Confirmed") ? [{
            label: "Mark Treated",
            onClick: () => handleMarkTreated(appointment),
          }] : []),
          ...(appointment.status.some(s => s === "Treated") ? [{
            label: "Invoice",
            onClick: () => handleCreateInvoice(appointment),
          }] : []),
          ...(appointment.status.some(s => s === "Invoiced") ? [{
            label: "Collect Payment",
            onClick: () => handleCollectPayment(appointment),
          }] : []),
        ]
      : [];

    // Calculate available space
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const menuWidth = 200; // Set exact menu width
    const menuHeight = contextMenuOptions.length * 50; // Set a fixed height per option

    // Adjust x position if menu would go off right edge
    let x = e.clientX;
    if (x + menuWidth > viewportWidth) {
      x = viewportWidth - menuWidth;
    }

    // Adjust y position if menu would go off bottom edge
    let y = e.clientY;
    if (y + menuHeight > viewportHeight) {
      y = viewportHeight - menuHeight;
    }

    // Set the context menu visibility and adjusted position
    setContextMenu({
      visible: true,
      x,
      y,
      appointment,
      options: contextMenuOptions,
    });
    setShowStatusSelector(false);
  };

  // Create appointment or show context menu
  const handleGridRightClick = (e) => {
    e.preventDefault();
    const clickedGridItem = e.target.closest("li");
    if (clickedGridItem) {
      const row = parseInt(clickedGridItem.dataset.row, 10);
      if (row >= 2 && row <= hoursToShow * 12) {
        const hour = startHour + Math.floor((row - 2) / 12);
        const minute = ((row - 2) % 12) * 5;
        const timeSelected = `${hour.toString().padStart(2, "0")}:${minute
          .toString()
          .padStart(2, "0")}`;

        const contextMenuOptions = [
          {
            label: "Create Appointment",
            onClick: () => {
              const modalContent = {
                date: new Date(currentDate),
                time: timeSelected,
                clinicianFname: clinician.firstName,
                clinicianLname: clinician.lastName,
              };
              console.log("Appointment Modal Content:", modalContent);
              setModalContent(modalContent);
              setSelectedClinicianInfo(clinician);
              setShowAppointmentModal(true);
            },
          },
          {
            label: "Create Meeting",
            onClick: () => {
              const modalContent = {
                date: new Date(currentDate),
                time: timeSelected,
                clinicianFname: clinician.firstName,
                clinicianLname: clinician.lastName,
              };
              console.log("Meeting Modal Content:", modalContent);
              setModalContent(modalContent);
              setShowMeetingModal(true);
              console.log("Meeting clicked");
            },
          },
        ];

        setContextMenu({
          visible: true,
          x: e.clientX,
          y: e.clientY,
          appointment: null,
          options: contextMenuOptions,
        });
      }
    }
  };

  const handleContextMenuClose = () => {
    setContextMenu({ visible: false, x: 0, y: 0, appointment: null });
    setShowStatusSelector(false); // Ensure the selector is hidden when the context menu is closed
  };

  const handleAppointmentModalClose = () => {
    console.log("Handle Modal Close - Clinician Schedule");
    setShowAppointmentModal(false);
    setShowDetailsModal(false);
    setShowSOAPForm(false); // Close SOAP form as well
    filterAppointments();
    // refetchAppointments();
    // console.log('logging contextmenu',contextMenu);
    // refetchAppointments(contextMenu.clinician._id);
  };

  const handleMeetingModalClose = (onClose) => {
    console.log("Handle Modal Close - Clinician Schedule");
    setShowMeetingModal(false);
    setShowDetailsModal(false);
    setShowSOAPForm(false);
    dispatch(setSelectedMeeting(null));

    if (handleMeetingUpdate) {
      handleMeetingUpdate(); // Call the callback in the parent
    }

    if (onClose && typeof onClose === "function") {
      onClose(); // Call the onClose function if provided
    }
  };

  const handleMeetingClick = (event) => {
    console.log("Meeting clicked", event);
    dispatch(setSelectedMeeting(event));
    setModalContent(event); // Pass the meeting data to the modal
    setShowMeetingModal(true);
  };

  const handleSoapModalClose = () => {
    console.log("Handle Modal Close - Clinician Schedule");
    setShowSOAPForm(false); // Close SOAP form as well
  };

  const handleAppointmentSave = (appointmentData) => {
    setAppointmentDetails(appointmentData);
    setShowAppointmentModal(false);
    setShowDetailsModal(true);
    // setCurrentStep("confirm");
  };

  const handleMeetingSave = (meetingData) => {
    setAppointmentDetails(meetingData);
    setShowMeetingModal(false);
    setShowDetailsModal(true);

    // setCurrentStep("confirm");
  };

  const handleMouseEnter = (rowIndex) => {
    setHoveredRow(rowIndex);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const formatTime = (rowIndex) => {
    const totalMinutes = rowIndex * 5 - 5;
    const hour = startHour + Math.floor(totalMinutes / 60);
    const minute = totalMinutes % 60;

    return new Date(
      `1970-01-01T${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}:00`
    ).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        contextMenuRef.current &&
        !contextMenuRef.current.contains(event.target)
      ) {
        handleContextMenuClose();
      }
    };

    if (contextMenu.visible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [contextMenu.visible]);

  return (
    <>
      <div className="sticky top-0 z-50 bg-white">
        <ClinicianInfo
          clinician={clinician}
          calendarDate={currentDate}
          dailyNote={dailyNote}
          onNoteUpdate={handleNoteUpdate}
          onNoteDelete={handleNoteDelete}
        />
      </div>

      <div className="flex w-full flex-auto">
        <div className="w-14 flex-none bg-white ring-1 ring-gray-100 relative">
          {currentTimePosition !== null && (
            <div
              className="absolute left-0 text-left pl-2 text-xs leading-5 text-red-500 current-time-label"
              style={{ top: `${currentTimePosition}%` }}>
              {new Date().toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </div>
          )}
        </div>

        <div className="grid flex-auto grid-cols-1 grid-rows-1 relative">
          {/* Time labels */}
          <div
            className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
            style={{
              gridTemplateRows: `repeat(${
                hoursToShow * 2
              }, minmax(4.5rem, 1fr))`,
            }}>
            <div className="row-end-1 h-7"></div>
            {[...Array(hoursToShow).keys()].map((hour) => {
              // Get current day of week
              const dayOfWeek = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(currentDate);
              let daySchedule = null;
              // Find availability for current clinic
              if (selectedClinic !== null) {
                const clinicAvailability = clinician?.availability?.find(a => a.clinic === selectedClinic._id);
                daySchedule = clinicAvailability?.schedule?.[dayOfWeek];
              }
              // Check if current hour is within available hours
              const currentHourTime = `${(hour + startHour).toString().padStart(2, '0')}:00`;
              const isAvailable = daySchedule?.some(slot => {
                return currentHourTime >= slot.start && currentHourTime < slot.end;
              });

              return (
                <React.Fragment key={hour}>
                  <div className={`${!isAvailable ? 'bg-gray-100' : ''}`}>
                    <div className="sticky z-10 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400 bg-white">
                      {(hour + startHour) % 12 === 0
                        ? hour + startHour === 0
                          ? "12AM"
                          : "12PM"
                        : `${(hour + startHour) % 12}${
                            hour + startHour < 12 ? "AM" : "PM"
                          }`}
                    </div>
                  </div>
                  <div className={`${!isAvailable ? 'bg-gray-100' : ''}`} />
                </React.Fragment>
              );
            })}
          </div>

          {/* Time hover indicator grid */}
          <ol
            className="col-start-1 col-end-2 row-start-1 grid relative"
            style={{
              gridTemplateRows: `1.75rem repeat(${
                hoursToShow * 12
              }, minmax(0, 1fr)) auto`,
              gridTemplateColumns: `1fr`, // Only one column for hover
            }}>
            {Array.from({ length: hoursToShow * 12 }, (_, rowIndex) => (
              <li
                key={rowIndex}
                data-row={rowIndex + 1}
                onMouseEnter={() => handleMouseEnter(rowIndex)}
                onMouseLeave={handleMouseLeave}
                onContextMenu={(e) => handleGridRightClick(e)}
                className={`relative ${
                  hoveredRow === rowIndex ? "bg-gray-200" : ""
                }`}
                style={{
                  gridColumn: "1 / -1", // Span across all columns
                  zIndex: 10, // Ensure it's above other elements but below appointments
                  pointerEvents: "auto", // Make sure it can receive mouse events
                }}>
                {hoveredRow === rowIndex && (
                  <div className="absolute -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400 bg-white">
                    {formatTime(rowIndex)}
                  </div>
                )}
              </li>
            ))}
          </ol>

          {/* Appointment grid */}
          <div
            className="col-start-1 col-end-2 row-start-1 grid relative"
            onContextMenu={handleGridRightClick}>
            <ol
              className="grid absolute inset-0"
              style={{
                gridTemplateRows: `1.75rem repeat(${
                  hoursToShow * 12
                }, minmax(0, 1fr)) auto`,
                gridTemplateColumns: `repeat(0, 1fr)`,
              }}>
              {renderAppointmentsAndMeetings()}
            </ol>
          </div>

          {currentTimePosition !== null && (
            <div
              className="absolute w-full border-t border-red-500"
              style={{ top: `${currentTimePosition}%` }}
            />
          )}
        </div>
      </div>

      {contextMenu.visible && (
        <ContextMenu
          ref={contextMenuRef}
          x={contextMenu.x}
          y={contextMenu.y}
          options={contextMenu.options}
          onClose={handleContextMenuClose}
          showStatusSelector={showStatusSelector}
          statusOptions={statusOptions}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          handleStatusChange={handleStatusChange}
        />
      )}

      {showStatusSelector && (
        <div
          className="absolute z-50 bg-white border rounded shadow-lg p-2"
          style={{ top: contextMenu.y, left: contextMenu.x }}>
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="p-2 border rounded w-full">
            <option value="" disabled>
              Select status
            </option>
            {statusOptions.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
          <div className="flex mt-2">
            <button
              onClick={handleStatusChange}
              className="p-2 bg-blue-500 text-white rounded mr-2">
              Confirm
            </button>
            <button
              onClick={() => setShowStatusSelector(false)}
              className="p-2 bg-gray-300 text-black rounded">
              Cancel
            </button>
          </div>
        </div>
      )}

      {showAppointmentModal && (
        <QuickAddAppointmentModal
          isOpen={showAppointmentModal}
          onClose={handleAppointmentModalClose}
          initialDate={modalContent?.date}
          initialTime={modalContent?.time}
          initialClinicianFname={modalContent?.clinicianFname}
          initialClinicianLname={modalContent?.clinicianLname}
          rescheduleData={passedRescheduleData}
          onSave={handleAppointmentSave}
          selectedClinician={selectedClinicianInfo}
          selectedClinic={clinic}
        />
      )}
      {showMeetingModal && (
        <QuickAddMeeting
          isOpen={showMeetingModal}
          onClose={handleMeetingModalClose}
          meetingData={modalContent?.isMeeting ? modalContent : null} // Pass meeting data if it's a meeting, otherwise null
          initialDate={modalContent?.date} // Used for new meetings
          initialTime={modalContent?.time} // Used for new meetings
          initialClinicianFname={modalContent?.clinicianFname} // Used for new meetings
          initialClinicianLname={modalContent?.clinicianLname} // Used for new meetings
          rescheduleData={passedRescheduleData}
          onSave={handleMeetingSave} // Called when creating a new meeting
          selectedClinician={selectedClinicianInfo}
          selectedClinic={clinic}
        />
      )}

      {showSOAPForm && soapFormAppointment && (
        <SOAPForm
          onClose={handleSoapModalClose}
          appointment={soapFormAppointment}
        />
      )}
      {onReschedule && <QuickAddAppointmentModal appointment={onReschedule} />}
      {showRescheduleModal && (
        <RescheduleModal
          isOpen={showRescheduleModal}
          onClose={() => setShowRescheduleModal(false)}
          appointment={onReschedule}
        />
      )}
    </>
  );
};

export default ClinicianSchedule;
