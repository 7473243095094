import React from 'react';

const BankingInstitutionNumberTable = () => {
    const bankData = [
        { name: 'Bank of Montreal', number: '001' },
        { name: 'Bank of Nova Scotia', number: '002' },
        { name: 'Royal Bank of Canada', number: '003' },
        { name: 'Toronto-Dominion Bank', number: '004' },
        { name: 'Canadian Imperial Bank of Commerce', number: '010' },
        { name: 'HSBC Bank Canada', number: '016' },
        { name: 'Tangerine Bank', number: '614' },
        { name: 'Simplii Financial', number: '010' },
        { name: 'National Bank of Canada', number: '006' },
        { name: 'Desjardins', number: '815' },
        { name: 'Laurentian Bank of Canada', number: '039' },
        { name: 'Manulife Bank of Canada', number: '540' },
        { name: 'Alterna Bank', number: '888' },
        { name: 'Bridgewater Bank', number: '638' },
        { name: 'Canadian Western Bank', number: '030' },
        { name: 'Concentra Bank', number: '308' },
        { name: 'Equitable Bank', number: '623' },
        { name: 'First Nations Bank of Canada', number: '818' },
        { name: 'Haventree Bank', number: '002' },
        { name: 'Motus Bank', number: '623' },
        { name: 'Peoples Trust Company', number: '621' },
        { name: 'Vancity', number: '809' },
        { name: 'ATB Financial', number: '219' },
        { name: 'B2B Bank', number: '039' },
        { name: 'Coast Capital Savings Federal Credit Union', number: '809' },
        { name: 'Community Trust Company', number: '352' },
        { name: 'CS Alterna Bank', number: '888' },
        { name: 'Duo Bank of Canada', number: '329' },
        { name: 'Exchange Bank of Canada', number: '246' },
        { name: 'First Nations Bank of Canada', number: '818' },
        { name: 'Home Bank', number: '355' },
        { name: 'Home Trust Company', number: '355' },
        { name: 'ICICI Bank Canada', number: '340' },
        { name: 'Industrial and Commercial Bank of China (Canada)', number: '117' },
        { name: 'Manulife Bank of Canada', number: '540' },
        { name: 'National Bank Direct Brokerage', number: '006' },
        { name: 'Peace Hills Trust Company', number: '895' },
        { name: 'President\'s Choice Financial', number: '010' },
        { name: 'RFA Bank of Canada', number: '324' },
        { name: 'Rogers Bank', number: '473' },
        { name: 'State Bank of India (Canada)', number: '307' },
        { name: 'Wealth One Bank of Canada', number: '324' },
        { name: 'Zag Bank', number: '638' },
    ];

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Bank Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Institution Number
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {bankData.map((bank, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {bank.name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {bank.number}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BankingInstitutionNumberTable;
