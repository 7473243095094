import React from "react";
import ClinicSchedule from "./ClinicSchedule";

function SchedulePage() {
  return (
    <div className="schedule-page">
      <>
        <ClinicSchedule />
      </>
    </div>
  );
}

export default SchedulePage;
