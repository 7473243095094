import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const fetchPhoneCalls = createAsyncThunk(
  "phoneCalls/fetchPhoneCalls",
  async (date, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.get(
        `${baseURL}/api/phoneCalls?date=${date}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

export const incrementPhoneCall = createAsyncThunk(
  "phoneCalls/incrementPhoneCall",
  async (userId, { rejectWithValue, getState }) => {
    console.log("passing userId through incrementphonecallthunk", userId);
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.post(
        `${baseURL}/api/phoneCalls/increment`,
        { userId },
        config
      );
      return response.data; // This should include { date, count, auditTrail }
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

export const decrementPhoneCall = createAsyncThunk(
  "phoneCalls/decrementPhoneCall",
  async (userId, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.post(
        `${baseURL}/api/phoneCalls/decrement`,
        { userId },
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);
