import React, { useState } from 'react';
import { ChatBubbleOvalLeftIcon } from '@heroicons/react/24/outline';
import NoteModal from '../../../modals/NoteModal';
import { useDispatch } from 'react-redux';
import { createNote, updateNote } from '../../../store/thunks/notesThunk';

function ClinicianInfo({ clinician, dailyNote, calendarDate, onNoteUpdate, onNoteDelete }) {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [noteText, setNoteText] = useState(dailyNote);

  const getBgColor = () => {
    if (clinician.specialization && clinician.specialization.length > 0) {
      switch (clinician.specialization[0]) {
        case "SLP":
          return "bg-blue-100";
        case "CDA":
          return "bg-green-100";
        default:
          return "bg-gray-100";
      }
    }
    return "bg-gray-100";
  };

  const handleNoteClick = () => {
    setNoteText(dailyNote); // Set the current note text
    setIsModalOpen(true); // Open the modal
  };

  return (
    <div className={`flex flex-col items-center justify-center w-full p-2 border-b border-gray-200 border-l-2 ${getBgColor()}`}>
      <h2 className="text-lg font-semibold">{clinician.firstName} {clinician.lastName}</h2>
      <p className="text-sm text-gray-500">{clinician.qualifications || clinician.credentials || "Clinician"}</p>

      {/* Speech Bubble */}
      <div 
        className="absolute top-2 right-2 cursor-pointer z-100"
        onClick={handleNoteClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <ChatBubbleOvalLeftIcon 
          className={`h-6 w-6 ${dailyNote ? 'text-white' : 'text-gray-600'}`} 
          style={{
            fill: dailyNote ? 'rgb(239, 68, 68)' : 'transparent',
            stroke: dailyNote ? 'rgb(239, 68, 68)' : 'currentColor'
          }}
        />
      </div>

      {/* Dialogue Box */}
      {isHovered && dailyNote && (
        <div className="absolute top-10 right-2 bg-white border border-gray-300 rounded shadow-md p-2">
          <pre className="text-sm text-gray-800 whitespace-pre-wrap">{dailyNote.content}</pre>
        </div>
      )}

      {/* Note Modal */}
      {isModalOpen && (
        <NoteModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          dailyNote={dailyNote}
          onSave={onNoteUpdate}
          onDelete={onNoteDelete}
          clinician={clinician}
          calendarDate={calendarDate}
        />
      )}
    </div>
  );
}

export default ClinicianInfo