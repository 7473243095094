import React from "react";
import SevenDayCalendarV2 from "./SevenDayCalendarV2";

export default function ClinicSchedule() {
  return (
    <>
      {/* <SevenDayCalendar /> */}
      <SevenDayCalendarV2 />
    </>
  );
}
