import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

// Fetch all clinics
export const fetchClinics = createAsyncThunk(
    "clinics/fetchAll",
    async (_, { rejectWithValue, getState }) => {
      try {
        const state = getState();
        const token = state.auth.token;
        const { baseURL, config } = generateConfig({ token });
  
        const response = await axios.get(`${baseURL}/api/clinics`, config);
        return response.data; // Return the clinics array directly
      } catch (error) {
        return handleApiError(error, rejectWithValue);
      }
    }
  );
  

// Fetch a single clinic by ID
export const fetchClinicById = createAsyncThunk(
  "clinics/fetchById",
  async (clinicId, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      console.log(`Fetching clinic with ID: ${clinicId}`);
      const response = await axios.get(
        `${baseURL}/api/clinics/${clinicId}`,
        config
      );
      console.log("Clinic fetched successfully:", response.data);

      return response.data;
    } catch (error) {
      console.error("Error fetching clinic:", error);
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Create a new clinic
export const createClinic = createAsyncThunk(
  "clinics/create",
  async (clinicData, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/clinics`,
        clinicData,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Update a clinic
export const updateClinic = createAsyncThunk(
  "clinics/update",
  async ({ id, clinicData }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.patch(
        `${baseURL}/api/clinics/${id}`,
        clinicData,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Delete a clinic
export const deleteClinic = createAsyncThunk(
  "clinics/delete",
  async (clinicId, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      await axios.delete(`${baseURL}/api/clinics/${clinicId}`, config);
      return clinicId; // Return the deleted clinic's ID
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const addClinicianToClinic = createAsyncThunk(
  "clinics/addClinician",
  async ({ clinicId, clinicianId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/clinics/${clinicId}/addClinician/${clinicianId}`,
        {},
        config
      )
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const removeClinicianFromClinic = createAsyncThunk(
  "clinics/removeClinician",
  async ({ clinicId, clinicianId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.delete(
        `${baseURL}/api/clinics/${clinicId}/removeClinician/${clinicianId}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const addRoleToClinic = createAsyncThunk(
  "clinics/addRole",
  async ({ clinicId, roleId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(    
        `${baseURL}/api/clinics/${clinicId}/addRole/${roleId}`,
        {},
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const removeRoleFromClinic = createAsyncThunk(
  "clinics/removeRole",
  async ({ clinicId, roleId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.delete(  
        `${baseURL}/api/clinics/${clinicId}/removeRole/${roleId}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const addStaffToClinic = createAsyncThunk(
  "clinics/addStaff",
  async ({ clinicId, staffId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/clinics/${clinicId}/addStaff/${staffId}`,
        {},
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const removeStaffFromClinic = createAsyncThunk(
  "clinics/removeStaff",
  async ({ clinicId, staffId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.delete(
        `${baseURL}/api/clinics/${clinicId}/removeStaff/${staffId}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const addServiceToClinic = createAsyncThunk(
  "clinics/addService",
  async ({ clinicId, serviceId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/clinics/${clinicId}/addService/${serviceId}`,
        {},
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }   
  }
);

export const removeServiceFromClinic = createAsyncThunk(
  "clinics/removeService",
  async ({ clinicId, serviceId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.delete(
        `${baseURL}/api/clinics/${clinicId}/removeService/${serviceId}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchClinicStaff = createAsyncThunk(
  "clinics/fetchStaff",
  async (clinicId, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });    

      const response = await axios.get(
        `${baseURL}/api/clinics/${clinicId}/staff`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchClinicServices = createAsyncThunk(
  "clinics/fetchServices",
  async (clinicId, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;   
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/clinics/${clinicId}/services`,
        config
      );
      return response.data;
    } catch (error) {   
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchClinicRoles = createAsyncThunk(
  "clinics/fetchRoles",
  async (clinicId, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/clinics/${clinicId}/roles`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchAllServices = createAsyncThunk(
  "clinics/fetchAllServices",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });    

      const response = await axios.get(
        `${baseURL}/api/services`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const deleteClinicService = createAsyncThunk(
  "clinics/deleteService",
  async ({ clinicId, serviceId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });    

      const response = await axios.delete(
        `${baseURL}/api/clinics/${clinicId}/removeService/${serviceId}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);


