import React, { useState, useEffect } from "react";
import Table from "../../applicationUi/components/Table";
import { logInfo } from "../../utils/logger";
import InvoiceGenerator from "../../applicationUi/components/InvoiceGenerator";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoiceById } from "../../store/thunks/invoiceThunk";
import { clearCurrentInvoice } from "../../store/slices/invoiceSlice";
import { fetchAppointmentById } from "../../store/thunks/appointmentsThunk";
import { fetchPaymentsByInvoice } from "../../store/store";

const ClientInvoices = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showInvoiceGenerator, setShowInvoiceGenerator] = useState(false);
  const itemsPerPage = 10; // Adjust as needed
  const dispatch = useDispatch();
  const currentInvoice = useSelector((state) => state.invoices.currentInvoice);
  const client = useSelector((state) => state.clients.selectedClient);

  if (!client) {
    return (
      <div className="p-4 text-gray-600">Loading client information...</div>
    );
  }

  const invoices = client.invoices || [];
  console.log("invoices", invoices);
  const totalInvoices = invoices.length;
  const totalPages = Math.ceil(totalInvoices / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    logInfo("Invoice page changed", { newPage });
  };

  const handleInvoiceGeneratorClose = () => {
    setShowInvoiceGenerator(false);
    dispatch(clearCurrentInvoice());
  };

  const handleRowClick = async (clickedInvoice) => {
    console.log("Clicked invoice", clickedInvoice);
    await dispatch(fetchInvoiceById(clickedInvoice));
    console.log("Current invoice", currentInvoice);
    await dispatch(fetchAppointmentById(currentInvoice.appointmentId));
    await dispatch(fetchPaymentsByInvoice(currentInvoice._id));
    setShowInvoiceGenerator(true);
  };

  const paginatedInvoices = invoices.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <Table
        columns={[
          {
            header: "Invoice ID",
            accessor: "invoiceId",
          },
          {
            header: "Service Date",
            accessor: (row) => {
              const date = new Date(row.serviceDate);
              return date.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
            },
          },
          {
            header: "Invoice Date",
            accessor: (row) => {
              const date = new Date(row.createdAt);
              return date.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
            },
          },
          {
            header: "Client Name",
            accessor: () =>
              `${client.firstName || "Unknown"} ${client.lastName || ""}`,
          },
          {
            header: "Clinician",
            accessor: (row) =>
              row.clinician
                ? `${row.clinician.firstName || "Unknown"} ${
                    row.clinician.lastName || ""
                  }`
                : "Unknown",
          },
          {
            header: "Amount",
            accessor: (row) => `$${row.amount?.toFixed(2) || "0.00"}`,
          },
          {
            header: "Split Invoice?",
            accessor: (row) =>
              row.splitBilling && row.splitBilling.isSplit ? "Yes" : "No",
          },
          {
            header: "Status",
            accessor: "status",
          },
        ]}
        data={paginatedInvoices}
        onRowClick={(clickedInvoice) => handleRowClick(clickedInvoice)}
        title="Client Invoices"
        description={`List of invoices for ${client.firstName} ${client.lastName} (${totalInvoices} total)`}
        showPagination={true}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      {showInvoiceGenerator && currentInvoice && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
          <div className="relative bg-white rounded-lg shadow-xl max-w-7xl w-full m-4">
            <div className="p-4 sm:p-6 lg:p-8">
              <button
                onClick={handleInvoiceGeneratorClose}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
                aria-label="Close">
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <InvoiceGenerator
                initialViewMode={true}
                onClose={handleInvoiceGeneratorClose}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClientInvoices;
