// src/features/kpi/kpiSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { fetchWaagData, fetchOfficeManagerData } from '../thunks/waagThunk';

const waagSlice = createSlice({
  name: 'waag',
  initialState: {
    data: {},
    officeManagerData: {},
    status: 'idle',
    error: null,
  },
  reducers: {
    // You can add reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWaagData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWaagData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchWaagData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchOfficeManagerData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOfficeManagerData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.officeManagerData = action.payload;
      })
      .addCase(fetchOfficeManagerData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default waagSlice.reducer;
