import CheckboxGroup from "../../applicationUi/components/intake/CheckboxGroup";
import TextAreaField from "../../applicationUi/components/intake/TextAreaField";
import InputField from "../../applicationUi/components/intake/InputField";
import RadioGroup from "../../applicationUi/components/intake/RadioGroup";
const AcademicImpactSection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold">G - Academic/Social Impact</h2>

    {/* How does speech and language difficulty impact day-to-day life? */}
    <h3 className="font-semibold mb-2">
      How does the speech and language difficulty impact your child on a
      day-to-day basis?
    </h3>

    {/* CheckboxGroup for selecting multiple impacts */}
    <CheckboxGroup
      label="Day-to-day Impact"
      namePrefix="academicSocialImpact.impactOnDayToDay"
      options={[
        { name: "shy", label: "Shy" },
        { name: "actingOut", label: "Acting Out" },
        { name: "teased", label: "Teased" },
        { name: "avoidsTalking", label: "Avoids Talking" },
        { name: "avoidsEyeContact", label: "Avoids Eye Contact" },
      ]}
      selectedOptions={formData.academicSocialImpact.impactOnDayToDay}
      onChange={handleChange}
    />

    <TextAreaField
      label="Other (please specify)"
      name="academicSocialImpact.impactOnDayToDay.other"
      value={formData.academicSocialImpact.impactOnDayToDay.other}
      onChange={handleChange}
    />

    {/* Academic Performance */}
    <InputField
      label="Academic Performance"
      name="academicSocialImpact.academicPerformance"
      value={formData.academicSocialImpact.academicPerformance}
      onChange={handleChange}
    />

    {/* Playmates */}
    <RadioGroup
      label="Does your child have playmates?"
      name="academicSocialImpact.hasPlaymates"
      options={[
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
      ]}
      selectedValue={formData.academicSocialImpact.hasPlaymates}
      onChange={handleChange}
    />

    {/* Conditional fields for playmates */}
    {formData.academicSocialImpact.hasPlaymates === "yes" && (
      <>
        <InputField
          label="How old are they?"
          name="academicSocialImpact.playmatesAge"
          value={formData.academicSocialImpact.playmatesAge}
          onChange={handleChange}
        />
        <TextAreaField
          label="How do they interact together?"
          name="academicSocialImpact.interactionWithPlaymates"
          value={formData.academicSocialImpact.interactionWithPlaymates}
          onChange={handleChange}
        />
      </>
    )}

    {/* Interaction with siblings */}
    <TextAreaField
      label="How does your child get along with their siblings?"
      name="academicSocialImpact.siblingsInteraction"
      value={formData.academicSocialImpact.siblingsInteraction}
      onChange={handleChange}
    />

    {/* Favorite activities at home */}
    <TextAreaField
      label="Favourite activities at home?"
      name="academicSocialImpact.favoriteActivitiesAtHome"
      value={formData.academicSocialImpact.favoriteActivitiesAtHome}
      onChange={handleChange}
    />
  </section>
);

export default AcademicImpactSection;
