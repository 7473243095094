import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { useAuth } from "./auth/authContext";
import { setLockScreen } from "./store/slices/authSlice";
import {
  handleMenuItemClickAsync,
  handleSearchResultClickAsync,
  setActiveModalAsync,
} from "./store/thunks/appStateThunk";

import DashboardPage from "./pages/dashboardPage/DashboardPage";
import CliniciansPage from "./pages/clinicianPage/CliniciansPage";
import ClientsPage from "./pages/clientsPage/ClientsPage";
import SchedulePage from "./pages/schedulePage/SchedulePage";
import FormsPage from "./pages/formsPage/FormsPage";
import ReportsPage from "./pages/reportsPage/ReportsPage";
import HRPage from "./pages/hrPage/HRPage";
import ManagerPage from "./pages/managerPage/ManagerPage";
import LockScreen from "./auth/lockScreen";
import QuickBooks from "./pages/quickBooks/QuickBooksPage";
import UserBanner from "./applicationUi/components/UserBanner";
import QuickAddModal from "./modals/QuickAddModals";
import ClinicPage from "./pages/clinic/ClinicPage";
import ClinicianPayrollOverviewPage from "./pages/payrollPage/ClinicianPayrollOverviewPage";
import HRPayrollOverviewPage from "./pages/payrollPage/HRPayrollOverviewPage";
import HourlyPayrollPage from "./pages/payrollPage/HourlyPayrollPage";
import ServicesPage from "./pages/services/ServicesPage";
import InvoicesPage from "./pages/invoicesPage/InvoicesPage";
import PaymentsPage from "./pages/paymentsPage/PaymentsPage";
import IntakesPage from "./pages/intakesPage/IntakesPage";

import {
  BanknotesIcon,
  CalendarIcon,
  CalendarDaysIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  LockClosedIcon,
  UserGroupIcon,
  IdentificationIcon,
  BuildingOffice2Icon,
  ArrowLeftStartOnRectangleIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function App() {
  const { handleUserLogout } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("Dashboard");
  const [isScreenLocked, setIsScreenLocked] = useState(false); // State to manage screen lock
  const [clickedItem, setClickedItem] = useState(null);
  const lastClickedMenuItem = useSelector(
    (state) => state.appState.selectedMenuItem
  );
  const activeModal = useSelector((state) => state.appState.activeModal);
  const roles = useSelector((state) => state.auth.user.role || []);
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const [navigation, setNavigation] = useState([]);

  // Check if the page was refreshed and set the menu to the lastClickedMenuItem
  useEffect(() => {
    if (lastClickedMenuItem && lastClickedMenuItem !== selectedMenuItem) {
      setSelectedMenuItem(lastClickedMenuItem);
      const updatedNavigation = navigation.map((item) => ({
        ...item,
        current: item.name === lastClickedMenuItem,
      }));
      setNavigation(updatedNavigation);
    }

    // Handle page refresh if there's an active modal
    if (activeModal) {
      // Re-open the active modal
      switch (activeModal) {
        case "quickAddClient":
          handleQuickAddClientClick();
          break;
        case "quickAddAppointment":
          handleQuickAddApptClick();
          break;
        case "scheduleModal":
          handleScheduleModalClick();
          break;
        // Add more cases for other modals as needed
        default:
          break;
      }
    }
  }, [lastClickedMenuItem, activeModal]);

  const menuAccessRules = (() => {
    // Default menu options for each role
    const defaultMenuAccess = {
      owner: [
        "Dashboard",
        "Clinicians",
        "Clients",
        "Week Schedule",
        "Forms",
        "Reports",
        "HR",
        "Manager",
        "QuickBooks",
        "Clinic",
        "Payroll",
        "HR Payroll",
        "Hourly Payroll",
        "Services",
        "Invoices",
        "Payments",
        "Intake",
      ],
      admin: [
        "Dashboard",
        "Clinicians",
        "Clients",
        "Week Schedule",
        "Reports",
        "Services",
        "Invoices",
        "Payments",
        "Intake",
      ],
      hr: [
        "Dashboard",
        "Clinicians",
        "Clients",
        "Week Schedule",
        "HR",
        "HR Payroll",
        "Invoices",
        "Payments",
        "Intake",
      ],
      clinician: ["Dashboard", "Clients", "Week Schedule"],
      bookkeeper: ["Dashboard", "QuickBooks"],
      officeManager: [
        "Dashboard",
        "Clinicians",
        "Clients",
        "Clinic",
        "HR",
        "Intake",
      ],
    };

    // Dynamically adjust menu based on `payrollType`
    const adjustedMenuAccess = { ...defaultMenuAccess };

    // Add "Payroll" or "Hourly Payroll" based on `payrollType`
    ["admin", "hr", "clinician", "bookkeeper", "officeManager"].forEach(
      (role) => {
        if (user?.payrollType === "fee for service") {
          adjustedMenuAccess[role] = [...adjustedMenuAccess[role], "Payroll"];
        } else if (user?.payrollType === "hourly") {
          adjustedMenuAccess[role] = [
            ...adjustedMenuAccess[role],
            "Hourly Payroll",
          ];
        }
      }
    );

    return adjustedMenuAccess;
  })();

  useEffect(() => {
    // Combine all accessible menu items based on all roles
    const combinedMenuItems = new Set();
    roles.forEach((role) => {
      (menuAccessRules[role] || []).forEach((item) =>
        combinedMenuItems.add(item)
      );
    });

    // Set the navigation state based on combined menu items
    setNavigation(
      [
        { name: "Dashboard", icon: HomeIcon, current: true },
        { name: "Clinicians", icon: UsersIcon, current: false },
        { name: "Clients", icon: UserGroupIcon, current: false },
        { name: "Week Schedule", icon: CalendarDaysIcon, current: false },
        { name: "Forms", icon: DocumentDuplicateIcon, current: false },
        { name: "Reports", icon: ChartPieIcon, current: false },
        { name: "HR", icon: FolderIcon, current: false },
        { name: "Manager", icon: IdentificationIcon, current: false },
        { name: "QuickBooks", icon: BanknotesIcon, current: false },
        { name: "Clinic", icon: BuildingOffice2Icon, current: false },
        { name: "Payroll", icon: BanknotesIcon, current: false },
        { name: "HR Payroll", icon: BanknotesIcon, current: false },
        { name: "Hourly Payroll", icon: BanknotesIcon, current: false },
        { name: "Services", icon: DocumentDuplicateIcon, current: false },
        { name: "Invoices", icon: DocumentTextIcon, current: false },
        { name: "Payments", icon: BanknotesIcon, current: false },
        { name: "Intake", icon: DocumentTextIcon, current: false },
      ].filter((item) => combinedMenuItems.has(item.name))
    );
  }, [roles]);

  // Function to toggle screen lock
  const toggleScreenLock = () => {
    dispatch(setLockScreen());
    setIsScreenLocked(!isScreenLocked);
  };

  const handleMenuItemClick = (name) => {
    setSelectedMenuItem(name);
    // Update the 'current' property of each menu item and set the state
    const updatedNavigation = navigation.map((item) => ({
      ...item,
      current: item.name === name,
    }));
    setNavigation(updatedNavigation); // Assuming you have a state for navigation
    dispatch(handleMenuItemClickAsync(name));
    setClickedItem(null); // Reset clickedItem when menu item changes
  };

  const handleQuickAddClientClick = () => {
    dispatch(setActiveModalAsync("client")); // Set the active modal to 'client'
  };

  const handleQuickAddApptClick = () => {
    dispatch(setActiveModalAsync("appointment")); // Set the active modal to 'appointment'
  };

  const handleScheduleModalClick = () => {
    dispatch(setActiveModalAsync("schedule"));
  };

  const handleCloseQuickAddModal = () => {
    dispatch(setActiveModalAsync(null)); // Close the active modal
  };

  const handleSearchResultClick = (clickedItem) => {
    console.log("Search result clicked in App.js:", clickedItem);

    if (typeof clickedItem !== "object" || clickedItem === null) {
      console.log("Invalid clickedItem:", clickedItem);
      return;
    }

    const { type, name } = clickedItem;

    const updateNavigation = (targetName) => {
      const updatedNavigation = navigation.map((item) => ({
        ...item,
        current: item.name === targetName,
      }));
      setNavigation(updatedNavigation);
      setSelectedMenuItem(targetName);
      setClickedItem(clickedItem);
    };

    switch (type) {
      case "Client":
        console.log("Client clicked:", clickedItem);
        updateNavigation("Clients");
        break;

      case "User":
        console.log("User clicked:", clickedItem);
        updateNavigation("HR");
        break;

      case "QuickAddClient":
        console.log("Quick Add Client action triggered");
        handleQuickAddClientClick();
        break;

      case "QuickAddAppointment":
        console.log("Quick Add Appointment action triggered");
        handleQuickAddApptClick();
        break;

      case "ScheduleModal":
        console.log("Schedule Modal action triggered");
        handleScheduleModalClick();
        break;

      case "Service":
        console.log("service clicked", clickedItem);
        updateNavigation("Services");
        break;

      case "Invoice":
        console.log("invoice clicked", clickedItem);
        updateNavigation("Invoices");
        break;

      case "Intake":
        console.log(`${type} menu clicked`);
        handleMenuItemClick(type);
        setClickedItem(clickedItem);
        break;

      // For menu-related actions
      case "Dashboard":
      case "Clinicians":
      case "Week Schedule":
      case "Forms":
      case "Reports":
      case "HR":
      case "Manager":
      case "QuickBooks":
      case "Clinic":
        console.log("Clinic clicked", clickedItem);
        updateNavigation("Clinic");
        break;
      case "Payroll":
      case "HR Payroll":
      case "Payments":
      default:
        console.log("Unhandled item clicked:", clickedItem);
    }
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full">
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 bg-white pb-2 pl-6">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul>
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <button
                                  onClick={() => handleMenuItemClick(item.name)}
                                  className={classNames(
                                    item.current
                                      ? "bg-gray-50 text-indigo-600"
                                      : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full"
                                  )}>
                                  <item.icon
                                    className={classNames(
                                      item.current
                                        ? "text-indigo-600"
                                        : "text-gray-400 group-hover:text-indigo-600",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-48 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 border-r border-gray-200 bg-white pl-6">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-16 w-auto"
                src="https://www.hearsay.ca/wp-content/uploads/2022/03/hearsay_logo_fnl_c.jpg"
                alt="HearSay Speech and Hearing Centre"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <div className="flex flex-col h-full">
                <ul className="flex-1 flex flex-col gap-y-7">
                  <li>
                    <ul>
                      {navigation.map((item) => (
                        <li key={item.name}>
                          <button
                            onClick={() => handleMenuItemClick(item.name)}
                            className={classNames(
                              item.current
                                ? "bg-gray-50 text-indigo-600"
                                : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full"
                            )}>
                            <item.icon
                              className={classNames(
                                item.current
                                  ? "text-indigo-600"
                                  : "text-gray-400 group-hover:text-indigo-600",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li className="mt-auto -mx-6 flex flex-col">
                    <button
                      onClick={handleUserLogout}
                      className="group flex items-center gap-x-3 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                      <ArrowLeftStartOnRectangleIcon className="h-6 w-6 text-gray-400 group-hover:text-indigo-600" />
                      Logout
                    </button>
                    {!isScreenLocked && (
                      <button
                        onClick={toggleScreenLock}
                        className="group flex items-center gap-x-3 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                        <LockClosedIcon className="h-6 w-6 text-gray-400 group-hover:text-indigo-600" />
                        Lock Screen
                      </button>
                    )}
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>

        {isScreenLocked && <LockScreen user={user} />}
        <main className={isScreenLocked ? "hidden" : "lg:pl-48"}>
          {/* UserBanner positioned to the right */}
          <div className="ml-auto">
            {/* Pushes UserBanner to the right */}
            <UserBanner
              onQuickAddClientClick={handleQuickAddClientClick}
              onQuickAddApptClick={handleQuickAddApptClick}
              onScheduleModalClick={handleScheduleModalClick}
              user={user}
              onSearchResultClick={handleSearchResultClick}
            />
          </div>

          <div className="px-4 sm:px-6 lg:px-8">
            {selectedMenuItem === "Dashboard" && (
              <DashboardPage user={user} clickedItem={clickedItem} />
            )}
            {selectedMenuItem === "Clinicians" && (
              <CliniciansPage clickedItem={clickedItem} />
            )}
            {selectedMenuItem === "Clients" && (
              <ClientsPage clickedItem={clickedItem} />
            )}
            {selectedMenuItem === "Week Schedule" && (
              <SchedulePage clickedItem={clickedItem} />
            )}
            {selectedMenuItem === "Forms" && (
              <FormsPage clickedItem={clickedItem} />
            )}
            {selectedMenuItem === "Reports" && (
              <ReportsPage clickedItem={clickedItem} />
            )}
            {selectedMenuItem === "HR" && <HRPage clickedItem={clickedItem} />}
            {selectedMenuItem === "Manager" && (
              <ManagerPage clickedItem={clickedItem} />
            )}
            {selectedMenuItem === "QuickBooks" && (
              <QuickBooks clickedItem={clickedItem} />
            )}
            {selectedMenuItem === "Clinic" && (
              <ClinicPage clickedItem={clickedItem} />
            )}
            {selectedMenuItem === "Payroll" && (
              <ClinicianPayrollOverviewPage clickedItem={clickedItem} />
            )}
            {selectedMenuItem === "HR Payroll" && (
              <HRPayrollOverviewPage clickedItem={clickedItem} />
            )}
            {selectedMenuItem === "Hourly Payroll" && (
              <HourlyPayrollPage clickedItem={clickedItem} />
            )}
            {selectedMenuItem === "Services" && (
              <ServicesPage clickedItem={clickedItem} />
            )}
            {selectedMenuItem === "Invoices" && (
              <InvoicesPage clickedItem={clickedItem} />
            )}
            {selectedMenuItem === "Payments" && (
              <PaymentsPage clickedItem={clickedItem} />
            )}
            {selectedMenuItem === "Intake" && (
              <IntakesPage clickedItem={clickedItem} />
            )}
          </div>
        </main>
      </div>
      <QuickAddModal
        activeModal={activeModal}
        onClose={handleCloseQuickAddModal}
      />
    </>
  );
}
