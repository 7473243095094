import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ToDoItem from "../../../applicationUi/components/toDoItem";
import Accordion from "../../../applicationUi/components/Accordion";
import { logInfo, logError } from "../../../utils/logger";

function OfficeManagerToDoList({ onReschedule, onWideSidePanelClose }) {
  const { todos, loading, error } = useSelector((state) => state.todos);

  useEffect(() => {
    logInfo('OfficeManagerToDoList rendered', {
      componentName: 'OfficeManagerToDoList',
      todosCount: todos ? Object.values(todos).flat().length : 0,
      loading,
      hasError: !!error
    });
  }, [todos, loading, error]);

  if (loading) {
    logInfo('OfficeManagerToDoList loading', { componentName: 'OfficeManagerToDoList' });
    return <div>Loading...</div>;
  }

  if (error) {
    logError('Error in OfficeManagerToDoList', {
      componentName: 'OfficeManagerToDoList',
      error: error.message || error
    });
    return <div>Error: {error}</div>;
  }

  if (!todos || typeof todos !== "object") {
    logInfo('No todos found in OfficeManagerToDoList', { componentName: 'OfficeManagerToDoList' });
    return <div>No To-Do items found.</div>;
  }

  const totalTodos = Object.values(todos).flat().length;

  return (
    <div>
      <h2 className="mx-auto mt-8 max-w-6xl text-lg font-medium leading-6 text-gray-900">
        To Do List - {totalTodos} items
      </h2>
      <div className="mt-2 mx-auto max-w-8xl max-h-112 overflow-y-auto divide-y divide-gray-200">
        {Object.entries(todos).map(([category, todosInCategory]) => {
          logInfo('Rendering todo category', {
            componentName: 'OfficeManagerToDoList',
            category,
            todoCount: todosInCategory.length
          });
          return (
            <Accordion
              key={category}
              title={`${category} (${todosInCategory.length})`}>
              <ul>
                {todosInCategory.map((todo) => (
                  <ToDoItem
                    key={todo._id}
                    todo={todo}
                    onReschedule={onReschedule}
                    onWideSidePanelClose={onWideSidePanelClose}
                  />
                ))}
              </ul>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
}

export default OfficeManagerToDoList;
