import RadioGroup from "../../applicationUi/components/intake/RadioGroup";

const FluencyConcernsSection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold">E - Fluency/Stuttering Concerns</h2>

    {/* Does your child stutter? */}
    <RadioGroup
      label="Does your child stutter?"
      name="fluencyConcerns.stutters"
      options={[
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
      ]}
      selectedValue={formData.fluencyConcerns.stutters}
      onChange={handleChange}
    />

    {/* Conditional rendering for "yes" */}
    {formData.fluencyConcerns.stutters === "yes" && (
      <>
        <RadioGroup
          label="Avoids Talking at Home or School?"
          name="fluencyConcerns.avoidsTalkingAtHomeOrSchool"
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
            { value: "rarely", label: "Rarely" },
          ]}
          selectedValue={formData.fluencyConcerns.avoidsTalkingAtHomeOrSchool}
          onChange={handleChange}
        />

        <RadioGroup
          label="Avoids Talking to Adults?"
          name="fluencyConcerns.avoidsTalkingToAdults"
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
            { value: "rarely", label: "Rarely" },
          ]}
          selectedValue={formData.fluencyConcerns.avoidsTalkingToAdults}
          onChange={handleChange}
        />

        <RadioGroup
          label="Avoids Talking to Peers?"
          name="fluencyConcerns.avoidsTalkingToPeers"
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
            { value: "rarely", label: "Rarely" },
          ]}
          selectedValue={formData.fluencyConcerns.avoidsTalkingToPeers}
          onChange={handleChange}
        />

        <RadioGroup
          label="Body movements when stuttering? (e.g., chin twitches, leg slapping)"
          name="fluencyConcerns.bodyTwitchesWhenStuttering"
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
            { value: "rarely", label: "Rarely" },
          ]}
          selectedValue={formData.fluencyConcerns.bodyTwitchesWhenStuttering}
          onChange={handleChange}
        />
      </>
    )}

    {/* Move to next section if "no" */}
    {formData.fluencyConcerns.stutters === "no" && (
      <p className="text-sm text-gray-500">Please proceed to Section F.</p>
    )}
  </section>
);

export default FluencyConcernsSection;
