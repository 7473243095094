import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { removeFamilyRelationship } from "../../store/thunks/clientsThunk";
import { logInfo, logError } from "../../utils/logger";

export default function UnlinkFamilyMemberModal({
  memberType,
  clientId,
  familyMembers,
  onUnlink,
  onClose,
  onCancel,
  onUpdateClient,
}) {
  const [selectedMember, setSelectedMember] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedMember) {
      alert("Please select a family member to unlink.");
      return;
    }

    try {
      await dispatch(
        removeFamilyRelationship({
          parentId: memberType === "parent" ? selectedMember._id : clientId,
          childId: memberType === "parent" ? clientId : selectedMember._id,
        })
      );
      onUnlink({ selectedMember, relation: memberType });
      onUpdateClient();
      onClose();
      logInfo("Family relationship removed successfully", {
        memberType,
        clientId,
        selectedMemberId: selectedMember._id,
      });
    } catch (error) {
      console.error("Error removing family relationship:", error);
      logError("Error removing family relationship", {
        memberType,
        clientId,
        selectedMemberId: selectedMember._id,
        error: error.message,
      });
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
      <div
        className="fixed inset-0 bg-gray-900 opacity-50"
        onClick={onCancel}
      />
      <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4 relative z-10">
        <form onSubmit={handleSubmit}>
          <div className="border-b border-gray-900/10 pb-4 mb-4">
            <h2 className="text-lg font-semibold leading-7 text-gray-900">
              Unlink {memberType}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Select the {memberType} to unlink.
            </p>
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Select {memberType} to unlink
            </label>
            <select
              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={(e) =>
                setSelectedMember(familyMembers[e.target.value])
              }>
              <option value="">Select a {memberType}</option>
              {familyMembers.map((member, index) => (
                <option key={member._id} value={index}>
                  {member.firstName} {member.lastName}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-4">
            <button
              type="button"
              onClick={onCancel}
              className="text-sm font-semibold leading-6 text-gray-900">
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500">
              Unlink
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
