import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";

export default function RescheduleModal({
  isOpen,
  onClose,
  onReschedule,
  newStartDate, // Receive from parent
  setNewStartDate, // Update parent's state
  newEndDate, // Receive from parent
  setNewEndDate, // Update parent's state
  rescheduleStatus,
  rescheduleError,
}) {
  if (!isOpen) return null;

  return (
    <div className="modal fixed inset-0 z-60 overflow-auto bg-black bg-opacity-50 flex">
      <div className="bg-white rounded-lg p-6 mx-auto my-auto">
        <h3 className="text-lg font-semibold mb-4">Reschedule Appointment</h3>
        <div className="mb-4">
          <label className="block text-gray-700">Start Date & Time</label>
          <DatePicker
            selected={newStartDate} // Use parent's state
            onChange={(date) => setNewStartDate(date)} // Update parent's state
            showTimeSelect
            timeIntervals={5}
            dateFormat="Pp"
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">End Date & Time</label>
          <DatePicker
            selected={newEndDate} // Use parent's state
            onChange={(date) => setNewEndDate(date)} // Update parent's state
            showTimeSelect
            timeIntervals={5}
            dateFormat="Pp"
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        {/* Feedback Message */}
        {rescheduleStatus === "success" && (
          <p className="text-green-500">
            Appointment successfully rescheduled!
          </p>
        )}
        {rescheduleStatus === "error" && (
          <p className="text-red-500">{rescheduleError}</p>
        )}
        <div className="flex justify-end mt-4">
          <button
            className="bg-gray-500 text-white rounded px-4 py-2 mr-2"
            onClick={onClose}>
            Close
          </button>
          <button
            className="bg-blue-500 text-white rounded px-4 py-2"
            onClick={onReschedule}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
