import UserCards from "../../../applicationUi/components/UserCards";
import AppointmentList from "../../../applicationUi/components/AppointmentList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchAppointmentsList } from "../../../store/thunks/appointmentsThunk";
import AdminToDoList from "./AdminToDoList";
import { fetchTodos } from "../../../store/thunks/todoThunk";
import { logInfo, logError } from "../../../utils/logger"; // Import both logging functions

export default function AdminDashboardComponent({
    handleClientClick,
    handleAppointmentClick,
    handleReschedule,
}) {
    const dispatch = useDispatch();
    const appointments = useSelector(
        (state) => state.appointments.appointments || []
    );
    const [filteredAppointments, setFilteredAppointments] = useState([]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        dispatch(fetchAppointmentsList())
            .unwrap()
            .then(() => {
                logInfo('Appointments fetched successfully', {
                    componentName: 'AdminDashboardComponent',
                    action: 'fetchAppointmentsList'
                });
            })
            .catch(error => {
                logError('Failed to fetch appointments', {
                    componentName: 'AdminDashboardComponent',
                    action: 'fetchAppointmentsList',
                    error: error.message
                });
            });

        dispatch(fetchTodos({ userRole: "admin", clinicianId: "" }))
            .unwrap()
            .then(() => {
                logInfo('Todos fetched successfully', {
                    componentName: 'AdminDashboardComponent',
                    action: 'fetchTodos',
                    userRole: 'admin'
                });
            })
            .catch(error => {
                logError('Failed to fetch todos', {
                    componentName: 'AdminDashboardComponent',
                    action: 'fetchTodos',
                    userRole: 'admin',
                    error: error.message
                });
            });
    }, [dispatch, refresh]);

    useEffect(() => {
        setFilteredAppointments(appointments);
        logInfo('Filtered appointments updated', {
            componentName: 'AdminDashboardComponent',
            action: 'updateFilteredAppointments',
            appointmentsCount: appointments.length
        });
    }, [appointments]);

    useEffect(() => {
        logInfo('AdminDashboardComponent rendered', {
            componentName: 'AdminDashboardComponent',
            appointmentsCount: appointments.length,
            filteredAppointmentsCount: filteredAppointments.length,
            refresh: refresh
        });
    }, [appointments, filteredAppointments, refresh]);

    const handleFilterAppointments = (filterFunction) => {
        const filtered = filterFunction(appointments);
        setFilteredAppointments(filtered);
        logInfo('Appointments filtered', {
            componentName: 'AdminDashboardComponent',
            action: 'filterAppointments',
            filteredCount: filtered.length
        });
    };

    const handleWideSidePanelClose = () => {
        setRefresh(prevRefresh => !prevRefresh);
        logInfo('Wide side panel closed', {
            componentName: 'AdminDashboardComponent',
            action: 'closeWideSidePanel'
        });
    }

    return (
        <div className="grid grid-cols-3 gap-6 pb-20">
            {/* Left Column */}
            <div className="col-span-2">
                {/* User Cards */}
                <UserCards
                    appointments={appointments}
                    onFilterAppointments={handleFilterAppointments}
                />

                {/* Appointment List aka Recent Activity */}
                <AppointmentList
                    appointments={filteredAppointments}
                    onAppointmentClick={handleAppointmentClick}
                    onClientClick={handleClientClick}
                    onReschedule={handleReschedule}
                />
            </div>

            {/* Right Column */}
            <div className="col-span-1">
                <AdminToDoList
                    onReschedule={handleReschedule}
                    onWideSidePanelClose={handleWideSidePanelClose}
                />
            </div>
        </div>
    );
}
