import RadioGroup from "../../applicationUi/components/intake/RadioGroup";

const HearingLossSection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold">F - Suspected Hearing Loss</h2>

    {/* Do you suspect hearing loss? */}
    <RadioGroup
      label="Do you suspect your child has hearing loss?"
      name="hearingLossConcerns.hasHearingLoss"
      options={[
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
      ]}
      selectedValue={formData.hearingLossConcerns.hasHearingLoss}
      onChange={handleChange}
    />

    {/* Conditional rendering for "yes" */}
    {formData.hearingLossConcerns.hasHearingLoss === "yes" && (
      <>
        <RadioGroup
          label="Needs repetition frequently?"
          name="hearingLossConcerns.needsRepetition"
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
            { value: "rarely", label: "Rarely" },
          ]}
          selectedValue={formData.hearingLossConcerns.needsRepetition}
          onChange={handleChange}
        />

        <RadioGroup
          label="Frequent ear infections?"
          name="hearingLossConcerns.frequentEarInfections"
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
            { value: "rarely", label: "Rarely" },
          ]}
          selectedValue={formData.hearingLossConcerns.frequentEarInfections}
          onChange={handleChange}
        />

        <RadioGroup
          label="Difficulty following directions?"
          name="hearingLossConcerns.difficultyFollowingDirections"
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
            { value: "rarely", label: "Rarely" },
          ]}
          selectedValue={
            formData.hearingLossConcerns.difficultyFollowingDirections
          }
          onChange={handleChange}
        />

        <RadioGroup
          label="Difficulty hearing in noisy environments?"
          name="hearingLossConcerns.difficultyHearingInNoisyEnvironment"
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
            { value: "rarely", label: "Rarely" },
          ]}
          selectedValue={
            formData.hearingLossConcerns.difficultyHearingInNoisyEnvironment
          }
          onChange={handleChange}
        />
      </>
    )}

    {/* Move to next section if "no" */}
    {formData.hearingLossConcerns.hasHearingLoss === "no" && (
      <p className="text-sm text-gray-500">Please proceed to Section G.</p>
    )}
  </section>
);

export default HearingLossSection;
