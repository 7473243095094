import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { fetchQboConnectionStatus } from "../../store/thunks/qboThunk";

export default function QuickBooksSuccess() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isConnected = useSelector((state) => state.qbo.isConnected); // Get connection status from Redux
  const status = useSelector((state) => state.qbo.status); // Loading/success/failure status

  // Fetch connection status on mount
  useEffect(() => {
    if (isConnected === null) {
      dispatch(fetchQboConnectionStatus());
    }
  }, [dispatch, isConnected]);

  // Navigate to the dashboard if connected
  useEffect(() => {
    if (isConnected) {
      navigate("/dashboard");
    }
  }, [isConnected, navigate]);

  return (
    <div className="mt-4 text-center">
      {status === "loading" ? (
        <p className="text-gray-500">Checking QuickBooks connection...</p>
      ) : isConnected ? (
        <div className="flex items-center justify-center space-x-2 text-green-500">
          <CheckCircleIcon className="h-6 w-6" />
          <p className="text-lg font-semibold">
            Successfully connected to QuickBooks!
          </p>
        </div>
      ) : (
        <div className="flex items-center justify-center space-x-2 text-red-500">
          <ExclamationCircleIcon className="h-6 w-6" />
          <p className="text-lg font-semibold">
            Failed to connect to QuickBooks.
          </p>
        </div>
      )}
    </div>
  );
}
