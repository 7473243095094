const CheckboxGroup = ({
  label,
  namePrefix,
  options,
  selectedOptions,
  onChange,
}) => (
  <div className="pb-4">
    <label className="block text-gray-700">{label}</label>
    <div className="flex flex-wrap gap-4">
      {options.map(({ name, label }) => (
        <label key={name} className="flex items-center">
          <input
            type="checkbox"
            name={`${namePrefix}.${name}`}
            checked={selectedOptions[name]}
            onChange={onChange}
            className="mr-2"
          />
          {label}
        </label>
      ))}
    </div>
  </div>
);
export default CheckboxGroup;
