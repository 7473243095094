import React, { useState } from "react";
import InvoiceGenerator from "./InvoiceGenerator";
import EditInvoiceForm from "./EditInvoiceForm";

function InvoiceList({ invoices = [], refetchInvoices }) {
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [editingInvoice, setEditingInvoice] = useState(null);
  const [selectedInvoiceIndex, setSelectedInvoiceIndex] = useState(null); // Track selected invoice by index

  const onInvoiceClick = (invoice, index) => {
    setSelectedInvoice(invoice);
    setSelectedInvoiceIndex(index); // Set the index of the selected invoice
    setEditingInvoice(null);
  };

  const handleEditInvoice = (invoice, index) => {
    setEditingInvoice(invoice);
    setSelectedInvoice(null);
    setSelectedInvoiceIndex(index); // Set the index of the edited invoice
  };

  const formatPrice = (price) => {
    if (price === undefined || price === null) {
      return "$0.00";
    }
    const numPrice = Number(price);
    return `$${numPrice.toFixed(2)}`;
  };

  return (
    <div className="mt-2">
      <div className="mx-auto max-w-7xl  ">
        <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
                  Actions
                </th>
                <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
                  Invoice ID
                </th>
                <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
                  Created
                </th>
                <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
                  Client
                </th>
                <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
                  Bill To
                </th>
                <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
                  Service Date
                </th>

                <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
                  Service
                </th>

                <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {invoices.map((invoice, index) => (
                <React.Fragment key={`${invoice._id}-${index}`}>
                  <tr className="bg-white hover:bg-gray-100 cursor-pointer">
                    <td className="px-2 py-4 text-sm text-gray-900">
                      <button
                        className="text-blue-500 hover:text-blue-700"
                        onClick={() => handleEditInvoice(invoice, index)}>
                        Edit
                      </button>
                    </td>
                    <td
                      className="px-2 py-4 text-sm text-gray-900"
                      onClick={() => onInvoiceClick(invoice, index)}>
                      {invoice.invoiceId}
                    </td>

                    <td
                      className="px-2 py-4 text-sm text-gray-900"
                      onClick={() => onInvoiceClick(invoice, index)}>
                      {new Date(invoice.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </td>
                    <td
                      className="px-2 py-4 text-sm text-gray-900"
                      onClick={() => onInvoiceClick(invoice, index)}>
                      {invoice.client.firstName} {invoice.client.lastName}
                    </td>

                    <td
                      className="px-2 py-4 text-sm text-gray-900"
                      onClick={() => onInvoiceClick(invoice, index)}>
                      {invoice?.splitBilling?.isChild
                        ? `${invoice.client?.firstName || ""} ${
                            invoice.client?.lastName || ""
                          }`
                        : invoice?.splitBilling?.isParent
                        ? `${
                            invoice.splitBilling.parentInfo?.firstName || ""
                          } ${invoice.splitBilling.parentInfo?.lastName || ""}`
                        : `${invoice.client?.firstName || ""} ${
                            invoice.client?.lastName || ""
                          }`}
                    </td>

                    <td
                      className="px-2 py-4 text-sm text-gray-900"
                      onClick={() => onInvoiceClick(invoice, index)}>
                      {new Date(invoice.serviceDate).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </td>

                    <td
                      className="px-2 py-4 text-sm text-gray-900"
                      onClick={() => onInvoiceClick(invoice, index)}>
                      {invoice.services.map((service) => (
                        <div key={service._id}>
                          {service.description}{" "}
                          <p> {formatPrice(invoice.amount)}</p>
                        </div>
                      ))}
                    </td>
                    <td
                      className="px-2 py-4 text-sm text-gray-900"
                      onClick={() => onInvoiceClick(invoice, index)}>
                      {invoice.status}
                    </td>
                  </tr>

                  {/* Conditionally render InvoiceGenerator or EditInvoiceForm after the selected row */}
                  {selectedInvoiceIndex === index && selectedInvoice && (
                    <tr>
                      <td colSpan="8">
                        <div className="mb-4">
                          <InvoiceGenerator
                            data={selectedInvoice}
                            showAcceptPaymentButton={true}
                          />
                        </div>
                      </td>
                    </tr>
                  )}

                  {selectedInvoiceIndex === index && editingInvoice && (
                    <tr>
                      <td colSpan="7">
                        <EditInvoiceForm
                          invoice={editingInvoice}
                          closeForm={() => setEditingInvoice(null)}
                          refetchInvoices={refetchInvoices}
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default InvoiceList;
