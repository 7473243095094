import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  setIntakeForms,
  setIntakeForm,
  setLoading,
  setError,
} from "../slices/intakeSlice";
import { generateConfig } from "../../utils/api";

// Retrieve all intake forms
export const fetchIntakeForms = createAsyncThunk(
  "intake/fetchIntakeForms",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(`${baseURL}/api/intake`, config);
      dispatch(setIntakeForms(response.data));
      return response.data;
    } catch (error) {
      console.error("API Error in thunk:", error);
      dispatch(setError(error.message));
      return rejectWithValue(error.message);
    }
  }
);

// Retrieve an existing intake form
export const fetchIntakeFormById = createAsyncThunk(
  "intake/fetchIntakeForm",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(`${baseURL}/api/intake/${id}`, config);
      dispatch(setIntakeForm(response.data));
      return response.data;
    } catch (error) {
      dispatch(setError(error.message));
      return rejectWithValue(error.message);
    }
  }
);

// Create a new intake form
export const createIntakeForm = createAsyncThunk(
  "intakes/createIntakeForm",
  async (formData, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token; // If needed for authorization
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/intake`,
        formData,
        config
      );

      console.log("Response data from API:", response.data); // Debugging
      return response.data;
    } catch (error) {
      console.error("Error in createIntakeForm thunk:", error);
      return rejectWithValue(
        error.response?.data || "Error creating intake form"
      );
    }
  }
);

// Update an existing intake form
export const updateIntakeForm = createAsyncThunk(
  "intake/updateIntakeForm",
  async ({ id, updatedData }, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.patch(
        `${baseURL}/api/intake/${id}`,
        updatedData,
        config
      );
      dispatch(setIntakeForm(response.data));
      return response.data;
    } catch (error) {
      dispatch(setError(error.message));
      return rejectWithValue(error.message);
    }
  }
);

// Delete an existing intake form
export const deleteIntakeForm = createAsyncThunk(
  "intake/deleteIntakeForm",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      await axios.delete(`${baseURL}/api/intake/${id}`, config);
      dispatch(fetchIntakeForms()); // Refresh the list after deletion
      return id;
    } catch (error) {
      dispatch(setError(error.message));
      return rejectWithValue(error.message);
    }
  }
);
