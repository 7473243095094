import RadioGroup from "../../applicationUi/components/intake/RadioGroup";
import SelectField from "../../applicationUi/components/intake/SelectField";
import TextAreaField from "../../applicationUi/components/intake/TextAreaField";

const LanguageConcernsSection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold">C - Language Concerns</h2>

    {/* Difficulty Understanding or Using Language */}
    <RadioGroup
      label="Does your child have difficulty understanding or using language?"
      name="languageConcerns.difficultyWithLanguage"
      options={[
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
      ]}
      selectedValue={formData.languageConcerns.difficultyWithLanguage}
      onChange={handleChange}
    />

    {/* If No, prompt to go to Section D */}
    {formData.languageConcerns.difficultyWithLanguage === "no" && (
      <p className="text-sm text-gray-500">Please proceed to Section D.</p>
    )}

    {/* Conditional Rendering: Show only if 'yes' is selected */}
    {formData.languageConcerns.difficultyWithLanguage === "yes" && (
      <>
        {/* Language Production */}
        <h3 className="font-semibold mb-2">Language Production</h3>

        {/* Short Sentences */}
        <RadioGroup
          label="Does your child speak in very short sentences?"
          name="languageConcerns.shortSentences"
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
            { value: "rarely", label: "Rarely" },
          ]}
          selectedValue={formData.languageConcerns.shortSentences}
          onChange={handleChange}
        />

        {/* Incorrect Grammar */}
        <RadioGroup
          label="Does your child use incorrect word order or grammar?"
          name="languageConcerns.incorrectGrammar"
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
            { value: "rarely", label: "Rarely" },
          ]}
          selectedValue={formData.languageConcerns.incorrectGrammar}
          onChange={handleChange}
        />

        {/* Limited Vocabulary */}
        <RadioGroup
          label="Does your child have a limited vocabulary?"
          name="languageConcerns.limitedVocabulary"
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
            { value: "rarely", label: "Rarely" },
          ]}
          selectedValue={formData.languageConcerns.limitedVocabulary}
          onChange={handleChange}
        />

        {/* Difficulty Telling a Story */}
        <RadioGroup
          label="Does your child have difficulty telling a story?"
          name="languageConcerns.difficultyTellingStory"
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
            { value: "rarely", label: "Rarely" },
          ]}
          selectedValue={formData.languageConcerns.difficultyTellingStory}
          onChange={handleChange}
        />

        {/* Sentence Type */}
        <SelectField
          label="Which sentence best represents the type of sentence your child would say?"
          name="languageConcerns.sentenceType"
          value={formData.languageConcerns.sentenceType}
          onChange={handleChange}
          options={[
            { value: "", label: "-- Select --" },
            { value: "play-ball", label: "Play ball" },
            { value: "her-play-ball", label: "Her play ball" },
            { value: "she-playing-ball", label: "She playing ball" },
            { value: "she-is-playing-ball", label: "She is playing ball" },
            {
              value: "she-is-playing-ball-with-friends",
              label: "She is playing ball with her friends",
            },
          ]}
        />

        {/* Sentence Examples */}
        <TextAreaField
          label="Please give 1-2 examples of sentences your child says at the present time"
          name="languageConcerns.sentenceExamples"
          value={formData.languageConcerns.sentenceExamples}
          onChange={handleChange}
        />

        {/* Language Comprehension */}
        <h3 className="font-semibold mb-2">Language Comprehension</h3>

        {/* Understands Common Objects */}
        <RadioGroup
          label="Does your child understand the name of common objects?"
          name="languageConcerns.comprehension.understandsCommonObjects"
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
            { value: "rarely", label: "Rarely" },
          ]}
          selectedValue={
            formData.languageConcerns.comprehension.understandsCommonObjects
          }
          onChange={handleChange}
        />

        {/* Understands Questions */}
        <RadioGroup
          label="Does your child understand questions?"
          name="languageConcerns.comprehension.understandsQuestions"
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
            { value: "rarely", label: "Rarely" },
          ]}
          selectedValue={
            formData.languageConcerns.comprehension.understandsQuestions
          }
          onChange={handleChange}
        />

        {/* Understands Stories */}
        <RadioGroup
          label="Does your child understand familiar stories?"
          name="languageConcerns.comprehension.understandsStories"
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
            { value: "rarely", label: "Rarely" },
          ]}
          selectedValue={
            formData.languageConcerns.comprehension.understandsStories
          }
          onChange={handleChange}
        />
      </>
    )}
  </section>
);

export default LanguageConcernsSection;
