import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


const WaagChart = ({ waagData }) => {
  const [metrics, setMetrics] = useState([]);

  useEffect(() => {
    if (waagData && waagData.revenue) {
      // Transform waagData into the format expected by the component
      const transformedMetrics = [
        { 
          name: 'Total Revenue', 
          lastWeek: `$${waagData.revenue.lastWeekRevenue?.toFixed(2) || '0.00'}`,
          prevWeek: `$${waagData.revenue.prevWeekRevenue?.toFixed(2) || '0.00'}`,
          mtd: `$${waagData.revenue.mtdRevenue?.toFixed(2) || '0.00'}`,
          lastMonth: `$${waagData.revenue.lastMonthRevenue?.toFixed(2) || '0.00'}`,
          target: 'N/A', // Target is not provided in the new API response
          percentCurr: 'N/A', // Cannot calculate without target
          percentLast: 'N/A' // Cannot calculate without target
        },
        // Keep the appointments metric as is, since it wasn't affected by the API change
        { 
          name: 'Total Appointments', 
          lastWeek: waagData.appointments?.lastWeek?.toString() || '0',
          prevWeek: waagData.appointments?.prevWeek?.toString() || '0',
          mtd: waagData.appointments?.mtd?.toString() || '0',
          lastMonth: waagData.appointments?.lastMonth?.toString() || '0',
          target: waagData.appointments?.target?.toString() || '0',
          percentCurr: waagData.appointments?.mtd && waagData.appointments?.target 
            ? `${((waagData.appointments.mtd / waagData.appointments.target) * 100).toFixed(0)}%`
            : 'N/A',
          percentLast: waagData.appointments?.lastMonth && waagData.appointments?.target
            ? `${((waagData.appointments.lastMonth / waagData.appointments.target) * 100).toFixed(0)}%`
            : 'N/A'
        },
        // Add more metrics here as they become available in the waagData
      ];
      setMetrics(transformedMetrics);
    }
  }, [waagData]);

  if (!waagData || !waagData.revenue) {
    return <div>Loading...</div>;
  }

  return (
    <div className="overflow-x-auto bg-white shadow-md rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Metric</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Week</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Prev Week</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">MTD</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Month</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Target</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">% curr</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">% last</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {metrics.map((metric) => (
            <tr key={metric.name}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{metric.name}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.lastWeek}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.prevWeek}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.mtd}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.lastMonth}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.target}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.percentCurr}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.percentLast}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WaagChart;
