import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedMenuItem: 'Dashboard',
  navigation: [],
  clickedItem: null,
};

const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setSelectedMenuItem: (state, action) => {
      state.selectedMenuItem = action.payload;
    },
    setNavigation: (state, action) => {
      state.navigation = action.payload;
    },
    setClickedItem: (state, action) => {
      state.clickedItem = action.payload;
    },
    setActiveModal: (state, action) => {
      state.activeModal = action.payload;
    },
  },
});

export const { setSelectedMenuItem, setNavigation, setClickedItem, setActiveModal } = appStateSlice.actions;

export default appStateSlice.reducer;
