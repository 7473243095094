const CheckboxField = ({ label, name, checked, onChange }) => (
  <div className="pb-4">
    <label className="block text-gray-700 font-medium mb-2">
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        className="mr-2"
      />
      {label}
    </label>
  </div>
);

export default CheckboxField;
