import React from "react";

function PronounSelector({ value, onChange }) {
  const pronounOptions = [
    { value: "", label: "Select Pronouns" },
    { value: "he/him", label: "He/Him" },
    { value: "she/her", label: "She/Her" },
    { value: "they/them", label: "They/Them" },
    { value: "xe/xem", label: "Xe/Xem" },
    { value: "ze/zir", label: "Ze/Zir" },
    { value: "fae/faer", label: "Fae/Faer" },
    { value: "ey/em", label: "Ey/Em" },
    { value: "ve/ver", label: "Ve/Ver" },
    { value: "he/they", label: "He/They" },
    { value: "she/they", label: "She/They" },
    { value: "other", label: "Other" },
  ];

  return (
    <select
      value={value}
      onChange={onChange}
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
      {pronounOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default PronounSelector;
