import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createInvoice,
  updateInvoice,
  fetchInvoicesByClientId,
  createSplitInvoice,
  fetchInvoicesByAppointmentId,
} from "../../store/thunks/invoiceThunk";
import InvoiceGenerator from "./InvoiceGenerator";
import InvoiceList from "./InvoiceList";
import { XMarkIcon } from "@heroicons/react/24/solid"; // Import Heroicon
import CreateInvoice from "./CreateInvoice";
import {
  clearCurrentInvoice,
  setCurrentInvoice,
} from "../../store/slices/invoiceSlice";
import { fetchClientById, fetchParents } from "../../store/thunks/clientsThunk";
import { clearParents } from "../../store/slices/clientsSlice";
import QuickBooksLogo from "../../assets/quickbooks-1.svg";
import Table from "./Table";
import QuickAddClientModal from "../../modals/QuickAddClientModal";

export default function InvoiceModal({ appointment, onClose }) {
  const createInvoiceRef = useRef(null);
  const childInvoiceRef = useRef(null);
  const parentInvoiceRef = useRef(null);
  console.log("Appointment:", appointment);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const appointmentInvoices = useSelector(
    (state) => state.invoices.appointmentInvoices
  );
  const clientInvoices = useSelector((state) => state.invoices.clientInvoices);
  const appointmentInvoicess = useSelector(
    (state) => state.invoices.appointmentInvoicess
  );
  const currentInvoice = useSelector((state) => state.invoices.currentInvoice);
  const isLoading = useSelector((state) => state.invoices.status === "loading");
  const [viewMode, setViewMode] = useState("create");
  const [isEditing, setIsEditing] = useState(false);
  const [isSplitInvoice, setIsSplitInvoice] = useState(false);
  const [childInvoice, setChildInvoice] = useState(null);
  const [parentInvoice, setParentInvoice] = useState(null);
  const [singleInvoiceData, setSingleInvoiceData] = useState(null);
  const [splitInvoiceData, setSplitInvoiceData] = useState(null);
  const [showInvoiceGenerator, setShowInvoiceGenerator] = useState(false);

  console.log("Appointment Invoices:", appointmentInvoicess);
  console.log("Appointment:", appointment);
  console.log("is appointment invoice:", appointmentInvoicess);

  console.log("Child Invoice:", childInvoice);
  console.log("Parent Invoice:", parentInvoice);

  useEffect(() => {
    if (appointment?.client?._id) {
      dispatch(fetchInvoicesByClientId(appointment.client._id));
      dispatch(fetchInvoicesByAppointmentId(appointment._id));
    }
  }, [dispatch, appointment]);

  const editInvoice = () => {
    setIsEditing(true);
  };

  const onSplitInvoice = (invoiceData) => {
    console.log("Splitting invoice:", invoiceData);
    setIsSplitInvoice(true);
    setViewMode("createsplit");
  };

  const handleSubmit = async (singleInvoiceData) => {
    if (!singleInvoiceData) {
      console.error("No invoice data available"); // Early exit if no data
      return;
    }

    console.log(
      "In handleSubmit for singleInvoice with this data:",
      singleInvoiceData
    );

    // Include userId in singleInvoiceData
    const userId = user._id; // Assuming you have user data from state or props
    const invoiceDataWithId = { ...singleInvoiceData, userId };

    await dispatch(createInvoice(invoiceDataWithId)); // Dispatch the action to create invoice
    await dispatch(fetchInvoicesByAppointmentId(appointment._id));
    await dispatch(fetchClientById(appointment.client._id));
  };

  const handleSplitInvoiceSubmit = async () => {
    // Fetch invoice data from both child and parent refs
    const childInvoiceData = childInvoiceRef.current?.getInvoiceData();
    const parentInvoiceData = parentInvoiceRef.current?.getInvoiceData();

    if (childInvoiceData && parentInvoiceData) {
      // Dispatch the action with both invoices
      await dispatch(
        createSplitInvoice({ childInvoiceData, parentInvoiceData })
      );
    } else {
      console.error("Both child and parent invoices must be filled out.");
    }
  };

  const toggleView = (mode) => {
    setViewMode(mode);
  };

  const handleClose = () => {
    if (onClose) {
      // Reset appointmentInvoices to null before closing
      dispatch(clearCurrentInvoice());
      dispatch(clearParents());
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
      <div className="fixed inset-0 bg-gray-900 opacity-50" />
      <div className="bg-white rounded-lg p-8 max-w-8xl w-full mx-4 relative z-10 overflow-y-auto max-h-[90vh]">
        <div className="space-y-12 sm:space-y-16">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Invoices
            </h2>

            <button
              onClick={handleClose}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none">
              <XMarkIcon className="h-6 w-6" />
            </button>

            {/* Tabs to switch views */}
            <button className="inline-flex items-center mt-2 rounded-tl-md rounded-tr-md focus:outline-none">
              <div
                onClick={() => toggleView("create")}
                className={`${
                  viewMode === "create"
                    ? "bg-indigo-600 text-white"
                    : "bg-gray-200 text-gray-600"
                }  px-2.5 py-1.5 mt-2 text-sm font-semibold rounded-tl-md rounded-tr-md cursor-pointer`}>
                Create Invoice
              </div>

              <div
                onClick={() => toggleView("createsplit")}
                className={`${
                  viewMode === "createsplit"
                    ? "bg-indigo-600 text-white"
                    : "bg-gray-200 text-gray-600"
                }  px-2.5 py-1.5 mt-2 text-sm font-semibold rounded-tl-md rounded-tr-md cursor-pointer`}>
                Create Split Invoice
              </div>

              <div
                onClick={() => toggleView("viewAll")}
                className={`${
                  viewMode === "viewAll"
                    ? "bg-indigo-600 text-white"
                    : "bg-gray-200 text-gray-600"
                }  px-2.5 py-1.5 mt-2 text-sm font-semibold rounded-tl-md rounded-tr-md cursor-pointer`}>
                View All Invoices
              </div>
            </button>

            {viewMode === "create" &&
            (!appointmentInvoices || appointmentInvoices.length === 0) ? (
              <>
                <CreateInvoice
                  ref={createInvoiceRef} // Pass the ref here
                  isSplit={false}
                  appointment={appointment}
                  onSubmit={(invoiceData) => {
                    console.log("Invoice Data Submitted:", invoiceData);
                    setSingleInvoiceData(invoiceData); // Store the invoice data in state
                  }}
                />

                <div className="flex justify-between pt-8">
                  <p className="block text-sm font-small leading-6 text-gray-900">
                    After generating the invoice, you will be able to view,
                    download, print, or edit.
                  </p>
                  <button
                    onClick={() => {
                      const invoiceData =
                        createInvoiceRef.current.getInvoiceData(); // Get the invoice data
                      handleSubmit(invoiceData); // Call handleSubmit with the invoice data
                    }}
                    disabled={isLoading}
                    className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center">
                    {isLoading ? (
                      <>
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24">
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Generating...
                      </>
                    ) : (
                      "Generate Invoice"
                    )}
                  </button>
                </div>
              </>
            ) : viewMode === "create" &&
              appointmentInvoices &&
              appointmentInvoices.length > 0 ? (
              <>
                <div>
                  {!showInvoiceGenerator ? (
                    <Table
                      columns={[
                        {
                          header: "Invoice ID",
                          accessor: "invoiceId",
                        },
                        {
                          header: "Service Date",
                          accessor: (row) => {
                            const date = new Date(row.serviceDate);
                            return date.toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            });
                          },
                        },
                        {
                          header: "Invoice Date",
                          accessor: (row) => {
                            const date = new Date(row.createdAt);
                            return date.toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            });
                          },
                        },
                        {
                          header: "Client Name",
                          accessor: (row) =>
                            row.client
                              ? `${row.client.firstName || "Unknown"} ${
                                  row.client.lastName || ""
                                }`
                              : "Unknown",
                        },
                        {
                          header: "Clinician",
                          accessor: (row) =>
                            row.clinician
                              ? `${row.clinician.firstName || "Unknown"} ${
                                  row.clinician.lastName || ""
                                }`
                              : "Unknown",
                        },
                        {
                          header: "Amount",
                          accessor: (row) =>
                            `$${row.amount?.toFixed(2) || "0.00"}`,
                        },
                        {
                          header: "Split Invoice?",
                          accessor: (row) =>
                            row.splitBilling && row.splitBilling.isSplit
                              ? "Yes"
                              : "No",
                        },
                        {
                          header: "Status",
                          accessor: "status",
                        },
                        {
                          header: "QuickBooks",
                          accessor: (row) => (
                            <div className="flex items-center">
                              {row.QBOInvoiceId && (
                                <img
                                  src={QuickBooksLogo}
                                  alt="QuickBooks"
                                  className="ml-2 h-5 w-5"
                                  title="Loaded to QuickBooks"
                                />
                              )}
                            </div>
                          ),
                        },
                      ]}
                      data={appointmentInvoices}
                      onRowClick={(invoice) => {
                        // Find the clicked invoice from appointmentInvoices array
                        const invoiceObject = appointmentInvoices.find(
                          (inv) => inv._id === invoice
                        );
                        // Set the found invoice as current invoice in Redux store
                        dispatch(setCurrentInvoice(invoiceObject));
                        setShowInvoiceGenerator(true);
                      }}
                    />
                  ) : (
                    <InvoiceGenerator 
                      data={currentInvoice}
                      showAcceptPaymentButton={true}
                    />
                  )}
                  {appointmentInvoices.length > 1 && (
                    <div className="pt-4">
                      <p>Multiple invoices found for this appointment.</p>
                      {/* You might want to add a list or some other UI to show multiple invoices */}
                    </div>
                  )}
                </div>
              </>
            ) : viewMode === "createsplit" &&
              (!appointmentInvoices || appointmentInvoices.length === 0) ? (
              <>
                <div>
                  <h3 className="text-lg font-semibold mb-4">Child Invoice</h3>
                  <CreateInvoice
                    ref={childInvoiceRef} // Use the ref here
                    isSplit={true}
                    isParent={false}
                    appointment={appointment}
                    onSubmit={(invoiceData) => {
                      console.log("Child Invoice Data Submitted:", invoiceData);
                      setChildInvoice(invoiceData); // Store in state for tracking
                    }}
                  />

                  <h3 className="text-lg font-semibold mb-4">Parent Invoice</h3>
                  <CreateInvoice
                    ref={parentInvoiceRef} // Use a separate ref for parent
                    isSplit={true}
                    isParent={true}
                    appointment={appointment}
                    onSubmit={(invoiceData) => {
                      console.log(
                        "Parent Invoice Data Submitted:",
                        invoiceData
                      );
                      setParentInvoice(invoiceData); // Store in state for tracking
                    }}
                  />

                  <button
                    onClick={() => {
                      handleSplitInvoiceSubmit(); // Use async handler for both invoices
                    }}
                    className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700">
                    Submit Split Invoice
                  </button>
                </div>
              </>
            ) : viewMode === "createsplit" &&
              appointmentInvoices &&
              appointmentInvoices.length > 0 ? (
              isSplitInvoice ? (
                <>
                  <div className="space-y-8">
                    <div>
                      <h3 className="text-lg font-semibold mb-4">
                        Child Invoice
                      </h3>
                      <CreateInvoice
                        ref={createInvoiceRef}
                        isSplit={true}
                        isParent={false}
                        appointment={appointment}
                        onSubmit={(invoiceData) => {
                          console.log(
                            "Child Invoice Data Submitted:",
                            invoiceData
                          );
                          setChildInvoice(invoiceData);
                        }}
                      />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold mb-4">
                        Parent Invoice
                      </h3>
                      <CreateInvoice
                        ref={createInvoiceRef}
                        isSplit={true}
                        isParent={true}
                        appointment={appointment}
                        onSubmit={(invoiceData) => {
                          console.log(
                            "Parent Invoice Data Submitted:",
                            invoiceData
                          );
                          setParentInvoice(invoiceData);
                        }}
                      />
                    </div>
                    <button
                      onClick={() => {
                        if (childInvoice && parentInvoice) {
                          handleSplitInvoiceSubmit({
                            childInvoice,
                            parentInvoice,
                          });
                        } else {
                          console.error(
                            "Both child and parent invoices must be filled out."
                          );
                        }
                      }}
                      className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700">
                      Submit Split Invoice
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    {!showInvoiceGenerator ? (
                      <Table
                        columns={[
                          {
                            header: "Invoice ID",
                            accessor: "invoiceId",
                          },
                          {
                            header: "Service Date",
                            accessor: (row) => {
                              const date = new Date(row.serviceDate);
                              return date.toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              });
                            },
                          },
                          {
                            header: "Invoice Date",
                            accessor: (row) => {
                              const date = new Date(row.createdAt);
                              return date.toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              });
                            },
                          },
                          {
                            header: "Client Name",
                            accessor: (row) =>
                              row.client
                                ? `${row.client.firstName || "Unknown"} ${
                                    row.client.lastName || ""
                                  }`
                                : "Unknown",
                          },
                          {
                            header: "Clinician",
                            accessor: (row) =>
                              row.clinician
                                ? `${row.clinician.firstName || "Unknown"} ${
                                    row.clinician.lastName || ""
                                  }`
                                : "Unknown",
                          },
                          {
                            header: "Amount",
                            accessor: (row) =>
                              `$${row.amount?.toFixed(2) || "0.00"}`,
                          },
                          {
                            header: "Split Invoice?",
                            accessor: (row) =>
                              row.splitBilling && row.splitBilling.isSplit
                                ? "Yes"
                                : "No",
                          },
                          {
                            header: "Status",
                            accessor: "status",
                          },
                          {
                            header: "QuickBooks",
                            accessor: (row) => (
                              <div className="flex items-center">
                                {row.QBOInvoiceId && (
                                  <img
                                    src={QuickBooksLogo}
                                    alt="QuickBooks"
                                    className="ml-2 h-5 w-5"
                                    title="Loaded to QuickBooks"
                                  />
                                )}
                              </div>
                            ),
                          },
                        ]}
                        data={appointmentInvoices}
                        onRowClick={(invoice) => {
                          setShowInvoiceGenerator(true);
                          // Find the clicked invoice from appointmentInvoices array
                          const invoiceObject = appointmentInvoices.find(
                            (inv) => inv._id === invoice._id
                          );
                          // Set the found invoice as current invoice in Redux store
                          dispatch(setCurrentInvoice(invoiceObject));
                        }}
                      />
                    ) : (
                      <InvoiceGenerator 
                        data={currentInvoice}
                        showAcceptPaymentButton={true}
                      />
                    )}
                    {appointmentInvoices.length > 1 && (
                      <div className="pt-4">
                        <p>Multiple invoices found for this appointment.</p>
                        {/* You might want to add a list or some other UI to show multiple invoices */}
                      </div>
                    )}
                  </div>
                </>
              )
            ) : viewMode === "viewAll" ? (
              <InvoiceList invoices={clientInvoices} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
