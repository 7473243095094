import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import SearchDropdown from "./SearchDropdown";
import { fetchParents } from "../../store/thunks/clientsThunk";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAppointmentById,
  fetchClinicById,
  fetchClinicianById,
} from "../../store/store";
import SetCliniciansModal from "../../modals/setCliniciansModal";
import QuickAddClientModal from "../../modals/QuickAddClientModal";

const CreateInvoice = forwardRef(
  ({ onSubmit, isParent = false, isSplit = false }, ref) => {
    const appointment = useSelector(
      (state) => state.appointments.selectedAppointment
    );

    const [services, setServices] = useState([
      {
        _id: appointment.service._id,
        description: appointment.service.description,
        quantity: 1,
        notes: "",
        amount: appointment.service.price,
      },
    ]);
    const [notes, setNotes] = useState("");
    const [showSetCliniciansModal, setShowSetCliniciansModal] = useState(false);
    const [isClinicianFetched, setIsClinicianFetched] = useState(false);

    const [selectedClient, setSelectedClient] = useState(
      isParent ? null : appointment.client
    );
    const [selectedService, setSelectedService] = useState(null);
    const [parentNotFound, setParentNotFound] = useState(false);
    const [editingClinician, setEditingClinician] = useState(false);
    // const statementClinician = useSelector(
    //   (state) => state.clinicians.selectedClinician
    // );
    const statementClinician = appointment.clinician;
    const [clinicianData, setClinicianData] = useState(statementClinician);

    const dispatch = useDispatch();
    const parents = useSelector((state) => state.clients.parents);
    const updatedClient = useSelector((state) => state.clients.selectedClient);

    const [isQuickAddClientModalOpen, setIsQuickAddClientModalOpen] = useState(false);

    const handleChargeChange = (index, field, value) => {
      const updatedServices = [...services];
      updatedServices[index][field] =
        field === "amount" ? parseFloat(value) || 0 : value;
      setServices(updatedServices);
    };

    const handleServiceSelection = (service, index) => {
      const updatedServices = [...services];
      updatedServices[index] = {
        ...updatedServices[index],
        _id: service._id,
        description: service.description,
        amount: service.price,
      };
      setServices(updatedServices);
    };

    const handleClinicianSelection = (clinician) => {
      setClinicianData(clinician); // Update state with new clinician
      setEditingClinician(false); // Ensure the editing mode exits
    };

    // const handleClinicianSelection = (clinician) => {
    //   setClinicianData(clinician);
    //   setEditingClinician(false);
    // };

    const addCharge = () => {
      setServices((prevServices) => [
        ...prevServices,
        { _id: "", description: "", quantity: 1, notes: "", amount: 0 },
      ]);
    };

    const removeCharge = (index) => {
      const updatedServices = services.filter((_, i) => i !== index);
      setServices(updatedServices);
    };

    const calculateSubtotal = () =>
      services.reduce(
        (total, charge) => total + charge.quantity * charge.amount,
        0
      );

    const calculateTax = () => {
      const taxRate =
        appointment.service.tax && appointment.service.tax.isTaxable
          ? appointment.service.tax.taxRate
          : 0;
      return calculateSubtotal() * taxRate;
    };

    const calculateTotal = () => calculateSubtotal() + calculateTax();

    const handleNotesChange = (e) => setNotes(e.target.value);

    const handleClientSelection = (client) => {
      setSelectedClient(client);
      if (client) setParentNotFound(false); // Reset parentNotFound if a client is selected
    };

    useEffect(() => {
      if (appointment?.client?._id) {
        dispatch(fetchParents(appointment.client._id));
      }
    }, [dispatch, appointment]);
    // const [clinicianData, setClinicianData] = useState(null);

    useEffect(() => {
      const loadClinicianData = async () => {
        if (!isClinicianFetched) {
          if (appointment?.clinician?.specialization !== "SLP") {
            if (appointment.client.supervisingClinician?.current) {
              const fetchedClinician = await dispatch(
                fetchClinicianById(
                  appointment.client.supervisingClinician.current
                )
              ).unwrap();
              setClinicianData(fetchedClinician);
            } else {
              setShowSetCliniciansModal(true);
            }
          } else {
            setClinicianData(appointment.clinician);
          }
          setIsClinicianFetched(true);
        }
      };

      loadClinicianData();
    }, [appointment, dispatch, isClinicianFetched]);

    // useEffect(() => {
    //   const loadClinicianData = async () => {
    //     if (appointment?.clinician?.specialization !== "SLP") {
    //       if (appointment.client.supervisingClinician?.current) {
    //         if (
    //           !clinicianData ||
    //           clinicianData._id !==
    //             appointment.client.supervisingClinician.current
    //         ) {
    //           const fetchedClinician = await dispatch(
    //             fetchClinicianById(
    //               appointment.client.supervisingClinician.current
    //             )
    //           ).unwrap();
    //           setClinicianData(fetchedClinician); // Ensure the state is updated with fetched data
    //         }
    //       } else {
    //         setShowSetCliniciansModal(true);
    //       }
    //     } else {
    //       setClinicianData(appointment.clinician);
    //     }
    //   };

    //   loadClinicianData();
    // }, [appointment, dispatch, clinicianData]);

    // useEffect(() => {
    //   const loadClinicianData = async () => {
    //     if (appointment?.clinician?.specialization !== "SLP") {
    //       if (appointment.client.supervisingClinician.current) {
    //         // Only fetch if clinicianData hasn't been set yet or if the ID changed
    //         if (
    //           !clinicianData ||
    //           clinicianData._id !==
    //             appointment.client.supervisingClinician.current
    //         ) {
    //           await dispatch(
    //             fetchClinicianById(
    //               appointment.client.supervisingClinician.current
    //             )
    //           );
    //           // setClinicianData(statementClinician);
    //         }
    //       } else {
    //         setShowSetCliniciansModal(true);
    //       }
    //     } else {
    //       setClinicianData(appointment.clinician);
    //     }
    //   };
    //   loadClinicianData();
    // }, [
    //   appointment,
    //   dispatch,
    //   statementClinician,
    //   clinicianData,
    //   updatedClient,
    // ]);

    const renderClinician = () => {
      if (editingClinician) {
        return (
          <div className="max-w-md">
            <SearchDropdown
              prefix=":clinician:"
              displayFields={["firstName", "lastName", "qualifications"]}
              onSelection={handleClinicianSelection}
              placeholder="Search for a clinician"
            />
          </div>
        );
      }

      if (!clinicianData) return <p>No clinician assigned</p>;

      return (
        <div className="flex justify-between items-start">
          <div>
            <p>
              {clinicianData.firstName} {clinicianData.lastName}
            </p>
            <p>{clinicianData.qualifications || clinicianData.jobTitle}</p>
          </div>
        </div>
      );
    };

    // const renderClinician = () => {
    //   if (editingClinician) {
    //     return (
    //       <div className="max-w-md">
    //         <SearchDropdown
    //           prefix=":clinician:"
    //           displayFields={["firstName", "lastName", "qualifications"]}
    //           onSelection={handleClinicianSelection}
    //           placeholder="Search for a clinician"
    //         />
    //       </div>
    //     );
    //   }

    //   if (!clinicianData) return null;

    //   return (
    //     <div className="flex justify-between items-start">
    //       <div>
    //         {clinicianData.invoiceDescriptor &&
    //         clinicianData.invoiceDescriptor !== "" ? (
    //           <>
    //             <p>
    //               {clinicianData.firstName} {clinicianData.lastName}
    //             </p>
    //             <p>{clinicianData.invoiceDescriptor}</p>
    //           </>
    //         ) : (
    //           <>
    //             <p>
    //               {clinicianData.firstName} {clinicianData.lastName}
    //             </p>
    //             <p>{clinicianData.qualifications}</p>
    //             <p>{clinicianData.jobTitle}</p>
    //           </>
    //         )}
    //       </div>
    //     </div>
    //   );
    // };

    useImperativeHandle(ref, () => ({
      getInvoiceData: () => {
        console.log("invoice data is being called");
        const validServices = services.filter((service) => service._id);
        if (validServices.length !== services.length) {
          console.error("Some services are missing valid _id fields");
          return null; // Return null if there are invalid services
        }

        return {
          appointmentId: appointment._id,
          client: selectedClient,
          services: validServices,
          clinic: appointment.clinic,
          clinician: clinicianData,
          serviceDate: appointment.start,
          notes,
          amount: calculateTotal(),
          status: "created",
        };
      },
    }));

    return (
      <div className="space-y-8 border-t border-b border-gray-900/10 pb-12">
        {/* Header with Clinician and Invoice Info */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 py-6">
          <div>
            <div className="flex justify-between items-center">
              <h2 className="text-base font-semibold text-gray-900">
                Clinician
              </h2>
              <button
                onClick={() => setEditingClinician(true)}
                className="text-indigo-600 hover:text-indigo-800">
                Edit
              </button>
            </div>
            {renderClinician()}
          </div>
          <div className="text-right">
            <h2 className="text-base font-semibold text-gray-900 mt-4">
              Clinic
            </h2>
            <p>{appointment.clinic.name}</p>
            <p>
              {appointment.clinic.address.street},{" "}
              {appointment.clinic.address.city},{" "}
              {appointment.clinic.address.province},{" "}
              {appointment.clinic.address.postalCode}
            </p>
            <p>
              Service Date:{" "}
              {new Date(appointment.start).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </p>
          </div>
        </div>

        {/* Client and Bill To Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 py-6">
          <div className="border p-4">
            <h2 className="text-base font-semibold border-b pb-2 mb-2">
              Client
            </h2>
            {isParent ? (
              <>
                {parentNotFound || parents.length === 0 ? (
                  <>
                    <SearchDropdown
                      prefix=":client:"
                      displayFields={["firstName", "lastName", "email"]}
                      onSelection={handleClientSelection}
                      placeholder="Search for a client to find their parents"
                    />
                    <button
                      type="button"
                      onClick={() => setIsQuickAddClientModalOpen(true)}
                      className="mt-2 text-sm font-semibold leading-6 text-indigo-600">
                      Can't find the parent? Add a new one
                    </button>
                  </>
                ) : (
                  <select
                    onChange={(e) =>
                      handleClientSelection(
                        parents.find((parent) => parent._id === e.target.value)
                      )
                    }
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option value="">Select a parent</option>
                    {parents.map((parent) => (
                      <option key={parent._id} value={parent._id}>
                        {parent.firstName} {parent.lastName}
                      </option>
                    ))}
                  </select>
                )}
                <div className="flex items-center mt-2">
                  <input
                    id="searchAllClients"
                    type="checkbox"
                    checked={parentNotFound}
                    onChange={() => setParentNotFound(!parentNotFound)}
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="searchAllClients"
                    className="ml-2 block text-sm text-gray-900">
                    Search all clients
                  </label>
                </div>
              </>
            ) : (
              <>
                <p>
                  {appointment.client.firstName} {appointment.client.lastName}
                </p>
                <p>
                  {appointment.client.address.streetNumber}{" "}
                  {appointment.client.address.streetName}
                </p>
                <p>
                  {appointment.client.address.city},{" "}
                  {appointment.client.address.province},{" "}
                  {appointment.client.address.postalCode}
                </p>
              </>
            )}
          </div>
          <div className="border p-4">
            <h2 className="text-base font-semibold border-b pb-2 mb-2">
              Bill To
            </h2>
            {selectedClient ? (
              <>
                <p>
                  {selectedClient.firstName} {selectedClient.lastName}
                </p>
                <p>
                  {selectedClient.address.streetNumber}{" "}
                  {selectedClient.address.streetName}
                </p>
                <p>
                  {selectedClient.address.city},{" "}
                  {selectedClient.address.province},{" "}
                  {selectedClient.address.postalCode}
                </p>
              </>
            ) : (
              <p>Please select a client</p>
            )}
          </div>
        </div>

        {/* Services Table */}
        <div className="py-6">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th className="py-3.5 text-left text-sm font-semibold">
                  Description
                </th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold">
                  Qty
                </th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold">
                  Notes
                </th>
                <th className="px-3 py-3.5 text-right text-sm font-semibold">
                  Amount
                </th>
                <th className="px-3 py-3.5 text-right text-sm font-semibold">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {services.map((service, index) => (
                <tr key={index}>
                  <td>
                    {isSplit ? (
                      service.description ? (
                        <div>{service.description}</div>
                      ) : (
                        <SearchDropdown
                          prefix=":service:"
                          displayFields={[
                            "serviceCode",
                            "description",
                            "price",
                          ]}
                          onSelection={(selectedService) =>
                            handleServiceSelection(selectedService, index)
                          }
                          placeholder={
                            "Search for a service by name or service code"
                          }
                          activeServices={true}
                        />
                      )
                    ) : (
                      <>
                        {service.description || (
                          <SearchDropdown
                            prefix=":service:"
                            displayFields={[
                              "serviceCode",
                              "description",
                              "price",
                            ]}
                            onSelection={(selectedService) =>
                              handleServiceSelection(selectedService, index)
                            }
                            placeholder={
                              "Search for a service by name or service code"
                            }
                            activeServices={true}
                          />
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    <input
                      type="number"
                      value={service.quantity}
                      onChange={(e) =>
                        handleChargeChange(index, "quantity", e.target.value)
                      }
                      className="w-full border rounded-md"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={service.notes}
                      onChange={(e) =>
                        handleChargeChange(index, "notes", e.target.value)
                      }
                      className="w-full border rounded-md"
                    />
                  </td>
                  <td className="text-right">
                    <input
                      type="number"
                      value={service.amount}
                      onChange={(e) =>
                        handleChargeChange(
                          index,
                          "amount",
                          parseFloat(e.target.value) || 0
                        )
                      }
                      className="w-full border rounded-md text-right"
                    />
                  </td>

                  <td className="text-right">
                    <button
                      onClick={() => removeCharge(index)}
                      className="text-red-600">
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            onClick={addCharge}
            className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded">
            Add Charge
          </button>
        </div>

        {/* Notes Section */}
        <div className="py-6">
          <textarea
            placeholder="Type any invoice related notes."
            rows={3}
            value={notes}
            onChange={handleNotesChange}
            className="w-full border rounded-md"
          />
        </div>
        {showSetCliniciansModal && (
          <SetCliniciansModal
            message="Client has no supervising clinician assigned. Please assign one before creating an invoice."
            onSubmit={() => setShowSetCliniciansModal(false)}
            onClose={() => {
              dispatch(fetchAppointmentById(appointment._id));
              setShowSetCliniciansModal(false);
            }}
            client={selectedClient}
          />
        )}

        {/* QuickAddClientModal */}
        {isQuickAddClientModalOpen && (
          <QuickAddClientModal
            addParent={true}
            onClose={() => setIsQuickAddClientModalOpen(false)}
          />
        )}
      </div>
    );
  }
);

export default CreateInvoice;
