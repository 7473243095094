import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  setUnauthenticatedIntakeForm,
  setUnauthenticatedError,
} from "../slices/unauthenticatedIntakeSlice";
import { generateConfig } from "../../utils/api";

// Fetch intake form by ID for unauthenticated users
export const fetchUnauthenticatedIntakeFormById = createAsyncThunk(
  "unauthenticatedIntake/fetchIntakeFormById",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const { baseURL, config } = generateConfig(); // No token required
      const response = await axios.get(
        `${baseURL}/api/public/intake/${id}`,
        config
      );
      dispatch(setUnauthenticatedIntakeForm(response.data));
      return response.data;
    } catch (error) {
      console.error("Error fetching unauthenticated intake form:", error);
      dispatch(setUnauthenticatedError(error.message));
      return rejectWithValue(error.message);
    }
  }
);

// Update intake form for unauthenticated users
export const updateUnauthenticatedIntakeForm = createAsyncThunk(
  "unauthenticatedIntake/updateIntakeForm",
  async ({ id, updatedData }, { dispatch, rejectWithValue }) => {
    try {
      const { baseURL, config } = generateConfig(); // No token required
      const response = await axios.patch(
        `${baseURL}/api/public/intake/${id}`,
        updatedData,
        config
      );
      dispatch(setUnauthenticatedIntakeForm(response.data));
      return response.data;
    } catch (error) {
      console.error("Error updating unauthenticated intake form:", error);
      dispatch(setUnauthenticatedError(error.message));
      return rejectWithValue(error.message);
    }
  }
);
