import CheckboxGroup from "../../applicationUi/components/intake/CheckboxGroup";
import TextAreaField from "../../applicationUi/components/intake/TextAreaField";
import InputField from "../../applicationUi/components/intake/InputField";
import RadioGroup from "../../applicationUi/components/intake/RadioGroup";
const ConsentAgreementsSection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold mb-4">J - Consent and Confidentiality</h2>

    <p className="mb-4">
      <strong>
        CONSENT FOR SEPARATED PARENTS OR GUARDIANS (if applicable)
      </strong>
    </p>

    <p className="mb-4">
      HearSay understands that there is a wide range of decision-making
      arrangements for children, and we will respect that. Where there is shared
      parenting time between parents or guardians, HearSay considers it
      important to support the parenting relationship with the child and each
      parent. HearSay needs to understand if decision making for your child is
      through a shared decision-making process (also called custody) to both
      parents, or if decision-making ability has been given to a single parent
      before service can proceed.
    </p>

    <p className="mb-4">
      Even when decision-making is given to a sole parent, a written agreement
      may state that the other parent still needs to be involved in service
      and/or informed of the child’s progress in service.
    </p>

    <p className="mb-4">
      Some families have no written agreement, while others have a written
      agreement that has been created with a lawyer, a mediator, or through a
      legal court process. These agreements often help us answer the question
      regarding who can give consent for your child to receive services.
    </p>

    <p className="font-semibold mb-4">
      <strong>Consent for Service - What this Means for You:</strong>
    </p>

    <ol className="list-decimal pl-6">
      <li className="mb-4">
        We have No agreement for the decision making of our child(ren).
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;Both parents are required to sign this letter
          before we provide service for your child.
        </p>
      </li>
      <li className="mb-4">
        We have a Shared Decision-Making Agreement through a Joint Custody
        Agreement, a Separation Agreement, or an Interim agreement from a family
        court.
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;Both parents are required to sign this letter
          before we provide service for your child.
        </p>
      </li>
      <li className="mb-4">
        I have Sole Decision-Making Ability through a Court Order or a
        Separation Agreement that allows me to provide consent for my child(ren)
        to receive services.
        <p className="my-4">
          Please confirm that you can provide consent for your child(ren) to
          receive services by signing below. Please note, even when
          decision-making is given to a single parent, the written agreement may
          still state that the other parent needs to:
        </p>
        <ul className="list-disc pl-6">
          <li className="mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;<strong>Give Permission:</strong> The other
            parent's permission may still be required specifically for your
            child to receive services.
          </li>
          <li className="mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;<strong>Be Involved:</strong> Your agreement
            may still require/encourage the involvement of the other parent in
            service with your child.
          </li>
          <li className="mb-4">
            &nbsp;&nbsp;&nbsp;&nbsp;<strong>Be Informed:</strong> The other
            parent may not need to consent or be involved, but you may still be
            required to inform them of the outcomes of service.
          </li>
        </ul>
      </li>
      <li className="mb-4">
        <strong>
          I have sole decision-making ability AND I choose to involve the other
          parent/guardian.
        </strong>
      </li>
    </ol>

    <p className="font-semibold mb-4">
      If applicable, please provide the names and signatures below.
    </p>

    {/* Parent/Guardian #1 */}
    <TextAreaField
      label="Parent/Guardian #1 - Print Name and Signature"
      name="consentAgreements.parent1Signature"
      value={formData.consentAgreements.parent1Signature}
      onChange={handleChange}
      placeholder="Print your Name, Signature, Date"
    />

    <div className="grid grid-cols-2 gap-4 w-full">
      <InputField
        label="Parent/Guardian #1 - Email"
        name="consentAgreements.parent1Email"
        value={formData.consentAgreements.parent1Email}
        onChange={handleChange}
      />
      <InputField
        label="Parent/Guardian #1 - Phone Number"
        name="consentAgreements.parent1Phone"
        value={formData.consentAgreements.parent1Phone}
        onChange={handleChange}
      />
    </div>

    {/* Parent/Guardian #2 */}
    <TextAreaField
      label="Parent/Guardian #2 - Print Name and Signature"
      name="consentAgreements.parent2Signature"
      value={formData.consentAgreements.parent2Signature}
      onChange={handleChange}
      placeholder="Print your Name, Signature, Date"
    />

    <div className="grid grid-cols-2 gap-4 w-full">
      <InputField
        label="Parent/Guardian #2 - Email"
        name="consentAgreements.parent2Email"
        value={formData.consentAgreements.parent2Email}
        onChange={handleChange}
      />
      <InputField
        label="Parent/Guardian #2 - Phone Number"
        name="consentAgreements.parent2Phone"
        value={formData.consentAgreements.parent2Phone}
        onChange={handleChange}
      />
    </div>
  </section>
);

export default ConsentAgreementsSection;
