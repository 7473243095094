import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUnauthenticatedIntakeFormById,
  updateUnauthenticatedIntakeForm,
} from "../thunks/unauthenticatedIntakeThunk";

const initialState = {
  intakeForm: null,
  error: null,
};

const unauthenticatedIntakeSlice = createSlice({
  name: "unauthenticatedIntake",
  initialState,
  reducers: {
    setUnauthenticatedIntakeForm: (state, action) => {
      state.intakeForm = action.payload;
    },
    setUnauthenticatedError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Intake Form by ID
      .addCase(
        fetchUnauthenticatedIntakeFormById.fulfilled,
        (state, action) => {
          state.intakeForm = action.payload;
          state.error = null;
        }
      )
      .addCase(fetchUnauthenticatedIntakeFormById.rejected, (state, action) => {
        state.error = action.payload;
      })

      // Update Intake Form
      .addCase(updateUnauthenticatedIntakeForm.fulfilled, (state, action) => {
        state.intakeForm = action.payload;
        state.error = null;
      })
      .addCase(updateUnauthenticatedIntakeForm.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { setUnauthenticatedIntakeForm, setUnauthenticatedError } =
  unauthenticatedIntakeSlice.actions;

export default unauthenticatedIntakeSlice.reducer;
