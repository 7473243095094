import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { startQboOAuth, disconnectQbo } from "../../store/thunks/qboThunk";

function QuickBooksConnectButton() {
  const dispatch = useDispatch();
  const isConnected = useSelector((state) => state.qbo.isConnected); // Fetch connection status from Redux

  const handleConnect = () => {
    dispatch(startQboOAuth()); // Dispatch the thunk to start OAuth
  };

  const handleDisconnect = () => {
    dispatch(disconnectQbo()); // Dispatch the thunk to disconnect QuickBooks
  };

  return (
    <div className="flex flex-col items-center space-y-4">
      {isConnected === null ? (
        <p className="text-gray-500">Checking connection status...</p>
      ) : isConnected ? (
        <>
          <p className="text-green-500 font-semibold">
            Connected to QuickBooks
          </p>
          <button
            onClick={handleDisconnect}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Disconnect from QuickBooks
          </button>
        </>
      ) : (
        <>
          <p className="text-red-500 font-semibold">
            Not connected to QuickBooks
          </p>
          <button
            onClick={handleConnect}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Connect to QuickBooks
          </button>
        </>
      )}
    </div>
  );
}

export default QuickBooksConnectButton;
