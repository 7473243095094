// Define roles as constants
export const OWNER = 'owner';
export const ADMIN = 'admin';
export const CLINICIAN = 'clinician';
export const BOOKKEEPER = 'bookkeeper';
export const OFFICE_MANAGER = 'officeManager';
export const HR = 'hr';

// Create an array of all roles
export const ALL_ROLES = [OWNER, ADMIN, CLINICIAN, BOOKKEEPER, OFFICE_MANAGER, HR];

// Create an object mapping role names to their values
export const ROLES = {
  OWNER,
  ADMIN,
  CLINICIAN,
  BOOKKEEPER,
  OFFICE_MANAGER,
  HR
};

// Function to check if a given role is valid
export const isValidRole = (role) => ALL_ROLES.includes(role);

// Function to get role display name
export const getRoleDisplayName = (role) => {
  switch (role) {
    case OWNER:
      return 'Owner';
    case ADMIN:
      return 'Administrator';
    case CLINICIAN:
      return 'Clinician';
    case BOOKKEEPER:
      return 'Bookkeeper';
    case OFFICE_MANAGER:
      return 'Office Manager';
    case HR:
      return 'Human Resources';
    default:
      return 'Unknown Role';
  }
};
