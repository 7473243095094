import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAppointmentStatus } from "../../store/thunks/appointmentsThunk";
import { updatePayroll } from "../../store/thunks/payrollThunk";
import PayrollTable from "../../applicationUi/components/PayrollTable";
import CompletedPayrollTable from "../../applicationUi/components/CompletedPayrollTable";
import PayrollScheduleTable from "./PayrollSchedule";
import PayrollSlideOver from "./PayrollSlideOver";
import payrollDates from "./payrollDates";
import PayrollStats from "./PayrollStats";
import CustomPayrollModal from "./CustomPayrollModal";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";

export default function HRIndividualPayrollPage({
  clinician,
  selectedPeriod,
  isOpen,
  onClose,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  // Use the new data structure
  const allAppointments = useSelector(
    (state) => state.appointments.allPayrollAppointments || {}
  );
  const allPayrolls = useSelector(
    (state) => state.payrolls.allCliniciansPayrolls || []
  );

  // Filter appointments and payrolls based on the clinician prop
  const clinicianAppointments =
    allAppointments[clinician._id]?.appointments || [];

  const clinicianPayrolls = allPayrolls.filter(
    (payroll) => payroll.clinicianId._id === clinician._id
  );

  console.log("clinicianappts", clinicianAppointments);
  console.log("clinicianpayrolls", clinicianPayrolls);

  const [isPayrollSlideOver, setIsPayrollSlideOver] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showPayrollSchedule, setShowPayrollSchedule] = useState(false);
  const [soapNotesComplete, setSoapNotesComplete] = useState(false);
  const [invoicePaid, setInvoicePaid] = useState(false);
  const [eligibleForPayroll, setEligibleForPayroll] = useState(false);
  const [pendingAppointments, setPendingAppointments] = useState([]);
  const [completedAppointments, setCompletedAppointments] = useState([]);
  const [carryoverAppointments, setCarryoverAppointments] = useState([]);
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const fetchData = async () => {
    if (selectedPeriod && user) {
      const [start, end] = selectedPeriod.includeDates
        .split("-")
        .map((date) => new Date(`${date.trim()}`));

      setStartDate(start);
      setEndDate(end);

      const currentDate = new Date();
      const submissionDate = new Date(`${selectedPeriod.submissionDate}`);

      try {
        const appointmentsResult = clinicianAppointments;
        const payrollsResult = clinicianPayrolls;

        const carryoverResult =
          currentDate > submissionDate ? appointmentsResult : [];
        const pendingResult =
          currentDate <= submissionDate ? appointmentsResult : [];

        setCompletedAppointments(payrollsResult);
        setCarryoverAppointments(carryoverResult);
        setPendingAppointments(pendingResult);

        if (selectedAppointment) {
          const updatedAppointment = appointmentsResult.find(
            (appointment) => appointment._id === selectedAppointment._id
          );
          setSelectedAppointment(updatedAppointment);
        }
      } catch (error) {
        console.error("Fetch data error:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedPeriod, clinician._id]);

  const normalizeAppointmentData = (data) => {
    let appointment = data.appointmentId ? data.appointmentId : data;
    let invoice = data.invoiceId ? data.invoiceId : data.invoice;
    let payrollId = data._id;
    return { appointment, invoice, payrollId };
  };

  const handleAppointmentClick = (data) => {
    const { appointment, invoice, payrollId } = normalizeAppointmentData(data);

    console.log("appointment", appointment);
    console.log("invoice", invoice);
    console.log("payrollid", payrollId);

    if (appointment) {
      setSelectedAppointment({ appointment, invoice, payrollId });
      setIsPayrollSlideOver(true);
    } else {
      console.error("Invalid appointment object:", appointment);
    }
  };

  const handleCloseScheduleModal = () => {
    setShowPayrollSchedule(false);
  };

  const filterAppointments = () => {
    return pendingAppointments.filter((appointment) => {
      const hasSoapNotesComplete = appointment.history.some(
        (historyItem) => historyItem.status === "Soap Notes Complete"
      );
      const hasInvoicePaid =
        appointment.invoice && appointment.invoice.status === "paid";

      if (eligibleForPayroll) {
        return hasSoapNotesComplete && hasInvoicePaid;
      }
      if (soapNotesComplete && !hasSoapNotesComplete) {
        return false;
      }
      if (invoicePaid && !hasInvoicePaid) {
        return false;
      }
      return true;
    });
  };

  const getSubmissionDate = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const submissionDate = new Date(currentPeriod.submissionDate);
      return submissionDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  const getScheduledPayDay = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const paymentDate = new Date(currentPeriod.paymentDate);
      return paymentDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  const calculateIncompleteAppointments = () => {
    return pendingAppointments.filter((appointment) => {
      const hasSoapNotesComplete = appointment.history.some(
        (historyItem) => historyItem.status === "Soap Notes Complete"
      );
      const hasInvoicePaid =
        appointment.invoice && appointment.invoice.status === "paid";

      return !(hasSoapNotesComplete && hasInvoicePaid);
    }).length;
  };

  const calculatePendingAppointments = () => {
    return pendingAppointments.filter((appointment) => {
      const hasSoapNotesComplete = appointment.history.some(
        (historyItem) => historyItem.status === "Soap Notes Complete"
      );
      const hasInvoicePaid =
        appointment.invoice && appointment.invoice.status === "paid";

      return !(hasSoapNotesComplete && hasInvoicePaid);
    }).length;
  };

  const calculateCompletedAppointments = () => {
    return completedAppointments.length;
  };

  const calculateCarryoverAppointments = () => {
    return carryoverAppointments.length;
  };

  const calculatePendingPay = () => {
    return pendingAppointments
      .reduce((sum, appointment) => {
        const servicePrice = appointment.service.price || 0;
        const pendingPay = servicePrice * 0.45;
        return sum + pendingPay;
      }, 0)
      .toFixed(2);
  };

  const calculateCompletedPay = () => {
    return completedAppointments
      .reduce((sum, payroll) => sum + (payroll.payrollAmount || 0), 0)
      .toFixed(2);
  };

  const calculateCarryoverPay = () => {
    return carryoverAppointments
      .reduce((sum, appointment) => {
        const servicePrice = appointment.service.price || 0;
        const pendingPay = servicePrice * 0.45;
        return sum + pendingPay;
      }, 0)
      .toFixed(2);
  };

  const toggleCustomPayrollModal = () => {
    setIsCustomModalOpen((prev) => !prev); // Toggles between true and false
  };

  const markCompletedAsReady = async () => {
    try {
      const updatePromises = completedAppointments.map((payroll) =>
        dispatch(
          updatePayroll({
            id: payroll._id,
            updateData: { status: "Ready" },
          })
        ).unwrap()
      );

      await Promise.all(updatePromises);

      fetchData();
    } catch (error) {
      console.error("Error updating payrolls:", error);
    }
  };

  const markReadyAsPaid = async () => {
    try {
      // Extract appointmentId and create update promises
      const updatePromises = completedAppointments.map(async (payroll) => {
        const appointmentId = payroll.appointmentId
          ? payroll.appointmentId._id
          : null;
        const userId = user._id; // Extract userId from the store

        // Dispatch updatePayroll thunk to update payroll status
        const payrollUpdatePromise = dispatch(
          updatePayroll({
            id: payroll._id,
            updateData: { status: "Paid to Clinician" },
          })
        ).unwrap();

        // Dispatch updateAppointmentStatus thunk to update appointment status if appointmentId is not null
        const appointmentUpdatePromise = appointmentId
          ? dispatch(
              updateAppointmentStatus({
                appointmentId,
                status: "Paid to Clinician",
                comments: "Status changed to Paid to Clinician",
                userId,
              })
            ).unwrap()
          : Promise.resolve();

        // Wait for both updates to complete
        await Promise.all([payrollUpdatePromise, appointmentUpdatePromise]);
      });

      // Wait for all updates to complete
      await Promise.all(updatePromises);

      // Fetch updated data after all updates
      fetchData();
    } catch (error) {
      console.error("Error updating statuses:", error);
    }
  };

  const stats = [
    {
      name: `Pay Period ${selectedPeriod.payPeriodNumber}`,
      value: `${selectedPeriod.includeDates}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Submit Appointments By",
      value: `${getSubmissionDate()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Pay Day",
      value: `${getScheduledPayDay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Pending Appointments",
      value: `${calculatePendingAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Completed Appointments",
      value: `${calculateCompletedAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Carryover Appointments",
      value: `${calculateCarryoverAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Pending Pay",
      value: `$${calculatePendingPay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Completed Pay",
      value: `$${calculateCompletedPay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Carryover Pay",
      value: `$${calculateCarryoverPay()}`,
      change: "",
      changeType: "neutral",
    },
  ];

  const getStatus = (item) => {
    const hasSoapNotesComplete = item.history.some(
      (historyItem) => historyItem.status === "Soap Notes Complete"
    );
    const hasInvoicePaid = item.invoice && item.invoice.status === "paid";

    let statuses = [];
    if (!hasSoapNotesComplete) {
      statuses.push("SOAP Notes");
    }
    if (!item.invoice) {
      statuses.push("Invoice: No Invoice");
    } else if (!hasInvoicePaid) {
      statuses.push(`Invoice: ${item.invoice.status}`);
    }
    if (hasSoapNotesComplete && hasInvoicePaid) {
      statuses.push("Ready");
      // Automatically save to payroll if not already saved
      // if (item.invoice && !item.invoice.isPayrollSaved) {
      //   handleSaveToPayroll(item);
      // }
    }

    const statusClasses = {
      "SOAP Notes": "text-orange-700 bg-orange-50 ring-orange-600/20",
      "Invoice: No Invoice": "text-orange-700 bg-orange-50 ring-orange-600/20",
      "Invoice: created": "text-orange-700 bg-orange-50 ring-orange-600/20",
      "Invoice: sent": "text-orange-700 bg-orange-50 ring-orange-600/20",
      "Invoice: paid": "text-orange-700 bg-orange-50 ring-orange-600/20",
      "Invoice: void": "text-orange-700 bg-orange-50 ring-orange-600/20",
      Ready: "text-green-700 bg-green-50 ring-green-600/20",
    };

    return (
      <div className="flex flex-col items-center space-y-1">
        {statuses.map((status, index) => (
          <div
            key={index}
            className={classNames(
              statusClasses[status],
              "rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
            )}>
            {status}
          </div>
        ))}
      </div>
    );
  };

  const getTimeUntilPayday = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const paymentDate = new Date(currentPeriod.paymentDate);
      const now = new Date();
      const timeDifference = paymentDate - now;

      if (timeDifference > 0) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
        return { days, hours, minutes, isPaydayReached: false };
      } else {
        return { days: 0, hours: 0, minutes: 0, isPaydayReached: true };
      }
    } else {
      return { days: 0, hours: 0, minutes: 0, isPaydayReached: false };
    }
  };

  const { days, hours, minutes, isPaydayReached } = getTimeUntilPayday();

  const customPayrolls = completedAppointments.filter((item) => item.isCustom);
  const regularPayrolls = completedAppointments.filter(
    (item) => !item.isCustom
  );

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 md:pl-30">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel className="pointer-events-auto w-screen max-w-4xl">
                  {/* <div className="py-4"> */}
                  <div className="px-4 flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="text-sm font-medium leading-6 text-gray-900">
                      {clinician.firstName} {clinician.lastName}
                    </div>
                    <PayrollStats stats={stats} />
                    {/* Pay period selector and toggles */}
                    <div className="flex space-x-4 mb-4 justify-around items-center">
                      <div className="flex flex-col items-center space-y-2">
                        <label className="block text-sm font-medium leading-6 text-gray-900 text-center">
                          Soap Notes Complete
                        </label>
                        <Switch
                          checked={soapNotesComplete}
                          onChange={(value) => {
                            setSoapNotesComplete(value);
                            if (!value) setEligibleForPayroll(false);
                          }}
                          className={`relative inline-flex items-center h-6 rounded-full w-11 ${
                            soapNotesComplete ? "bg-indigo-600" : "bg-gray-200"
                          }`}>
                          <span className="sr-only">Soap Notes Complete</span>
                          <span
                            className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${
                              soapNotesComplete
                                ? "translate-x-6"
                                : "translate-x-1"
                            }`}
                          />
                        </Switch>
                      </div>
                      <div className="flex flex-col items-center space-y-2">
                        <label className="block text-sm font-medium leading-6 text-gray-900 text-center">
                          Invoice Paid
                        </label>
                        <Switch
                          checked={invoicePaid}
                          onChange={(value) => {
                            setInvoicePaid(value);
                            if (!value) setEligibleForPayroll(false);
                          }}
                          className={`relative inline-flex items-center h-6 rounded-full w-11 ${
                            invoicePaid ? "bg-indigo-600" : "bg-gray-200"
                          }`}>
                          <span className="sr-only">Invoice Paid</span>
                          <span
                            className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${
                              invoicePaid ? "translate-x-6" : "translate-x-1"
                            }`}
                          />
                        </Switch>
                      </div>
                      <div className="flex flex-col items-center space-y-2">
                        <label className="block text-sm font-medium leading-6 text-gray-900 text-center">
                          Eligible for payroll
                        </label>
                        <Switch
                          checked={eligibleForPayroll}
                          onChange={(value) => {
                            setEligibleForPayroll(value);
                            setSoapNotesComplete(value);
                            setInvoicePaid(value);
                          }}
                          className={`relative inline-flex items-center h-6 rounded-full w-11 ${
                            eligibleForPayroll ? "bg-indigo-600" : "bg-gray-200"
                          }`}>
                          <span className="sr-only">Eligible for Payroll</span>
                          <span
                            className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${
                              eligibleForPayroll
                                ? "translate-x-6"
                                : "translate-x-1"
                            }`}
                          />
                        </Switch>
                      </div>
                      <div>
                        <button
                          type="button"
                          onClick={() =>
                            setShowPayrollSchedule(!showPayrollSchedule)
                          }
                          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                          View Payroll Schedule
                        </button>
                      </div>
                    </div>

                    <div>
                      {/* TABLE FOR COMPLETED PAYROLL */}
                      <div className="border border-gray-300 rounded-lg">
                        <div>
                          {/* Table for Regular Payrolls */}
                          <div className="border border-gray-300 rounded-lg mb-6">
                            <CompletedPayrollTable
                              columns={[
                                {
                                  header: "Payroll Status",
                                  accessor: (item) => `${item.status}`,
                                },
                                {
                                  header: "Client",
                                  accessor: (item) =>
                                    item.appointmentId &&
                                    item.appointmentId.client
                                      ? `${item.appointmentId.client.firstName} ${item.appointmentId.client.lastName}`
                                      : "N/A",
                                },
                                {
                                  header: "Service",
                                  accessor: (item) =>
                                    item.appointmentId &&
                                    item.appointmentId.title
                                      ? item.appointmentId.title
                                      : "N/A",
                                },
                                {
                                  header: "Service Date",
                                  accessor: (item) => {
                                    if (
                                      item.appointmentId &&
                                      item.appointmentId.start
                                    ) {
                                      const date = new Date(
                                        item.appointmentId.start
                                      );
                                      const optionsDate = {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                      };
                                      const optionsTime = {
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: true,
                                      };
                                      return `${date.toLocaleDateString(
                                        "en-US",
                                        optionsDate
                                      )}, ${date.toLocaleTimeString(
                                        "en-US",
                                        optionsTime
                                      )}`;
                                    } else {
                                      return "N/A";
                                    }
                                  },
                                },
                                {
                                  header: "Billed Amount",
                                  accessor: (item) =>
                                    item.originalAmount !== undefined
                                      ? `$${item.originalAmount.toFixed(2)}`
                                      : "N/A",
                                },
                                {
                                  header: "% Received",
                                  accessor: (item) =>
                                    item.percentReceived !== undefined
                                      ? `${item.percentReceived}%`
                                      : "N/A",
                                },
                                {
                                  header: "Payroll Amount",
                                  accessor: (item) =>
                                    item.payrollAmount !== undefined
                                      ? `$${item.payrollAmount.toFixed(2)}`
                                      : "N/A",
                                },
                              ]}
                              data={regularPayrolls}
                              onRowClick={(item) =>
                                handleAppointmentClick(item)
                              }
                              clinicianId={null}
                              title={`Completed Appointments [${regularPayrolls.length}]`}
                              description="List of regular completed appointments, per pay period."
                              actions={[
                                {
                                  label: "Add Custom Payroll Item",
                                  onClick: toggleCustomPayrollModal,
                                },
                                {
                                  label: "Mark Completed Appointments as Ready",
                                  onClick: markCompletedAsReady,
                                },
                                {
                                  label: "Mark Ready Appointments as Paid",
                                  onClick: isPaydayReached
                                    ? markReadyAsPaid
                                    : null,
                                  disabled: !isPaydayReached,
                                },
                              ]}
                              countdown={
                                !isPaydayReached &&
                                `Time until allowed: ${days}d ${hours}h ${minutes}m`
                              }
                            />
                          </div>

                          {/* Table for Custom Payrolls */}
                          <div className="border border-gray-300 rounded-lg">
                            <CompletedPayrollTable
                              columns={[
                                {
                                  header: "Payroll Status",
                                  accessor: (item) => `${item.status}`,
                                },
                                {
                                  header: "Description",
                                  accessor: (item) =>
                                    item.description ? item.description : "N/A",
                                },
                                {
                                  header: "Payroll Amount",
                                  accessor: (item) =>
                                    item.payrollAmount !== undefined
                                      ? `$${item.payrollAmount.toFixed(2)}`
                                      : "N/A",
                                },
                              ]}
                              data={customPayrolls}
                              onRowClick={(item) =>
                                handleAppointmentClick(item)
                              }
                              clinicianId={null}
                              title={`Custom Payrolls [${customPayrolls.length}]`}
                              description="List of custom payrolls, per pay period."
                            />
                          </div>
                        </div>
                      </div>

                      <div className="border border-gray-300 rounded-lg mt-6">
                        {/* TABLE FOR PENDING PAYROLL */}
                        {filterAppointments().length > 0 && (
                          <PayrollTable
                            columns={[
                              {
                                header: "Appointment Status",
                                accessor: (item) => `${item.status}`,
                              },
                              {
                                header: "Client",
                                accessor: (item) =>
                                  item.client
                                    ? `${item.client.firstName} ${item.client.lastName}`
                                    : "N/A",
                              },
                              {
                                header: "Service",
                                accessor: (item) =>
                                  item.service
                                    ? item.service.description
                                    : "N/A",
                              },
                              {
                                header: "Service Date",
                                accessor: (item) => {
                                  if (item.start) {
                                    const date = new Date(item.start);
                                    const optionsDate = {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    };
                                    const optionsTime = {
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: true,
                                    };
                                    return `${date.toLocaleDateString(
                                      "en-US",
                                      optionsDate
                                    )}, ${date.toLocaleTimeString(
                                      "en-US",
                                      optionsTime
                                    )}`;
                                  } else {
                                    return "N/A";
                                  }
                                },
                              },
                              {
                                header: "Invoice ID",
                                accessor: (item) =>
                                  item.invoice
                                    ? item.invoice.invoiceId
                                    : "No Invoice",
                              },
                              {
                                header: "Invoice Amount",
                                accessor: (item) =>
                                  item.invoice
                                    ? `$${item.invoice.amount.toFixed(2)}`
                                    : "No Invoice",
                              },
                              {
                                header: "Invoice Status",
                                accessor: (item) =>
                                  item.invoice
                                    ? item.invoice.status
                                    : "No Invoice",
                              },
                              {
                                header: "Invoice Created",
                                accessor: (item) =>
                                  item.invoice
                                    ? new Date(
                                        item.invoice.createdAt
                                      ).toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                      })
                                    : "No Invoice",
                              },
                            ]}
                            data={filterAppointments()}
                            onRowClick={handleAppointmentClick}
                            actions={[]}
                            title={`Pending Appointments [${calculateIncompleteAppointments()}]`}
                            description="Select a clinician to view a list of their incomplete appointments, per pay period."
                          />
                        )}

                        {/* TABLE FOR CARRYOVER PAYROLL */}
                        {carryoverAppointments.length > 0 && (
                          <PayrollTable
                            columns={[
                              {
                                header: "Status",
                                accessor: (item) => getStatus(item),
                              },
                              // {
                              //   header: "Appointment History",
                              //   accessor: (item) =>
                              //     item.history && item.history.length > 0
                              //       ? item.history
                              //           .map((entry) => entry.status)
                              //           .join(", ") // Joins all the statuses into a single string
                              //       : "No History",
                              // },
                              {
                                header: "Client",
                                accessor: (item) =>
                                  item.client
                                    ? `${item.client.firstName} ${item.client.lastName}`
                                    : "N/A",
                              },
                              {
                                header: "Service",
                                accessor: "service.description",
                              },
                              {
                                header: "Service Date",
                                accessor: (item) => {
                                  if (item.start) {
                                    const date = new Date(item.start);
                                    const optionsDate = {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    };
                                    const optionsTime = {
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: true,
                                    };
                                    return `${date.toLocaleDateString(
                                      "en-US",
                                      optionsDate
                                    )}, ${date.toLocaleTimeString(
                                      "en-US",
                                      optionsTime
                                    )}`;
                                  } else {
                                    return "N/A";
                                  }
                                },
                              },
                              {
                                header: "Invoice ID",
                                accessor: (item) =>
                                  item.invoice
                                    ? item.invoice.invoiceId
                                    : "No Invoice",
                              },
                              {
                                header: "Invoice Amount",
                                accessor: (item) =>
                                  item.invoice
                                    ? `$${item.invoice.amount.toFixed(2)}`
                                    : "No Invoice",
                              },
                              {
                                header: "Invoice Status",
                                accessor: (item) =>
                                  item.invoice
                                    ? item.invoice.status
                                    : "No Invoice",
                              },
                              {
                                header: "Invoice Created",
                                accessor: (item) =>
                                  item.invoice
                                    ? new Date(
                                        item.invoice.createdAt
                                      ).toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                      })
                                    : "No Invoice",
                              },
                            ]}
                            data={carryoverAppointments}
                            onRowClick={handleAppointmentClick}
                            actions={[]}
                            title={`Carryover Appointments [${calculateCarryoverAppointments()}]`}
                            description={`These are all your appointments that have not been completed.`}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Modal for custom payroll items */}
                  <CustomPayrollModal
                    clinician={clinician}
                    selectedPeriod={selectedPeriod}
                    isOpen={isCustomModalOpen}
                    onClose={toggleCustomPayrollModal}
                  />

                  {showPayrollSchedule && (
                    <div
                      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                      onClick={handleCloseScheduleModal}
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === "Escape") handleCloseScheduleModal();
                      }}>
                      <div
                        className="bg-white rounded-lg shadow-lg p-8 max-w-4xl w-full relative"
                        onClick={(e) => e.stopPropagation()}>
                        <button
                          onClick={handleCloseScheduleModal}
                          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
                          <XMarkIcon className="h-6 w-6" />
                        </button>
                        <PayrollScheduleTable />
                      </div>
                    </div>
                  )}
                  {isPayrollSlideOver && selectedAppointment && (
                    <PayrollSlideOver
                      isOpen={isPayrollSlideOver}
                      appointment={selectedAppointment}
                      onClose={() => setIsPayrollSlideOver(false)}
                      selectedPeriod={selectedPeriod.payPeriodNumber}
                      selectedPeriodDates={selectedPeriod.includeDates}
                      isHr={true}
                      onUpdateSuccess={fetchData}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
