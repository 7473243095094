// store/slices/clientNotesSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  createClientNote,
  fetchClientNote,
  updateClientNote,
  deleteClientNote,
} from "../thunks/clientNotesThunk";

const initialState = {
  note: null, // Holds the note object
  loading: false, // Loading state for async actions
  error: null, // Holds any errors that occur during async actions
};

const clientNotesSlice = createSlice({
  name: "clientNotes",
  initialState,
  reducers: {
    // Add any sync actions here if necessary
  },
  extraReducers: (builder) => {
    // Create Note
    builder.addCase(createClientNote.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createClientNote.fulfilled, (state, action) => {
      state.loading = false;
      state.note = action.payload; // Store the new note
      state.error = null;
    });
    builder.addCase(createClientNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Fetch Note
    builder.addCase(fetchClientNote.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchClientNote.fulfilled, (state, action) => {
      state.loading = false;
      state.note = action.payload; // Will be `null` if no note exists
      state.error = null;
    });
    builder.addCase(fetchClientNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Update Note
    builder.addCase(updateClientNote.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateClientNote.fulfilled, (state, action) => {
      state.loading = false;
      state.note = action.payload; // Update the note with the new content
      state.error = null;
    });
    builder.addCase(updateClientNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete Note
    builder.addCase(deleteClientNote.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteClientNote.fulfilled, (state) => {
      state.loading = false;
      state.note = null; // Clear the note since it's been deleted
      state.error = null;
    });
    builder.addCase(deleteClientNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default clientNotesSlice.reducer;
