import React, { forwardRef } from 'react';

const ContextMenu = forwardRef(({ x, y, options, onClose }, ref) => {
  return (
    <div
      ref={ref}
      style={{ position: 'fixed', top: y, left: x, zIndex: 9999 }}
      className="bg-white shadow-lg rounded-md overflow-hidden border border-black"
    >
      <ul className="py-2">
        {options.map((option, index) => (
          <li
            key={index}
            className="px-4 py-2 hover:bg-blue-50 cursor-pointer transition-colors duration-150 ease-in-out"
            onClick={(e) => {
              e.stopPropagation(); // Prevent click from bubbling up
              console.log("Context menu clicked", option);
              if (option.onClick) {
                option.onClick(); // Call the option's click handler
              }
              onClose(); // Close the context menu
            }}
          >
            <span className="text-gray-700 text-sm font-medium">{option.label}</span>
          </li>
        ))}
      </ul>
      <div className="border-t border-gray-200 px-4 py-2">
        <button
          className="w-full text-sm text-gray-600 hover:text-gray-800 focus:outline-none"
          onClick={onClose} // Close the menu on button click
        >
          Close
        </button>
      </div>
    </div>
  );
});

export default ContextMenu;
