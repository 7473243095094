import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import payrollDates from "./payrollDates";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import {
  fetchHourlyPayroll,
  updateHourlyPayroll,
  submitHourlyPayroll,
} from "../../store/thunks/hourlyPayrollThunk";

export default function HourlyPayrollPage() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [currentDate] = useState(new Date());
  const [selectedPeriod, setSelectedPeriod] = useState(findCurrentPayPeriod());
  const [dailyHours, setDailyHours] = useState({});
  const [timeRemaining, setTimeRemaining] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      updateTimeRemaining();
    }, 1000);
    return () => clearInterval(interval);
  }, [selectedPeriod]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await dispatch(
        fetchHourlyPayroll({
          userId: user._id,
          payPeriod: selectedPeriod.payPeriodNumber,
        })
      );
      if (fetchHourlyPayroll.fulfilled.match(result)) {
        setDailyHours(result.payload.hours || {});
      }
    };
    fetchData();
  }, [dispatch, user._id, selectedPeriod]);

  function findCurrentPayPeriod() {
    let closestPeriod = payrollDates[0];
    payrollDates.forEach((period) => {
      const [startDateString, endDateString] = period.includeDates.split("-");
      const startDate = new Date(Date.parse(`${startDateString.trim()}`));
      const endDate = new Date(Date.parse(`${endDateString.trim()}`));
      endDate.setHours(23, 59, 59, 999);

      if (currentDate >= startDate && currentDate <= endDate) {
        closestPeriod = period;
      }
    });
    return closestPeriod;
  }

  function updateTimeRemaining() {
    const [_, endDateString] = selectedPeriod.includeDates.split("-");
    const submissionDate = new Date(Date.parse(selectedPeriod.submissionDate));
    const now = new Date();

    let targetDate = null;
    if (now <= new Date(Date.parse(`${endDateString.trim()}`))) {
      targetDate = new Date(Date.parse(`${endDateString.trim()}`));
      targetDate.setHours(23, 59, 59, 999);
    } else if (now <= submissionDate) {
      targetDate = submissionDate;
    }

    if (targetDate) {
      const timeDiff = targetDate - now;
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
      setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
    } else {
      setTimeRemaining("No actions available.");
    }
  }

  const handleHoursChange = (date, type, value) => {
    const formattedDate = new Date(date).toISOString().split("T")[0];

    setDailyHours((prev) => ({
      ...prev,
      [formattedDate]: {
        ...prev[formattedDate],
        [type]:
          type === "regular" ? (value === "" ? "" : parseFloat(value)) : value, // Parse only numbers for 'regular'
      },
    }));
  };

  const calculateTotalHours = () => {
    return Object.values(dailyHours).reduce((total, day) => {
      const regular = parseFloat(day.regular) || 0; // Ensure numbers
      return total + regular;
    }, 0);
  };

  const transformKeysToDateFormat = (hours) => {
    const transformedHours = {};
    Object.keys(hours).forEach((key) => {
      const date = new Date(key).toISOString().split("T")[0];
      transformedHours[date] = hours[key];
    });
    return transformedHours;
  };

  const handleUpdateHours = async () => {
    const simplifiedDailyHours = transformKeysToDateFormat(dailyHours);
    const result = await dispatch(
      updateHourlyPayroll({
        userId: user._id,
        payPeriod: selectedPeriod.payPeriodNumber,
        hours: simplifiedDailyHours,
      })
    );
    if (updateHourlyPayroll.fulfilled.match(result)) {
      setSuccessMessage("Hours updated successfully!");
      setTimeout(() => setSuccessMessage(""), 3000);
    } else {
      console.error("Failed to update hours");
    }
  };

  const handleSubmitHours = async () => {
    const result = await dispatch(
      submitHourlyPayroll({
        userId: user._id,
        payPeriod: selectedPeriod.payPeriodNumber,
      })
    );
    if (submitHourlyPayroll.fulfilled.match(result)) {
      setSuccessMessage("Hours submitted successfully!");
      setTimeout(() => setSuccessMessage(""), 3000);
      console.log("Hours submitted successfully");
    } else {
      console.error("Failed to submit hours");
    }
  };

  const isUpdateHoursEnabled = () => {
    const [startDateString, endDateString] =
      selectedPeriod.includeDates.split("-");
    const startDate = new Date(Date.parse(startDateString.trim()));
    const endDate = new Date(Date.parse(endDateString.trim()));
    endDate.setHours(23, 59, 59, 999);

    return currentDate >= startDate && currentDate <= endDate;
  };

  const isSubmitHoursEnabled = () => {
    const [_, endDateString] = selectedPeriod.includeDates.split("-");
    const endDate = new Date(Date.parse(endDateString.trim()));
    const submissionDate = new Date(Date.parse(selectedPeriod.submissionDate));
    endDate.setHours(23, 59, 59, 999);

    return currentDate > endDate && currentDate <= submissionDate;
  };

  const renderMiniCalendar = () => {
    const [startDateString, endDateString] =
      selectedPeriod.includeDates.split("-");
    const startDate = new Date(Date.parse(startDateString.trim()));
    const endDate = new Date(Date.parse(endDateString.trim()));

    const days = [];
    for (
      let date = new Date(startDate);
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      days.push(new Date(date));
    }

    const startDayOfWeek = startDate.getDay();
    const weeks = [];
    let currentWeek = new Array(startDayOfWeek).fill(null);

    days.forEach((date) => {
      currentWeek.push(date);
      if (currentWeek.length === 7) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
    });

    if (currentWeek.length > 0) {
      while (currentWeek.length < 7) {
        currentWeek.push(null);
      }
      weeks.push(currentWeek);
    }

    const weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    return (
      <div className="border rounded-md shadow-lg overflow-hidden">
        <table className="min-w-full table-fixed border-collapse">
          <thead className="bg-gray-100">
            <tr>
              {weekDays.map((day) => (
                <th
                  key={day}
                  className="py-2 px-4 text-center text-sm font-medium text-gray-700">
                  {day}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {weeks.map((week, weekIndex) => (
              <tr key={weekIndex}>
                {week.map((date, dayIndex) => (
                  <td
                    key={dayIndex}
                    className="border p-2 text-center align-top">
                    {date ? (
                      <>
                        <div className="font-semibold">{date.getDate()}</div>
                        {/* Regular Hours Input */}
                        <input
                          type="number"
                          placeholder="Hours"
                          className="mt-2 w-full p-1 border rounded text-sm"
                          value={
                            dailyHours[
                              new Date(date).toISOString().split("T")[0]
                            ]?.regular || ""
                          }
                          onChange={(e) =>
                            handleHoursChange(date, "regular", e.target.value)
                          }
                        />
                        {/* Notes Input */}
                        <input
                          type="text"
                          placeholder="Notes"
                          className="mt-2 w-full p-1 border rounded text-sm"
                          value={
                            dailyHours[
                              new Date(date).toISOString().split("T")[0]
                            ]?.notes || ""
                          }
                          onChange={(e) =>
                            handleHoursChange(date, "notes", e.target.value)
                          }
                        />
                      </>
                    ) : (
                      <div className="h-16"></div>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="p-4 text-right">
          {/* <strong>Regular Hours: {calculateTotalRegularHours()}</strong> |{" "} */}
          {/* <strong>OT Hours: {calculateTotalOTHours()}</strong> |{" "} */}
          <strong>Total Hours: {calculateTotalHours()}</strong>
        </div>
      </div>
    );
  };

  return (
    <div className="p-6 max-w-5xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Hourly Payroll Entry</h1>
      <p className="mb-2 text-lg">
        Currently viewing{" "}
        <strong>
          Pay Period {selectedPeriod.payPeriodNumber.split(".")[1]} of{" "}
          {selectedPeriod.payPeriodNumber.split(".")[0]}
        </strong>
      </p>
      <div className="mb-6">
        <Listbox value={selectedPeriod} onChange={setSelectedPeriod}>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              <span className="block truncate">
                {selectedPeriod.includeDates}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {payrollDates.map((period) => (
                <Listbox.Option
                  key={period.payPeriodNumber}
                  value={period}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }>
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}>
                        {period.includeDates}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
      </div>
      {renderMiniCalendar()}
      <div className="flex items-top justify-end mt-4 text-right">
        <div className="flex flex-col items-center">
          {successMessage ? (
            <p className="px-4 py-2 rounded shadow bg-green-100 text-green-700">
              {successMessage}
            </p>
          ) : (
            <button
              className={`px-4 py-2 rounded shadow ${
                isUpdateHoursEnabled()
                  ? "bg-indigo-600 text-white hover:bg-indigo-800"
                  : "bg-gray-400 text-white cursor-not-allowed"
              }`}
              onClick={handleUpdateHours}
              disabled={!isUpdateHoursEnabled()}>
              Update Hours
            </button>
          )}
          <p className="text-sm mt-2">
            {isUpdateHoursEnabled() && `Time left to update: ${timeRemaining}`}
          </p>
        </div>

        <div className="flex flex-col items-center ml-4">
          <button
            className={`px-4 py-2 rounded shadow ${
              isSubmitHoursEnabled()
                ? "bg-green-600 text-white hover:bg-green-700"
                : "bg-gray-400 text-white cursor-not-allowed"
            }`}
            onClick={handleSubmitHours}
            disabled={!isSubmitHoursEnabled()}>
            Submit Hours
          </button>
          <p className="text-sm mt-2">
            {isSubmitHoursEnabled() && `Time left to submit: ${timeRemaining}`}
          </p>
        </div>
      </div>
    </div>
  );
}
