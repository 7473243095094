import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchClinicById, fetchClinics } from "../../store/thunks/clinicThunk";
import Table from "../../applicationUi/components/Table";
import { logError, logInfo } from "../../utils/logger";
import ClinicDetails from "./ClinicDetails";
import ClinicOverview from "./ClinicOverview";

export default function ClinicPage({ clickedItem }) {
  const dispatch = useDispatch();
  const {
    clinics = [],
    pagination = { currentPage: 1, pageSize: 10 },
    status: clinicStatus,
    error: clinicError,
  } = useSelector((state) => state.clinics);

  const [selectedClinic, setSelectedClinic] = useState(null);

  useEffect(() => {
    if (clickedItem && clickedItem._id) {
      logInfo("Fetching specific clinic", { clinicId: clickedItem._id });
      dispatch(fetchClinicById(clickedItem._id))
        .unwrap()
        .then((clinic) => {
          logInfo("Specific clinic fetched successfully", {
            clinicId: clinic._id,
          });
          setSelectedClinic(clinic);
        })
        .catch((error) => {
          logError("Failed to fetch specific clinic", {
            error,
            clinicId: clickedItem._id,
          });
          console.error("Failed to fetch specific clinic:", error);
        });
    } else {
      logInfo("Fetching clinics", {
        page: pagination.currentPage,
        limit: pagination.pageSize,
      });
      dispatch(
        fetchClinics({
          page: pagination.currentPage,
          limit: pagination.pageSize,
        })
      )
        .unwrap()
        .then(() => {
          logInfo("Clinics fetched successfully", {
            clinicCount: clinics.length,
          });
        })
        .catch((error) => {
          logError("Failed to fetch clinics", { error });
          console.error("Failed to fetch clinics:", error);
        });
    }
  }, [
    dispatch,
    pagination.currentPage,
    pagination.pageSize,
    clickedItem,
    clinics.length,
  ]);

  const handleClinicClick = (clinicId) => {
    const clinic = clinics.find((clinic) => clinic._id === clinicId);
    console.log("clinic in clinicPage", clinic);
    setSelectedClinic(clinic);
  };

  const handleUpdateClinic = () => {
    logInfo("Clinic updated", {
      componentName: "ClinicPage",
      action: "updateClinic",
    });
    // Implement update clinic logic here
  };

  const handleAddClinic = () => {
    logInfo("Add Clinic action initiated", {
      componentName: "ClinicPage",
      action: "addClinic",
    });
    // Implement add clinic logic here
  };

  const handleCloseClinicDetails = () => {
    logInfo("Edit clinic closed", {
      componentName: "ClinicPage",
      action: "closeEditClinic",
    });
    setSelectedClinic(null);
  };

  if (clinicStatus === "loading") {
    return <div>Loading...</div>;
  }

  if (clinicStatus === "failed") {
    return <div>Error: {clinicError}</div>;
  }

  return (
    <>
      {selectedClinic ? (
        <ClinicOverview
          selectedClinic={selectedClinic}
          onClose={handleCloseClinicDetails}
          onUpdateClinic={handleUpdateClinic}
        />
      ) : (
        <div className="pt-4">
          <Table
            columns={[
              { header: "Name", accessor: "name" },
              {
                header: "Address",
                accessor: (row) => row.address?.street || "N/A",
              },
              { header: "Email", accessor: "email" },
              { header: "Phone", accessor: "phoneNumber" },
            ]}
            data={clinics || []}
            onRowClick={handleClinicClick}
            actions={[{ label: "Add Clinic", onClick: handleAddClinic }]}
            title="Clinics"
            description="A list of all clinics connected to your account."
          />
        </div>
      )}
    </>
  );
}
