import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllServices, addServiceToClinic } from '../../store/thunks/clinicThunk';
import { logInfo, logError } from '../../utils/logger';
import Table from '../../applicationUi/components/Table';
import CircularButton from '../../applicationUi/components/CircularButton';

const AddServiceToClinicModal = ({ isOpen, onClose, clinicId }) => {
    const dispatch = useDispatch();
    const [selectedServices, setSelectedServices] = useState([]);
    const { services, status, error } = useSelector((state) => state.services);

    useEffect(() => {
        if (isOpen) {
            dispatch(fetchAllServices())
                .unwrap()
                .then(() => {
                    logInfo('All services fetched successfully');
                })
                .catch((error) => {
                    logError('Failed to fetch all services', { error });
                });
        }
    }, [dispatch, isOpen]);

    const handleCheckboxChange = (serviceId) => {
        setSelectedServices(prev => 
            prev.includes(serviceId) 
                ? prev.filter(id => id !== serviceId)
                : [...prev, serviceId]
        );
    };

    const handleAddServices = () => {
        selectedServices.forEach(serviceId => {
            dispatch(addServiceToClinic({ clinicId, serviceId }))
                .unwrap()
                .then(() => {
                    logInfo('Service added to clinic successfully', { clinicId, serviceId });
                })
                .catch((error) => {
                    logError('Failed to add service to clinic', { error, clinicId, serviceId });
                });
        });
        onClose();
    };

    const columns = [
        {
            header: 'Select',
            accessor: '_id',
            Cell: ({ value }) => (
                <input
                    type="checkbox"
                    checked={selectedServices.includes(value)}
                    onChange={() => handleCheckboxChange(value)}
                />
            ),
        },
        { header: 'Name', accessor: 'name' },
        { header: 'Description', accessor: 'description' },
        { header: 'Duration (minutes)', accessor: 'duration' },
        { header: 'Price ($)', accessor: 'price' },
    ];

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
            <div className="relative top-20 mx-auto p-5 border w-3/4 shadow-lg rounded-md bg-white">
                <div className="mt-3">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">Add Services to Clinic</h3>
                    {status === 'loading' && <p>Loading services...</p>}
                    {error && <p className="text-red-500">Error: {error}</p>}
                    {services && services.length > 0 && (
                        <Table 
                            columns={columns}
                            data={services}
                        />
                    )}
                    <div className="mt-4 flex justify-end space-x-2">
                        <CircularButton onClick={handleAddServices} disabled={selectedServices.length === 0}>
                            Add Selected Services
                        </CircularButton>
                        <CircularButton onClick={onClose} variant="secondary">
                            Cancel
                        </CircularButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddServiceToClinicModal;
