import InputField from "../../applicationUi/components/intake/InputField";
import TextAreaField from "../../applicationUi/components/intake/TextAreaField";
import CheckboxField from "../../applicationUi/components/intake/CheckboxField"; // Use CheckboxField for true/false checkboxes

const MedicalHistorySection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold">Medical History</h2>

    {/* Medical Diagnosis */}
    <TextAreaField
      label="Medical Diagnosis, if applicable (PDD, cerebral palsy, Hydrocephalus, etc.)"
      name="medicalHistory.medicalDiagnosis"
      value={formData.medicalHistory.medicalDiagnosis}
      onChange={handleChange}
    />

    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {/* Birth Weight */}
      <InputField
        label="Birth Weight"
        name="medicalHistory.birthWeight"
        value={formData.medicalHistory.birthWeight}
        onChange={handleChange}
      />

      {/* Premature Field with True/False Radio Buttons */}
      <div className="pb-4">
        <label>Premature?</label>
        <div className="flex gap-4 items-center">
          <CheckboxField
            label="Yes"
            name="medicalHistory.premature"
            checked={formData.medicalHistory.premature === true}
            onChange={() => handleChange({ target: { name: "medicalHistory.premature", value: true, type: "radio" } })}
          />
          <CheckboxField
            label="No"
            name="medicalHistory.premature"
            checked={formData.medicalHistory.premature === false}
            onChange={() => handleChange({ target: { name: "medicalHistory.premature", value: false, type: "radio" } })}
          />
        </div>
      </div>

      {/* NICU Time */}
      <InputField
        label="NICU Time (if applicable)"
        name="medicalHistory.nicuTime"
        value={formData.medicalHistory.nicuTime}
        onChange={handleChange}
      />
    </div>

    {/* Hearing Concerns */}
    <TextAreaField
      label="Do you have concerns with your child's hearing? If yes, explain"
      name="medicalHistory.hearingConcerns"
      value={formData.medicalHistory.hearingConcerns}
      onChange={handleChange}
    />

    {/* Hearing Tested */}
    <TextAreaField
      label="Has your child ever had their hearing tested? If yes, when and where was the test done? What was the result?"
      name="medicalHistory.hearingTested"
      value={formData.medicalHistory.hearingTested}
      onChange={handleChange}
    />

    {/* Ear Infections */}
    <TextAreaField
      label="Has your child had a history of ear infections/congestion requiring medication and/or tubes? If yes, please explain. Include ENT name and phone number."
      name="medicalHistory.earInfections"
      value={formData.medicalHistory.earInfections}
      onChange={handleChange}
    />
  </section>
);

export default MedicalHistorySection;
