import { createSlice } from '@reduxjs/toolkit';
import { fetchNotes, createNote, updateNote, deleteNote } from '../thunks/notesThunk';

const initialState = {
  notes: [],
  loading: false,
  error: null,
};

const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch notes
      .addCase(fetchNotes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.notes = action.payload; // This will now correctly handle an empty array
        state.loading = false;
      })
      .addCase(fetchNotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Create note
      .addCase(createNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNote.fulfilled, (state, action) => {
        const clinicianIndex = state.notes.findIndex(item => item.clinicianId === action.payload.clinicianId);
        if (clinicianIndex !== -1) {
          state.notes[clinicianIndex].notes.push(action.payload);
        } else {
          state.notes.push({ clinicianId: action.payload.clinicianId, notes: [action.payload] });
        }
        state.loading = false;
      })
      .addCase(createNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update note
      .addCase(updateNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        const clinicianIndex = state.notes.findIndex(item => item.clinicianId === action.payload.clinicianId);
        if (clinicianIndex !== -1) {
          const noteIndex = state.notes[clinicianIndex].notes.findIndex(note => note._id === action.payload._id);
          if (noteIndex !== -1) {
            state.notes[clinicianIndex].notes[noteIndex] = action.payload;
          }
        }
        state.loading = false;
      })
      .addCase(updateNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete note
      .addCase(deleteNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.notes = state.notes.map(item => ({
          ...item,
          notes: item.notes.filter(note => note._id !== action.payload)
        })).filter(item => item.notes.length > 0);
        state.loading = false;
      })
      .addCase(deleteNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default notesSlice.reducer;
