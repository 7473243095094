import InputField from "../../applicationUi/components/intake/InputField";
import RadioGroup from "../../applicationUi/components/intake/RadioGroup";
import TextAreaField from "../../applicationUi/components/intake/TextAreaField";

const EducationHistorySection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold">H - Education History</h2>

    {/* Age the child started school */}
    <InputField
      label="What age did your child start school?"
      name="educationHistory.startedSchoolAge"
      value={formData.educationHistory.startedSchoolAge}
      onChange={handleChange}
    />

    {/* Repeated or skipped grades */}
    <RadioGroup
      label="Were any grades repeated or skipped?"
      name="educationHistory.repeatedGrades"
      options={[
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
      ]}
      selectedValue={formData.educationHistory.repeatedGrades}
      onChange={handleChange}
    />

    {formData.educationHistory.repeatedGrades === "yes" && (
      <TextAreaField
        label="Please describe the skipped or repeated grades"
        name="educationHistory.repeatedGradesDescription"
        value={formData.educationHistory.repeatedGradesDescription}
        onChange={handleChange}
      />
    )}

    {/* General grades */}
    <InputField
      label="What are your child's grades in general?"
      name="educationHistory.generalGrades"
      value={formData.educationHistory.generalGrades}
      onChange={handleChange}
    />

    {/* Satisfaction with performance */}
    <InputField
      label="Are you satisfied with your child's performance in school?"
      name="educationHistory.satisfiedWithPerformance"
      value={formData.educationHistory.satisfiedWithPerformance}
      onChange={handleChange}
    />

    {/* Specific subjects with difficulty */}
    <InputField
      label="Are there any school subjects with which your child has particular difficulty?"
      name="educationHistory.specificDifficultySubjects"
      value={formData.educationHistory.specificDifficultySubjects}
      onChange={handleChange}
    />

    {/* How the child gets along with other children */}
    <InputField
      label="How does your child get along with other school children?"
      name="educationHistory.getsAlongWithSchoolChildren"
      value={formData.educationHistory.getsAlongWithSchoolChildren}
      onChange={handleChange}
    />
  </section>
);

export default EducationHistorySection;
