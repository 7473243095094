import React, { useEffect, useState } from "react";
import SearchDropdown from "../applicationUi/components/SearchDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  createAppointment,
  fetchAppointmentsByClinicianId,
  checkForConflicts,
  updateAppointment, // import this
} from "../store/thunks/appointmentsThunk";
import { fetchClinicianById } from "../store/thunks/cliniciansThunk";
import QuickAddClientModal from "./QuickAddClientModal";

const QuickAddAppointmentModal = ({
  isOpen,
  onClose,
  initialDate,
  initialTime,
  initialClinicianFname,
  initialClinicianLname,
  selectedClinician: initialSelectedClinician,
  clinic,
  mode = "create",
  appointment = null,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const [isNewClient, setIsNewClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedReferral, setSelectedReferral] = useState(null);
  const [isBlock, setIsBlock] = useState(false);
  const [blockDates, setBlockDates] = useState([]);
  const conflicts = useSelector((state) => state.appointments.conflicts) || [];
  const [showActiveOnly, setShowActiveOnly] = useState(true);

  const [selectedClinician, setSelectedClinician] = useState(null);

  const [modalContent, setModalContent] = useState({
    date: initialDate || null,
    time: initialTime || "",
    clinicianFname: initialClinicianFname || "",
    clinicianLname: initialClinicianLname || "",
  });

  useEffect(() => {
    if (mode === "edit" && appointment) {
      const start = new Date(appointment.start);
      const hours = start.getHours().toString().padStart(2, "0");
      const minutes = start.getMinutes().toString().padStart(2, "0");

      setModalContent({
        date: start,
        time: `${hours}:${minutes}`,
        clinicianFname: appointment.clinician.firstName,
        clinicianLname: appointment.clinician.lastName,
      });

      setSelectedClient(appointment.client);
      setSelectedService({
        ...appointment.service,
        duration: appointment.service?.duration ?? 30,
      });
      setSelectedClinician(appointment.clinician);
      console.log(selectedClinician);
      setIsBlock(appointment.isBlock || false);
    }
  }, [mode, appointment]);

  useEffect(() => {
    if (
      mode === "create" &&
      initialSelectedClinician &&
      initialSelectedClinician._id
    ) {
      dispatch(fetchClinicianById(initialSelectedClinician._id)).then(
        (response) => {
          setSelectedClinician(response.payload);
        }
      );
    } else if (mode === "edit" && appointment) {
      setSelectedClinician(appointment.clinician);
    }
  }, [initialSelectedClinician, mode, appointment, dispatch]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [onClose]);

  useEffect(() => {
    if (isBlock && modalContent.date && modalContent.time) {
      const dates = [];
      const [hours, minutes] = modalContent.time.split(":").map(Number);
      for (let i = 0; i < 10; i++) {
        const date = new Date(modalContent.date);
        date.setDate(date.getDate() + i * 7);
        date.setHours(hours);
        date.setMinutes(minutes);
        dates.push(date);
      }
      setBlockDates(dates);

      const clinicianId = selectedClinician?._id;
      dispatch(checkForConflicts({ blockDates: dates, clinicianId }))
        .then((action) => {
          console.log("Conflicts:", action.payload);
        })
        .catch((error) => {
          console.error("Failed to fetch conflicts:", error);
        });
    } else {
      setBlockDates([]);
    }
  }, [
    isBlock,
    modalContent.date,
    modalContent.time,
    dispatch,
    selectedClinician,
  ]);

  const handleServiceSelection = (service) => {
    setSelectedService(service);
  };

  const handleReferralSelection = (referral) => {
    setSelectedReferral(referral);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModalContent((prevContent) => ({
      ...prevContent,
      [name]: value,
    }));
  };

  // Handle date changes from the date input
  const handleDateChange = (e) => {
    const newDateStr = e.target.value; // "YYYY-MM-DD"
    if (newDateStr) {
      const [year, month, day] = newDateStr.split("-");
      const updatedDate = new Date(modalContent.date || Date.now());
      updatedDate.setFullYear(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day)
      );
      setModalContent((prev) => ({ ...prev, date: updatedDate }));
    }
  };

  const handleSave = (event) => {
    event.preventDefault();
    const { date, time } = modalContent;

    if (!time) {
      console.error("Time is not defined");
      return;
    }

    if (
      !selectedClient ||
      !selectedService ||
      !selectedClinician ||
      !selectedClinician.defaultLocation
    ) {
      alert("All fields must be nonempty");
      return;
    }

    const [hours, minutes] = time.split(":").map(Number);
    const start = new Date(date);
    start.setHours(hours, minutes, 0, 0);
    const end = new Date(start);
    if (selectedService.duration === 0) {
      end.setMinutes(start.getMinutes() + 30);
    } else {
      end.setMinutes(start.getMinutes() + selectedService.duration);
    }

    const updatedData = {
      clinic: selectedClinician.defaultLocation,
      clinician: selectedClinician._id,
      client: selectedClient._id,
      title: selectedService.description,
      start: start.toISOString(),
      end: end.toISOString(),
      service: selectedService._id,
      status: mode === "create" ? ["Booked"] : appointment.status,
      history:
        mode === "create"
          ? [
              {
                status: "Booked",
                comments: "",
                timestamp: new Date().toISOString(),
                user: user._id,
              },
            ]
          : [
              ...appointment.history,
              {
                // appointmentId: appointment._id,
                status: "Changed",
                comments: "Appointment changed",
                user: user._id,
                timestamp: new Date().toISOString(),
              },
            ],
      isBlock,
      blockInfo: isBlock
        ? {
            totalSessions: 10,
            sessionsCompleted: appointment?.blockInfo?.sessionsCompleted || 0,
          }
        : undefined,
      userId: user._id,
    };

    if (mode === "create") {
      dispatch(createAppointment(updatedData));
    } else if (mode === "edit" && appointment) {
      dispatch(
        updateAppointment({
          id: appointment._id,
          updatedData,
        })
      );
    }

    onClose();
  };

  if (!isOpen) return null;

  // Convert date to YYYY-MM-DD for input
  const dateInputValue =
    modalContent.date instanceof Date && !isNaN(modalContent.date)
      ? modalContent.date.toISOString().slice(0, 10)
      : "";

  return (
    <div className="fixed inset-0 flex items-center justify-center z-60 overflow-y-auto">
      <div className="fixed inset-0 bg-gray-900 opacity-50" onClick={onClose} />
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full mx-4 relative z-60 overflow-y-auto max-h-[90vh]">
        <form onSubmit={handleSave}>
          {/* Header */}
          <div className="border-b border-gray-900/10 pb-6 mb-6">
            <h2 className="text-lg font-semibold leading-7 text-gray-900">
              {mode === "edit" ? "Update Appointment" : "Quick Add Appointment"}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {mode === "edit"
                ? "Update the fields as needed and confirm the changes."
                : "Use this form to quickly create an appointment."}
            </p>
          </div>

          {/* Client Information */}
          <div className="mt-8 border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Client Information
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {mode === "edit"
                ? "Search for a different client or keep the existing client selected."
                : "Search for an existing client or create a new one."}
            </p>

            {/* {selectedClient && ( */}
            <div className="mt-4 p-4 mb-4 border border-gray-300 rounded-md">
              <h3 className="text-sm font-semibold leading-7 text-gray-900 mb-2">
                Selected Client
              </h3>
              <p className="border border-gray-300 rounded-md p-4 text-sm leading-6 text-gray-600 mb-2">
                {selectedClient ? (
                  <>
                    {selectedClient.firstName} {selectedClient.lastName},{" "}
                    {selectedClient.email},{" "}
                    {selectedClient.phoneNumbers
                      .map((phone) => `${phone.type}: ${phone.number}`)
                      .join(", ")}
                  </>
                ) : (
                  "No client selected"
                )}
              </p>
            </div>
            {/* )} */}

            {/* {!isNewClient && ( */}
            <div className="border border-gray-300 p-4 rounded-md">
              <h3 className="text-sm font-semibold leading-7 text-gray-900 mb-2">
                {selectedClient ? "Select another client" : "Select a client"}
              </h3>

              <SearchDropdown
                prefix=":client:"
                displayFields={[
                  "firstName",
                  "lastName",
                  "email",
                  "phoneNumbers",
                ]}
                onSelection={(client) => setSelectedClient(client)}
                placeholder={"Search for a client"}
                value={selectedClient} // Optional: to show currently selected client
              />
              <p className="pt-4 text-sm font-semibold leading-7 text-gray-900 mb-2">
                OR Create new client
              </p>
              {mode === "create" && (
                <button
                  type="button"
                  onClick={() => setIsNewClient(true)}
                  className="block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
                  Add a New Client
                </button>
              )}
            </div>
            {/* )} */}
          </div>

          {/* Appointment Information */}
          <div className="mt-8 border-b border-gray-900/10 pb-8">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Appointment Information
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {mode === "edit"
                ? "You can now change the date, time, clinician, and service."
                : "Date, Time, and Clinician are prepopulated from your selection."}
            </p>

            <div className="border border-gray-300 rounded-md p-4 flex justify-between my-4 space-x-4">
              <div className="flex-1">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Date
                </label>
                <input
                  type="date"
                  value={dateInputValue}
                  onChange={handleDateChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
                />
              </div>

              <div className="flex-1">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Time
                </label>
                <input
                  type="time"
                  name="time"
                  value={modalContent.time || ""}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
                />
              </div>
            </div>

            <div className="my-4 border border-gray-300 p-4 rounded-md flex flex-row space-x-4">
              <div className="flex-1">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Selected Clinician
                </label>
                {selectedClinician && (
                  <div className="mt-2 p-2 border rounded-md mb-2">
                    <p className="text-sm text-gray-800">
                      {selectedClinician.firstName} {selectedClinician.lastName}
                      , {selectedClinician.specialization.join(", ")}
                    </p>
                  </div>
                )}
              </div>
              <div className="flex-1">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  OR Search for a clinician
                </label>
                <div className="mt-2">
                  <SearchDropdown
                    prefix=":clinician:"
                    displayFields={["firstName", "lastName", "email"]}
                    onSelection={(clinician) => {
                      setSelectedClinician(clinician);
                      setModalContent((prev) => ({
                        ...prev,
                        clinicianFname: clinician.firstName,
                        clinicianLname: clinician.lastName,
                      }));
                    }}
                    placeholder={"Search for a clinician"}
                  />
                </div>
              </div>
            </div>

            <div className="border border-gray-300 p-4 rounded-md">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Service
              </label>
              {selectedService && (
                <div className="mt-4 p-4 mb-4 border border-gray-300 rounded-md flex items-center">
                  <h3 className="text-sm font-semibold leading-7 text-gray-900 mr-4">
                    Selected Service:
                  </h3>
                  <p className="text-sm leading-6 text-gray-600">
                    {selectedService.description}, $
                    {selectedService.price.toFixed(2)}
                  </p>
                </div>
              )}

              <div className="flex flex-row items-center space-x-4">
                {/* Search Dropdown */}
                <div className="flex-1">
                  <SearchDropdown
                    prefix=":service:"
                    displayFields={["serviceCode", "description", "price"]}
                    onSelection={handleServiceSelection}
                    placeholder={"Search for a service by name or service code"}
                    activeServices={showActiveOnly}
                  />
                </div>

                {/* Checkbox and Label */}
                <div className="flex items-center">
                  <input
                    id="showActiveOnly"
                    type="checkbox"
                    checked={showActiveOnly}
                    onChange={() => setShowActiveOnly(!showActiveOnly)}
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="showActiveOnly"
                    className="ml-2 block text-sm text-gray-900">
                    Show only active services
                  </label>
                </div>
              </div>

              {/* Duration dropdown */}
              <div className="flex flex-row space-x-4 mt-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Duration
                  </label>
                  <div className="mt-2 text-gray-700">
                    <select
                      value={selectedService?.duration ?? 30}
                      onChange={(e) =>
                        setSelectedService({
                          ...selectedService,
                          duration: parseInt(e.target.value),
                        })
                      }
                      disabled={!selectedService}
                      className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none ${
                        !selectedService ? "opacity-50" : ""
                      }`}>
                      {[
                        15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180,
                        195, 210, 225, 240,
                      ].map((minutes) => (
                        <option key={minutes} value={minutes}>
                          {Math.floor(minutes / 60)}h {minutes % 60}m
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {selectedService && selectedService.duration === 0 && (
                <div className="mt-2 text-red-600 text-sm">
                  Please select a duration for this service.
                </div>
              )}
            </div>

            <div className="flex justify-around">
              <div className="flex items-center mt-4">
                <input
                  id="isBlock"
                  name="isBlock"
                  type="checkbox"
                  checked={isBlock}
                  onChange={() => setIsBlock(!isBlock)}
                  className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
                <label
                  htmlFor="isBlock"
                  className="ml-2 block text-sm text-gray-900">
                  Block Appointment (Book 10 consecutive weeks)
                </label>
              </div>
            </div>

            {isBlock && (
              <>
                <dl className="mt-5 grid grid-cols-1 overflow-hidden rounded-lg md:grid-cols-5">
                  {blockDates.map((date, index) => (
                    <div key={index} className="px-2 py-4">
                      <dt className="text-sm font-semibold text-gray-900">
                        Block #{index + 1}
                      </dt>
                      <dd className="flex items-baseline justify-between">
                        <div className="text-sm font-normal text-gray-900">
                          {new Date(date).toDateString()}
                          <div className="text-sm font-normal text-gray-900">
                            at{" "}
                            {new Date(date).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })}
                          </div>
                          {conflicts[index] && (
                            <div className="mt-2">
                              <p className="text-sm font-medium">
                                <span
                                  className={`font-semibold ${
                                    conflicts[index].status === "No conflict"
                                      ? "text-green-600"
                                      : "text-red-600"
                                  }`}>
                                  {conflicts[index].status}
                                </span>
                              </p>
                              {conflicts[index].status === "Conflict" && (
                                <ul className="text-sm">
                                  {conflicts[index].conflictDetails
                                    .appointments > 0 && (
                                    <li>
                                      <p className="font-semibold text-red-600">
                                        {
                                          conflicts[index].conflictDetails
                                            .appointments
                                        }{" "}
                                        Appointment
                                        {conflicts[index].conflictDetails
                                          .appointments > 1
                                          ? "s"
                                          : ""}
                                      </p>
                                    </li>
                                  )}
                                  {conflicts[index].conflictDetails.meetings >
                                    0 && (
                                    <li>
                                      <p className="font-semibold text-red-600">
                                        {
                                          conflicts[index].conflictDetails
                                            .meetings
                                        }{" "}
                                        Meeting
                                        {conflicts[index].conflictDetails
                                          .meetings > 1
                                          ? "s"
                                          : ""}
                                      </p>
                                    </li>
                                  )}
                                </ul>
                              )}
                            </div>
                          )}
                        </div>
                      </dd>
                    </div>
                  ))}
                </dl>

                {/* Sum up all conflicts */}
                {(() => {
                  const totalConflicts = conflicts.reduce(
                    (sum, conflict) =>
                      sum +
                      conflict.conflictDetails.appointments +
                      conflict.conflictDetails.meetings,
                    0
                  );

                  if (totalConflicts > 0) {
                    return (
                      <div className="flex flex-col items-center justify-center">
                        <p className="mt-4 font-semibold text-sm text-red-600 text-center">
                          {totalConflicts} conflicting appointments/meetings
                          detected for {selectedClinician?.firstName}{" "}
                          {selectedClinician?.lastName} at the selected time.
                        </p>
                        <p className="mt-4 font-semibold text-sm text-red-600 text-center">
                          Booking is still permitted.
                        </p>
                      </div>
                    );
                  } else {
                    return (
                      <div className="flex flex-col items-center justify-center">
                        <p className="mt-4 font-semibold text-sm text-green-600 text-center">
                          No conflicting appointments/meetings detected for{" "}
                          {selectedClinician?.firstName}{" "}
                          {selectedClinician?.lastName}.
                        </p>
                        <p className="mt-4 font-semibold text-sm text-green-600 text-center">
                          Booking is permitted.
                        </p>
                      </div>
                    );
                  }
                })()}
              </>
            )}
          </div>

          {/* Save and Cancel Buttons */}
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={onClose}
              className="text-sm font-semibold leading-6 text-gray-900">
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
              {mode === "edit"
                ? "Confirm Update Appointment"
                : "Create Appointment"}
            </button>
          </div>
        </form>

        {isNewClient && (
          <QuickAddClientModal onClose={() => setIsNewClient(false)} />
        )}
      </div>
    </div>
  );
};

export default QuickAddAppointmentModal;
