import InputField from "../../applicationUi/components/intake/InputField";
import TextAreaField from "../../applicationUi/components/intake/TextAreaField";

const DailyProgramSection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold">Child's Daily Program</h2>
    <InputField
      label="Program Type (eg. childcare, nursey, home, school)"
      name="dailyProgram.dailyProgram"
      value={formData.dailyProgram.dailyProgram}
      onChange={handleChange}
    />
    <TextAreaField
      label="Person/Agency/School Name"
      name="dailyProgram.dailyProgramName"
      value={formData.dailyProgram.dailyProgramName}
      onChange={handleChange}
    />

    <TextAreaField
      label="Overall Performance"
      name="dailyProgram.programPerformance"
      value={formData.dailyProgram.programPerformance}
      onChange={handleChange}
    />
    <TextAreaField
      label="Specific Difficulties"
      name="dailyProgram.specificDifficulties"
      value={formData.dailyProgram.specificDifficulties}
      onChange={handleChange}
    />

    <TextAreaField
      label="School attending"
      name="dailyProgram.schoolAttending"
      value={formData.dailyProgram.schoolAttending}
      onChange={handleChange}
    />
    <TextAreaField
      label="Current Grade"
      name="dailyProgram.currentGrade"
      value={formData.dailyProgram.currentGrade}
      onChange={handleChange}
    />
  </section>
);

export default DailyProgramSection;
