import CheckboxGroup from "../../applicationUi/components/intake/CheckboxGroup";
import RadioGroup from "../../applicationUi/components/intake/RadioGroup";
import TextAreaField from "../../applicationUi/components/intake/TextAreaField"; // Assuming you have this for other input fields

const VoiceConcernsSection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold">D - Voice Concerns</h2>

    {/* Does your child have voice problems? */}
    <RadioGroup
      label="Does your child have voice problems?"
      name="voiceConcerns.hasVoiceProblems"
      options={[
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
      ]}
      selectedValue={formData.voiceConcerns.hasVoiceProblems}
      onChange={handleChange}
    />

    {/* If No, prompt to move to the next section */}
    {formData.voiceConcerns.hasVoiceProblems === "no" && (
      <p className="text-sm text-gray-500">Please proceed to Section E.</p>
    )}

    {/* Conditional Rendering: Show only if 'yes' is selected */}
    {formData.voiceConcerns.hasVoiceProblems === "yes" && (
      <>
        {/* Voice Characteristics */}
        <h3 className="font-semibold mb-2">
          Which of the following are characteristics of your child's voice?
        </h3>

        <CheckboxGroup
          label="Voice Characteristics"
          namePrefix="voiceConcerns.characteristics"
          options={[
            { name: "too-high", label: "Too High" },
            { name: "too-low", label: "Too Low" },
            { name: "too-soft", label: "Too Soft" },
            { name: "nasal", label: "Nasal" },
            { name: "hoarse", label: "Hoarse" },
            { name: "congested", label: "Congested" },
            { name: "monotonous", label: "Monotonous" },
            { name: "breathy", label: "Breathy" },
          ]}
          selectedOptions={formData.voiceConcerns.characteristics}
          onChange={handleChange}
        />

        {/* Other voice concerns */}
        <TextAreaField
          label="Other (please specify)"
          name="voiceConcerns.characteristics.other"
          value={formData.voiceConcerns.characteristics.other}
          onChange={handleChange}
        />
      </>
    )}
  </section>
);

export default VoiceConcernsSection;
