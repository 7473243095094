import React, { useState } from "react";
import AddFamilyMemberModal from "./AddFamilyMemberModal";
import UnlinkFamilyMemberModal from "./UnlinkFamilyMemberModal";
import { fetchClientById } from "../../store/thunks/clientsThunk";
import Table from "../../applicationUi/components/Table";
import { logInfo } from "../../utils/logger";
import { useDispatch, useSelector } from "react-redux";

const FamilyDetails = ({ display }) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState({
    type: "",
    open: false,
    action: "",
  });
  const client = useSelector((state) => state.clients.selectedClient);
  console.log("client", client);
  
  // Utility function to calculate age from date of birth
  const calculateAge = (dateOfBirth) => {
    const dob = new Date(dateOfBirth);
    const diff = Date.now() - dob.getTime();
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  // Utility function to format phone numbers
  const formatPhoneNumbers = (phoneNumbers) => {
    if (!phoneNumbers || !Array.isArray(phoneNumbers)) {
      return "No phone numbers available";
    }
    return phoneNumbers
      .map((phone) => `${phone.type}: ${phone.number}`)
      .join(", ");
  };

  // Format family member data to fit table structure
  const formatFamilyMember = (member) => {
    console.log("member", member);

    return {
      _id: member._id,
      firstName: member.firstName,
      lastName: member.lastName,
      dateOfBirth: member.dateOfBirth
        ? new Date(member.dateOfBirth).toLocaleDateString()
        : "N/A",
      age: calculateAge(member.dateOfBirth),
      email: member.email,
      formattedPhoneNumbers: formatPhoneNumbers(member.phoneNumbers),
    };
  };

  // Example formatted data for parents and children
  const parents =
    client.parents?.map((parent) => formatFamilyMember(parent)) || [];
  const children =
    client.children?.map((child) => formatFamilyMember(child)) || [];

  // Open the modal for adding or unlinking a family member
  const handleOpenModal = (type, action) => {
    setShowModal({ type, open: true, action });
    logInfo(`Modal opened for ${action}ing ${type}`, { clientId: client._id });
  };

  // Close the modal
  const handleCloseModal = () => {
    setShowModal({ type: "", open: false, action: "" });
    logInfo("Modal closed", { clientId: client._id });
  };

  const handleLinkParent = () => {
    console.log(" test - link parent clicked");
  };

  return (
    <div className="family-details space-y-8">
      <h1 className="header text-2xl font-bold">Family Details</h1>

      {/* Parents Table */}
      <Table
        columns={[
          { header: "First Name", accessor: "firstName" },
          { header: "Last Name", accessor: "lastName" },
          { header: "Age", accessor: "age" },
          { header: "Email", accessor: "email" },
          { header: "Phone Numbers", accessor: "formattedPhoneNumbers" },
        ]}
        data={parents}
        onRowClick={(row) => console.log("Parent row clicked:", row)}
        actions={
          !display
            ? [
                {
                  label: "Link/Add Parent",
                  onClick: () => handleOpenModal("parent", "add"),
                },
                {
                  label: "Unlink Parent",
                  onClick: () => handleOpenModal("parent", "unlink"),
                },
              ]
            : undefined
        }
        title="Parents"
        description="List of parents"
        showPagination={false}
      />

      {/* Children Table */}
      <Table
        columns={[
          { header: "First Name", accessor: "firstName" },
          { header: "Last Name", accessor: "lastName" },
          { header: "Age", accessor: "age" },
          { header: "Email", accessor: "email" },
          { header: "Phone Numbers", accessor: "formattedPhoneNumbers" },
        ]}
        data={children}
        onRowClick={(row) => console.log("Child row clicked:", row)}
        actions={
          !display
            ? [
                {
                  label: "Link/Add Child",
                  onClick: () => handleOpenModal("child", "add"),
                },
                {
                  label: "Unlink Child",
                  onClick: () => handleOpenModal("child", "unlink"),
                },
              ]
            : undefined
        }
        title="Children"
        description="List of children"
        showPagination={false}
      />

      {/* Modal for adding family members */}
      {showModal.open && showModal.action === "add" && (
        <AddFamilyMemberModal
          memberType={showModal.type}
          clientId={client._id}
          onClose={handleCloseModal}
          onCancel={handleCloseModal}
          onSave={handleLinkParent}
          onUpdateClient={() => {
            console.log("Client updated!");
            dispatch(fetchClientById(client._id));
          }}
        />
      )}

      {/* Modal for unlinking family members */}
      {showModal.open && showModal.action === "unlink" && (
        <UnlinkFamilyMemberModal
          memberType={showModal.type}
          clientId={client._id}
          familyMembers={showModal.type === "parent" ? parents : children}
          onClose={handleCloseModal}
          onCancel={handleCloseModal}
          onUpdateClient={() => {
            console.log("Client updated!");
            dispatch(fetchClientById(client._id));
          }}
        />
      )}
    </div>
  );
};

export default FamilyDetails;
