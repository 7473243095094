// store/thunks/clientNotesThunk.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

// Create Client Note
export const createClientNote = createAsyncThunk(
  "clientNotes/createClientNote",
  async ({ clientId, content }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const userId = state.auth.user._id;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/clientNotes`,
        { clientId, content, userId },
        config
      );
      return response.data.note;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

// Fetch Client Note
export const fetchClientNote = createAsyncThunk(
  "clientNotes/fetchClientNote",
  async (clientId, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/clientNotes/${clientId}`,
        config
      );

      if (response.status === 204) {
        return null; // Indicate no content
      }

      return response.data.note;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

// Update Client Note
export const updateClientNote = createAsyncThunk(
  "clientNotes/updateClientNote",
  async ({ clientId, content }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const userId = state.auth.user._id;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.patch(
        `${baseURL}/api/clientNotes/${clientId}`,
        { content, userId },
        config
      );
      return response.data.note;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

// Delete Client Note
export const deleteClientNote = createAsyncThunk(
  "clientNotes/deleteClientNote",
  async (clientId, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      await axios.delete(`${baseURL}/api/clientNotes/${clientId}`, config);
      return clientId; // Return clientId to update the store accordingly
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);
