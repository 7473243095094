import TextAreaField from "../../applicationUi/components/intake/TextAreaField";
import InputField from "../../applicationUi/components/intake/InputField";

const CancellationPolicySection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold mb-4">
      Cancellation and Missed Appointment Fee Policy
    </h2>

    <p className="mb-4">
      At HearSay Speech, we value your commitment to improving your
      communication health. To provide you with the best possible service and to
      honor the time and dedication of our professional team, we have
      established the following Cancellation and Missed Appointment Fee Policy:
    </p>

    <ol className="list-decimal pl-6 mb-4">
      <li className="mb-2">
        <strong>Less than 24 Hours’ Notice for Cancellation:</strong>
        <p>
          If you need to cancel an appointment and provide less than 24 hours’
          notice, a 50% charge will be applied.
        </p>
      </li>
      <li className="mb-2">
        <strong>No-Show Policy:</strong>
        <p>
          In the event of a no-show, where you do not attend a scheduled
          appointment without prior notice, a 100% charge will be incurred for
          the session.
        </p>
      </li>
      <li>
        <strong>Rescheduling with 24 Hours’ Notice:</strong>
        <p>
          If you provide a minimum of 24 hours’ notice for rescheduling, there
          will be no additional cost.
        </p>
      </li>
    </ol>

    <p className="mb-4">
      These adjustments have been implemented to respect the time of our
      dedicated team and to maintain the efficiency of our services. We
      appreciate your understanding and cooperation in adhering to these
      policies.
    </p>

    <p className="font-semibold mb-4">
      I have read the above information that explains the cancellation/missed
      appointment fee. I understand that at least 24 hours’ notice is required
      to avoid these charges. I authorize HearSay to charge my credit card
      accordingly.
    </p>

    {/* Signature Section */}
    <TextAreaField
      label="Signature"
      name="cancellationPolicy.signature"
      value={formData.cancellationPolicy.signature}
      onChange={handleChange}
      placeholder="Enter your signature"
    />
    <InputField
      label="Date"
      name="cancellationPolicy.date"
      value={formData.cancellationPolicy.date}
      onChange={handleChange}
      placeholder="MM/DD/YYYY"
    />

    {/* Credit Card Information */}
    <InputField
      label="Credit Card Type"
      name="cancellationPolicy.cardType"
      value={formData.cancellationPolicy.cardType}
      onChange={handleChange}
      placeholder="Visa, MasterCard, etc."
    />
    <InputField
      label="Card Number"
      name="cancellationPolicy.cardNumber"
      value={formData.cancellationPolicy.cardNumber}
      onChange={handleChange}
      placeholder="Card Number"
    />
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    <InputField
        label="Expiry Date (MM/YYYY)"
        name="cancellationPolicy.expiryDate"
        value={formData.cancellationPolicy.expiryDate}
        onChange={handleChange}
        placeholder="MM/YYYY"
      />
      <InputField
        label="CVC"
        name="cancellationPolicy.cvc"
        value={formData.cancellationPolicy.cvc}
        onChange={handleChange}
        placeholder="CVC"
      />
    </div>
  </section>
);

export default CancellationPolicySection;
