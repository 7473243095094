import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClinicById } from "../../store/thunks/clinicThunk";
import {
  BuildingOffice2Icon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { logInfo, logError } from "../../utils/logger";
import ClinicDetails from "./ClinicDetails";
import ClinicStaff from "./ClinicStaff";
import ClinicServices from "./ClinicServices";

export default function ClinicOverview({
  selectedClinic,
  onClose,
  onUpdateClinic,
}) {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("details");
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  console.log("selectedClinic", selectedClinic);

  // useEffect(() => {
  //     if (selectedClinic?._id) {
  //         dispatch(fetchClinicById(selectedClinic._id));
  //     }
  // }, [selectedClinic, dispatch, refreshTrigger]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    logInfo("Tab changed", { newTab: tab });
  };

  const handleClinicUpdate = () => {
    setRefreshTrigger((prev) => prev + 1);
  };

  return (
    <div className="p-6 bg-white shadow rounded-lg mt-4">
      {" "}
      {/* Added mt-4 for top margin */}
      {/* Header */}
      <div className="flex justify-between items-center">
        {/* Left side: Clinic Name */}
        <div className="flex items-center">
          <div>
            <div className="flex items-center ml-3 text-xl font-bold text-gray-900 sm:truncate sm:leading-9">
              <p>{selectedClinic.name}</p>
              <BuildingOffice2Icon className="h-6 w-6 text-black ml-2" />
            </div>
            <p className="ml-3 sm:truncate">{selectedClinic.address?.street}</p>
            <p className="ml-3 sm:truncate">
              {selectedClinic.address?.city}, {selectedClinic.address?.province}{" "}
              {selectedClinic.address?.postalCode}
            </p>
            <p className="ml-3">{selectedClinic.phoneNumber}</p>
            <p className="ml-3">{selectedClinic.email}</p>
          </div>
        </div>

        {/* Right side: Close Button */}
        <div className="flex items-center space-x-4">
          <button
            type="button"
            onClick={() => {
              onClose();
              logInfo("Clinic overview closed", {
                clinicId: selectedClinic._id,
              });
            }}
            className="inline-flex items-center rounded-md bg-gray-200 p-2 text-gray-500 hover:bg-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
      {/* Tabs Navigation */}
      <div className="mt-4">
        <nav className="flex space-x-4" aria-label="Tabs">
          <button
            onClick={() => handleTabChange("details")}
            className={classNames(
              selectedTab === "details"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}
            aria-current={selectedTab === "details" ? "page" : undefined}>
            Clinic Details
          </button>
          <button
            onClick={() => handleTabChange("staff")}
            className={classNames(
              selectedTab === "staff"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}>
            Staff
          </button>
          <button
            onClick={() => handleTabChange("services")}
            className={classNames(
              selectedTab === "services"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}>
            Services
          </button>
          <button
            onClick={() => handleTabChange("appointments")}
            className={classNames(
              selectedTab === "appointments"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}>
            Appointments
          </button>
          <button
            onClick={() => handleTabChange("files")}
            className={classNames(
              selectedTab === "files"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}>
            Files
          </button>
        </nav>
      </div>
      {/* Content for each tab */}
      <div className="mt-6">
        {selectedTab === "details" && (
          <ClinicDetails
            clinic={selectedClinic}
            onUpdateClinic={handleClinicUpdate}
          />
        )}
        {selectedTab === "staff" && (
          <ClinicStaff
            clinic={selectedClinic}
            onUpdateClinic={handleClinicUpdate}
          />
        )}
        {selectedTab === "services" && (
          <ClinicServices
            clinic={selectedClinic}
            onUpdateClinic={handleClinicUpdate}
          />
        )}
      </div>
    </div>
  );
}

// Helper function for dynamic class names
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
