// src/store/slices/payrollSlice.js

import { createSlice } from "@reduxjs/toolkit";
import {
  createPayroll,
  fetchPayrolls,
  fetchPayrollsByPayPeriod,
  fetchPayrollById,
  fetchPayrollsByClinicianId,
  fetchPayrollsByClinicianStatusAndPayPeriod,
  fetchAllPayrollsByStatusAndPayPeriod,
  updatePayroll,
  deletePayroll,
} from "../thunks/payrollThunk";

const initialState = {
  payrolls: [],
  clinicianPayroll: [],
  allCliniciansPayrolls: [],
  currentPayroll: null,
  status: "idle",
  error: null,
};

const payrollSlice = createSlice({
  name: "payroll",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPayroll.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createPayroll.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.payrolls.push(action.payload);
      })
      .addCase(createPayroll.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchPayrolls.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPayrolls.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.payrolls = action.payload;
      })
      .addCase(fetchPayrolls.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchPayrollsByPayPeriod.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPayrollsByPayPeriod.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.payrolls = action.payload;
      })
      .addCase(fetchPayrollsByPayPeriod.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchPayrollById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPayrollById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.currentPayroll = action.payload;
      })
      .addCase(fetchPayrollsByClinicianId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPayrollsByClinicianId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.payrolls = action.payload;
      })
      .addCase(fetchPayrollsByClinicianId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Used in clinicianpayrolloverviewpage
      .addCase(fetchPayrollsByClinicianStatusAndPayPeriod.pending, (state) => {
        state.status = "loading";
        state.clinicianPayroll = [];
      })
      .addCase(
        fetchPayrollsByClinicianStatusAndPayPeriod.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          state.clinicianPayroll = action.payload;
        }
      )
      .addCase(
        fetchPayrollsByClinicianStatusAndPayPeriod.rejected,
        (state, action) => {
          state.status = "failed";
          state.error = action.payload;
          state.clinicianPayroll = [];
        }
      )

      // Used exclusively in hrpayrolloveriewpage
      .addCase(fetchAllPayrollsByStatusAndPayPeriod.pending, (state) => {
        state.status = "loading";
        state.error = [];
      })
      .addCase(
        fetchAllPayrollsByStatusAndPayPeriod.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          state.allCliniciansPayrolls = action.payload;
        }
      )
      .addCase(
        fetchAllPayrollsByStatusAndPayPeriod.rejected,
        (state, action) => {
          state.status = "failed";
          state.error = action.payload;
        }
      )

      .addCase(fetchPayrollById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updatePayroll.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePayroll.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.payrolls.findIndex(
          (payroll) => payroll._id === action.payload._id
        );
        if (index !== -1) {
          state.payrolls[index] = action.payload;
        }
      })
      .addCase(updatePayroll.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deletePayroll.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deletePayroll.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.payrolls = state.payrolls.filter(
          (payroll) => payroll._id !== action.payload
        );
      })
      .addCase(deletePayroll.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default payrollSlice.reducer;
