import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CheckIcon } from "@heroicons/react/24/solid";
import UserPersonalInformation from "./components/UserPersonalInformation";
import UserHrInformation from "./components/UserHrInformation";
import UserClinicInformation from "./components/UserClinicInformation";
import UserAvailabilityInformation from "./components/UserAvailabilityInformation";

const AddUser = ({ onClose, onUserAdded }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [userId, setUserId] = useState(null);

  const [steps, setSteps] = useState([
    { id: "01", name: "Personal Information", status: "current" },
    { id: "02", name: "HR Information", status: "upcoming" },
    { id: "03", name: "Clinic Information", status: "upcoming" },
    { id: "04", name: "Availability", status: "upcoming" },
  ]);

  const handleStepComplete = (stepData) => {
    console.log("handleStepComplete called for step:", steps[currentStep]);

    // Update the form data with the step data
    setFormData((prevData) => ({ ...prevData, ...stepData }));

    console.log("userId", userId);

    // If the current step is the last one, call onUserAdded and exit
    if (currentStep === steps.length - 1) {
      onUserAdded(formData); // Trigger callback indicating user creation is complete
      return null;
    }

    goToNextStep();
  };

  const goToNextStep = () => {
    if (currentStep < steps.length - 1) {
      updateStepStatus(currentStep + 1);
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const updateStepStatus = (newStep) => {
    setSteps((prevSteps) =>
      prevSteps.map((step, index) => ({
        ...step,
        status:
          index < newStep
            ? "complete"
            : index === newStep
            ? "current"
            : "upcoming",
      }))
    );
    console.log("Updated Steps:", steps);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <UserPersonalInformation
            onComplete={(userData) => {
              setUserId(userData._id);
              handleStepComplete(userData);
            }}
            isEditMode={false}
            initialData={formData}
          />
        );
      case 1:
        return (
          <UserHrInformation
            onComplete={handleStepComplete}
            user={{ ...formData, _id: userId }}
            userId={userId}
          />
        );
      case 2:
        return (
          <UserClinicInformation
            onComplete={handleStepComplete}
            user={{ ...formData, _id: userId }}
          />
        );
      case 3:
        return (
          <UserAvailabilityInformation
            onComplete={handleStepComplete}
            user={{ ...formData, _id: userId }}
          />
        );
      default:
        return null;
    }
  };

  const StepIndicator = ({ step, stepIdx }) => {
    const handleClick = () => {
      if (stepIdx <= currentStep) {
        console.log(`Navigating to Step: ${step.id} - ${step.name}`);
        setCurrentStep(stepIdx);
        updateStepStatus(stepIdx);
      }
    };

    const isComplete = step.status === "complete";
    const isCurrent = step.status === "current";

    return (
      <div
        className="group flex w-full items-center cursor-pointer"
        onClick={handleClick}>
        <span className="flex items-center px-6 py-4 text-sm font-medium">
          <span
            className={`flex h-10 w-10 items-center justify-center rounded-full ${
              isComplete
                ? "bg-green-600"
                : isCurrent
                ? "border-2 border-indigo-600"
                : "border-2 border-gray-300"
            }`}>
            {isComplete ? (
              <CheckIcon className="h-6 w-6 text-white" />
            ) : (
              <span
                className={`${
                  isCurrent ? "text-indigo-600" : "text-gray-400"
                }`}>
                {step.id}
              </span>
            )}
          </span>
          <span
            className={`ml-4 text-sm font-medium ${
              isCurrent || isComplete ? "text-black" : "text-gray-400"
            }`}>
            {step.name}
          </span>
        </span>
      </div>
    );
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg w-full mx-auto">
      <nav aria-label="Progress">
        <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
          {steps.map((step, stepIdx) => (
            <li key={step.name} className="relative md:flex md:flex-1">
              <StepIndicator step={step} stepIdx={stepIdx} />
              {stepIdx !== steps.length - 1 && (
                <div
                  className="absolute right-0 top-0 hidden h-full w-5 md:block"
                  aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none">
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentColor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              )}
            </li>
          ))}
        </ol>
      </nav>

      <div className="mt-8">{renderStep()}</div>

      <div className="mt-6 flex justify-between">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50">
          Cancel
        </button>
        {currentStep > 0 && (
          <button
            type="button"
            onClick={() => setCurrentStep((prev) => prev - 1)}
            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50">
            Back
          </button>
        )}
      </div>
    </div>
  );
};

export default AddUser;
