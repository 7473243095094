import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClinicStaff, addStaffToClinic, removeStaffFromClinic } from '../../store/thunks/clinicThunk';
import { logInfo, logError } from '../../utils/logger';
import Table from '../../applicationUi/components/Table';
import CircularButton from '../../applicationUi/components/CircularButton';
import AddStaffToClinicModal from './AddStaffToClinicModal';

export default function ClinicStaff({ clinic, onUpdateClinic }) {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { staff, status, error } = useSelector((state) => state.clinics);

    useEffect(() => {
        if (clinic?._id) {
            dispatch(fetchClinicStaff(clinic._id))
                .unwrap()
                .then(() => {
                    logInfo('Clinic staff fetched successfully', { clinicId: clinic._id });
                })
                .catch((error) => {
                    logError('Failed to fetch clinic staff', { error, clinicId: clinic._id });
                });
        }
    }, [dispatch, clinic]);

    const handleAddStaff = (staffData) => {
        dispatch(addStaffToClinic({ clinicId: clinic._id, staffData }))
            .unwrap()
            .then(() => {
                logInfo('Staff member added successfully', { clinicId: clinic._id });
                setIsModalOpen(false);
            })
            .catch((error) => {
                logError('Failed to add staff member', { error, clinicId: clinic._id });
            });
    };

    const handleRemoveStaff = (staffId) => {
        if (window.confirm('Are you sure you want to remove this staff member?')) {
            dispatch(removeStaffFromClinic({ clinicId: clinic._id, staffId }))
                .unwrap()
                .then(() => {
                    logInfo('Staff member removed successfully', { clinicId: clinic._id, staffId });
                })
                .catch((error) => {
                    logError('Failed to remove staff member', { error, clinicId: clinic._id, staffId });
                });
        }
    };

    if (status === 'loading') {
        return <div>Loading staff data...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Clinic Staff</h2>
                <CircularButton onClick={() => setIsModalOpen(true)}>Add Staff Member</CircularButton>
            </div>
            <Table
              columns={[
                { header: "Name", accessor: "name" },
                { header: "Role", accessor: "role" },
                { header: "Email", accessor: "email" },
              ]}
              data={staff || []}
              onRowClick={(staffId) => {
                // Handle row click if needed
                logInfo("Staff row clicked", { staffId });
              }}
              onDeleteRow={(staffId) => {
                handleRemoveStaff(staffId);
              }}
              actions={[
                {
                  label: "Add Staff",
                  onClick: () => {
                    logInfo("Add Staff button clicked");
                    setIsModalOpen(true);
                  },
                },
              ]}
              title="Clinic Staff"
              description="A list of all staff members in this clinic."
              showPagination={false} // Assuming pagination is not needed for staff list
            />
            <AddStaffToClinicModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onAddStaff={handleAddStaff}
                clinicId={clinic._id}
                existingStaff={staff || []}
            />
        </div>
    );
}
