import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createIntakeForm,
  fetchIntakeForms,
} from "../../store/thunks/intakeThunk";
import Table from "../../applicationUi/components/Table";
import IntakeDetailsModal from "./IntakeDetailsModal";
import { Dialog, Transition } from "@headlessui/react";
import SearchDropdown from "../../applicationUi/components/SearchDropdown";

function IntakesPage() {
  const dispatch = useDispatch();
  const intakes = useSelector((state) =>
    Array.isArray(state.intake.allIntakeForms)
      ? state.intake.allIntakeForms
      : []
  );
  const [selectedIntake, setSelectedIntake] = useState(null);
  const [generatedUrl, setGeneratedUrl] = useState("");
  const [isUrlModalOpen, setIsUrlModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [useExistingClient, setUseExistingClient] = useState(false);
  const [isCreatingIntake, setIsCreatingIntake] = useState(false);

  useEffect(() => {
    dispatch(fetchIntakeForms());
  }, [dispatch, selectedIntake, isUrlModalOpen]);

  const user = useSelector((state) => state.auth.user);

  const handleCreateIntake = async (client = null) => {
    try {
      const payload = client
        ? {
            client: {
              firstName: client.firstName,
              lastName: client.lastName,
              dob: client.dateOfBirth,
              gender: client.gender,
              address: {
                aptUnit: client.address?.aptUnit || "",
                streetNumber: client.address?.streetNumber || "",
                streetName: client.address?.streetName || "",
                city: client.address?.city || "",
                province: client.address?.province || "",
                postalCode: client.address?.postalCode || "",
                country: client.address?.country || "Canada",
              },
            },
            createdClient: client._id,
            createdBy: user._id,
          }
        : { createdBy: user._id };

      console.log("Payload being sent to API:", payload);
      const result = await dispatch(createIntakeForm(payload));
      const intakeId = result.payload._id;

      if (intakeId) {
        const url = `${window.location.origin}/intake/${intakeId}`;
        setGeneratedUrl(url);
        setIsUrlModalOpen(true);
        console.log("Generated URL:", url);
      }
    } catch (error) {
      console.error("Error in handleCreateIntake:", error);
    }
  };

  const handleCreateNewClientIntake = async () => {
    try {
      const payload = { createdBy: user._id };

      console.log("Payload for new client intake being sent to API:", payload);
      const result = await dispatch(createIntakeForm(payload));
      const intakeId = result.payload._id;

      if (intakeId) {
        const url = `${window.location.origin}/intake/${intakeId}`;
        setGeneratedUrl(url);
        setIsUrlModalOpen(true);
        console.log("Generated URL for new client:", url);
      }
    } catch (error) {
      console.error("Error in handleCreateNewClientIntake:", error);
    }
  };

  const handleCreateExistingClientIntake = async (client) => {
    if (isCreatingIntake) return; // Prevent duplicate calls
    setIsCreatingIntake(true);

    try {
      const payload = {
        client: {
          firstName: client.firstName,
          lastName: client.lastName,
          dob: client.dateOfBirth,
          gender: client.gender,
          address: {
            aptUnit: client.address?.aptUnit || "",
            streetNumber: client.address?.streetNumber || "",
            streetName: client.address?.streetName || "",
            city: client.address?.city || "",
            province: client.address?.province || "",
            postalCode: client.address?.postalCode || "",
            country: client.address?.country || "Canada",
          },
        },
        createdClient: client._id,
        createdBy: user._id,
      };

      console.log(
        "Payload for existing client intake being sent to API:",
        payload
      );
      const result = await dispatch(createIntakeForm(payload));
      const intakeId = result.payload._id;

      if (intakeId) {
        const url = `${window.location.origin}/intake/${intakeId}`;
        setGeneratedUrl(url);
        setIsUrlModalOpen(true);
        console.log("Generated URL for existing client:", url);
      }
    } catch (error) {
      console.error("Error in handleCreateExistingClientIntake:", error);
    } finally {
      setIsCreatingIntake(false); // Reset the flag
    }
  };

  const handleRowClick = (intakeId) => {
    const intake = intakes.find((item) => item._id === intakeId);
    setSelectedIntake(intake);
  };

  const handleModalClose = () => {
    setSelectedIntake(null);
  };

  const handleUrlModalClose = () => {
    setIsUrlModalOpen(false);
    setGeneratedUrl("");
  };

  const columns = useMemo(
    () => [
      { header: "First Name", accessor: "client.firstName" },
      { header: "Last Name", accessor: "client.lastName" },
      { header: "Gender", accessor: "client.gender" },
      { header: "Date of Birth", accessor: "client.dob" },
      { header: "Doctor Name", accessor: "client.doctorName" },
      { header: "Referral Reason", accessor: "client.referralReason" },
      {
        header: "Intake Status",
        accessor: (row) =>
          row.statusHistory?.length > 0
            ? row.statusHistory[row.statusHistory.length - 1].status
            : "Unknown",
      },
    ],
    []
  );

  const actions = useMemo(
    () => [
      {
        label: "Create New Intake Form",
        onClick: () => setIsCreateModalOpen(true),
      },
    ],
    []
  );

  return (
    <div className="py-10">
      <Table
        columns={columns}
        data={intakes}
        onRowClick={handleRowClick}
        title="Intakes"
        description="A list of all intake forms."
        actions={actions}
      />
      {selectedIntake && (
        <IntakeDetailsModal
          intake={selectedIntake}
          onClose={handleModalClose}
        />
      )}

      {/* Modal to choose client type */}
      <Transition.Root show={isCreateModalOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsCreateModalOpen(false)}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900">
                        Create New Intake Form
                      </Dialog.Title>
                      <div className="mt-4">
                        <p className="text-sm text-gray-500">
                          Choose whether to use an existing client or create a
                          new one.
                        </p>
                        <div className="mt-4 space-y-4">
                          <button
                            className="w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700"
                            onClick={() => {
                              setUseExistingClient(true);
                            }}>
                            Use Existing Client
                          </button>

                          {useExistingClient && (
                            <div className="mt-4">
                              <SearchDropdown
                                prefix=":client:"
                                displayFields={[
                                  "firstName",
                                  "lastName",
                                  "email",
                                  "phoneNumbers",
                                ]}
                                onSelection={(client) => {
                                  if (client) {
                                    setSelectedClient(client);
                                    setUseExistingClient(false);
                                    setIsCreateModalOpen(false);
                                    handleCreateExistingClientIntake(client); // Ensure only one call
                                  }
                                }}
                                placeholder="Search for an existing client"
                              />
                            </div>
                          )}

                          <button
                            className="w-full px-4 py-2 text-sm font-medium text-white bg-gray-600 rounded-md hover:bg-gray-700"
                            onClick={() => {
                              setSelectedClient(null);
                              handleCreateNewClientIntake();
                              setIsCreateModalOpen(false);
                            }}>
                            Use New Client
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* URL Modal */}
      <Transition.Root show={isUrlModalOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={handleUrlModalClose}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900">
                        Share URL with Client
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Copy the link below to share with the client:
                        </p>
                        <div className="mt-4 flex items-center space-x-4">
                          <a
                            href={generatedUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-indigo-500 underline break-all">
                            {generatedUrl}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 flex justify-end space-x-3">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={handleUrlModalClose}>
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default IntakesPage;
