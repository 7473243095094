import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  updateIntakeForm,
  fetchIntakeFormById,
} from "../../store/thunks/intakeThunk";
import {
  fetchUnauthenticatedIntakeFormById,
  updateUnauthenticatedIntakeForm,
} from "../../store/thunks/unauthenticatedIntakeThunk";
import BeginIntakeSection from "./BeginIntakeSection";
import ClientInformationSection from "./ClientInformation";
import ParentGuardianSection from "./ParentGuardian";
import SiblingsSection from "./Siblings";
import MedicalHistorySection from "./MedicalHistory";
import DailyProgramSection from "./DailyProgram";
import PreschoolLanguageSection from "./A-PreschoolLanguage";
import ArticulationSection from "./B-Articulation";
import LanguageConcernsSection from "./C-LanguageConcerns";
import VoiceConcernsSection from "./D-VoiceConcerns";
import FluencyConcernsSection from "./E-FluencyConcerns";
import HearingLossSection from "./F-HearingLoss";
import AcademicImpactSection from "./G-AcademicImpact";
import EducationHistorySection from "./H-EducationHistory";
import PostAppointmentSection from "./I-PostAppointment";
import ConsentAgreementsSection from "./J-ConsentAgreements";
import LimitsConfidentialitySection from "./K-LimitsConfidentiality";
import CancellationPolicySection from "./L-CancellationPolicy";
import PreAuthorizedPaymentSection from "./M-PreAuthorizedPayment";
import CodeOfConductSection from "./N-CodeOfConduct";
import ProgressBar from "../../applicationUi/components/intake/ProgressBar";

const IntakeForm = () => {
  const isAuthenticated = useSelector((state) => state.auth?.user);
  console.log("isAuthenticated:", isAuthenticated);

  const [formData, setFormData] = useState({
    client: {
      firstName: "",
      lastName: "",
      gender: "",
      pronouns: "",
      dob: "",
      doctorName: "",
      doctorPhone: "",
      address: {
        aptUnit: "",
        streetNumber: "",
        streetName: "",
        city: "",
        province: "",
        postalCode: "",
        country: "Canada",
      },
      referralSource: "",
      referralReason: "",
    },
    parentGuardian1: {
      firstName: "",
      surname: "",
      workPhone: "",
      homePhone: "",
      cellPhone: "",
      email: "",
      occupation: "",
      mainContact: false,
      notes: "",
    },
    parentGuardian2: {
      firstName: "",
      surname: "",
      workPhone: "",
      homePhone: "",
      cellPhone: "",
      email: "",
      occupation: "",
      mainContact: false,
      notes: "",
    },
    siblings: "",
    medicalHistory: {
      medicalDiagnosis: "",
      birthWeight: "",
      premature: null,
      nicuTime: "",
      hearingConcerns: "",
      hearingTested: "",
      earInfections: "",
    },
    dailyProgram: {
      dailyProgram: "",
      dailyProgramName: "",
      programPerformance: "",
      specificDifficulties: "",
    },
    // A - Preschool Language
    preschoolLanguage: {
      lessThan50Words: null,
      knowsName: false,
      knowsNumbers1to10: false,
      knowsBodyParts: false,
      knowsBasicColors: false,
      understandsShortRequests: false,
      understandsStories: false,
      understandsChildrensTvPrograms: false,
      understandsAdultConversations: false,
      communicates: {
        eyeContactBodyMovements: false,
        sounds: false,
        gestures: false,
      },
      understandingSpeech: "",
      communicatesWithOtherChildren: {
        eyeContactBodyMovements: false,
        playsAlone: false,
        other: "",
      },
      whatChildLikesToCommunicate: {
        whatTheyAreDoing: false,
        whatYouAreDoing: false,
        other: "",
      },
    },
    // B - Articulation Concerns
    articulationConcerns: {
      difficultyPronouncing: null,
      understoodBySelf: "",
      understoodByOthers: "",
      understoodByPeers: "",
      difficultWords: "",
    },
    // C - Language Concerns
    languageConcerns: {
      difficultyWithLanguage: null,
      shortSentences: null,
      incorrectGrammar: null,
      limitedVocabulary: null,
      difficultyTellingStory: null,
      sentenceType: "",
      sentenceExamples: "",
      comprehension: {
        understandsCommonObjects: null,
        understandsQuestions: null,
        understandsStories: null,
      },
    },
    // D - Voice Concerns
    voiceConcerns: {
      hasVoiceProblems: null,
      characteristics: {
        tooHigh: false,
        tooLow: false,
        tooSoft: false,
        nasal: false,
        hoarse: false,
        congested: false,
        monotonous: false,
        breathy: false,
        other: "",
      },
    },
    // E - Fluency Concerns
    fluencyConcerns: {
      stutters: null,
      avoidsTalkingAtHomeOrSchool: null,
      avoidsTalkingToAdults: null,
      avoidsTalkingToPeers: null,
      bodyTwitchesWhenStuttering: null,
    },
    // F - Hearing Loss Concerns
    hearingLossConcerns: {
      hasHearingLoss: null,
      needsRepetition: null,
      frequentEarInfections: null,
      difficultyFollowingDirections: null,
      difficultyHearingInNoisyEnvironment: null,
    },
    // G - Academic/Social Impact
    academicSocialImpact: {
      impactOnDayToDay: {
        shy: false,
        actingOut: false,
        teased: false,
        avoidsTalking: false,
        avoidsEyeContact: false,
        other: "",
      },
      academicPerformance: "",
      hasPlaymates: null,
      playmatesAge: "",
      interactionWithPlaymates: "",
      siblingsInteraction: "",
      favoriteActivitiesAtHome: "",
    },
    // H - Education History
    educationHistory: {
      startedSchoolAge: "",
      repeatedGrades: null,
      repeatedGradesDescription: "",
      generalGrades: "",
    },
    // I - Post Appointment Expectations
    postAppointmentExpectations: "",
    // J - Consent Agreements
    consentAgreements: {
      consentForSeparatedParents: null, // Holds the selected consent agreement
      parent1Signature: "", // Parent/Guardian #1 - Print Name and Signature
      parent1Email: "", // Parent/Guardian #1 - Email
      parent1Phone: "", // Parent/Guardian #1 - Phone Number
      parent2Signature: "", // Parent/Guardian #2 - Print Name and Signature
      parent2Email: "", // Parent/Guardian #2 - Email
      parent2Phone: "", // Parent/Guardian #2 - Phone Number
      limitsOfConfidentialityAgreement: null,
      cancellationAndMissedAppointmentAgreement: null,
    },
    // K - Limits of Confidentiality
    limitsOfConfidentiality: {
      parent1Signature: "",
      parent1Date: "",
      parent2Signature: "",
      parent2Date: "",
    },
    // L - Cancellation and Missed Appointment Policy
    cancellationPolicy: {
      signature: "",
      date: "",
      cardType: "",
      cardNumber: "",
      expiryDate: "",
      cvc: "",
    },
    // M - Pre-authorized Credit Card Payment
    preAuthorizedPayment: {
      name: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      visa: "",
      masterCard: "",
      expiryMonth: "",
      expiryYear: "",
      securityCode: "",
      cardHolderSignature: "",
      date: "",
    },
    // N - Code of Conduct
    codeOfConduct: {
      commitment: {
        fairness: false,
        integrity: false,
        mutualRespect: false,
      },
      clientSignature: "",
      agreeToCodeOfConduct: false,
      date: "",
    },
  });
  const { id } = useParams();

  // Fetch states from both authenticated and unauthenticated slices
  const authenticatedState = useSelector((state) => state.intake);
  const unauthenticatedState = useSelector(
    (state) => state.unauthenticatedIntake
  );

  // Dynamically assign the intake state
  const { intakeForm, loading, error } = isAuthenticated
    ? authenticatedState
    : unauthenticatedState;

  const [currentSection, setCurrentSection] = useState(-1); // Set to -1 to show the "Start Intake" section first
  const [intakeId, setIntakeId] = useState(id); // Store the ID of the created intake form
  const dispatch = useDispatch();
  const [completed, setCompleted] = useState(false); // To track when all sections are done

  // Fetch intake form data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        let result;

        // Check if the user is authenticated
        if (isAuthenticated) {
          // Use authenticated fetch
          result = await dispatch(fetchIntakeFormById(id));
        } else {
          result = await dispatch(fetchUnauthenticatedIntakeFormById(id));
        }

        if (result.payload) {
          // Merge response data with formData
          setFormData((prevData) => ({
            ...prevData,
            ...result.payload, // Merge top-level fields
            client: { ...prevData.client, ...result.payload.client },
            parentGuardian1: {
              ...prevData.parentGuardian1,
              ...result.payload.parentGuardian1,
            },
            parentGuardian2: {
              ...prevData.parentGuardian2,
              ...result.payload.parentGuardian2,
            },
            siblings: result.payload.siblings || prevData.siblings,
            medicalHistory: {
              ...prevData.medicalHistory,
              ...result.payload.medicalHistory,
            },
            dailyProgram: {
              ...prevData.dailyProgram,
              ...result.payload.dailyProgram,
            },
            preschoolLanguage: {
              ...prevData.preschoolLanguage,
              ...result.payload.preschoolLanguage,
              communicates: {
                ...prevData.preschoolLanguage.communicates,
                ...result.payload.preschoolLanguage?.communicates,
              },
              communicatesWithOtherChildren: {
                ...prevData.preschoolLanguage.communicatesWithOtherChildren,
                ...result.payload.preschoolLanguage
                  ?.communicatesWithOtherChildren,
              },
              whatChildLikesToCommunicate: {
                ...prevData.preschoolLanguage.whatChildLikesToCommunicate,
                ...result.payload.preschoolLanguage
                  ?.whatChildLikesToCommunicate,
              },
            },
            articulationConcerns: {
              ...prevData.articulationConcerns,
              ...result.payload.articulationConcerns,
            },
            languageConcerns: {
              ...prevData.languageConcerns,
              ...result.payload.languageConcerns,
              comprehension: {
                ...prevData.languageConcerns.comprehension,
                ...result.payload.languageConcerns?.comprehension,
              },
            },
            voiceConcerns: {
              ...prevData.voiceConcerns,
              ...result.payload.voiceConcerns,
              characteristics: {
                ...prevData.voiceConcerns.characteristics,
                ...result.payload.voiceConcerns?.characteristics,
              },
            },
            fluencyConcerns: {
              ...prevData.fluencyConcerns,
              ...result.payload.fluencyConcerns,
            },
            hearingLossConcerns: {
              ...prevData.hearingLossConcerns,
              ...result.payload.hearingLossConcerns,
            },
            academicSocialImpact: {
              ...prevData.academicSocialImpact,
              ...result.payload.academicSocialImpact,
              impactOnDayToDay: {
                ...prevData.academicSocialImpact.impactOnDayToDay,
                ...result.payload.academicSocialImpact?.impactOnDayToDay,
              },
            },
            educationHistory: {
              ...prevData.educationHistory,
              ...result.payload.educationHistory,
            },
            postAppointmentExpectations:
              result.payload.postAppointmentExpectations ||
              prevData.postAppointmentExpectations,
            consentAgreements: {
              ...prevData.consentAgreements,
              ...result.payload.consentAgreements,
            },
            limitsOfConfidentiality: {
              ...prevData.limitsOfConfidentiality,
              ...result.payload.limitsOfConfidentiality,
            },
            cancellationPolicy: {
              ...prevData.cancellationPolicy,
              ...result.payload.cancellationPolicy,
            },
            preAuthorizedPayment: {
              ...prevData.preAuthorizedPayment,
              ...result.payload.preAuthorizedPayment,
            },
            codeOfConduct: {
              ...prevData.codeOfConduct,
              ...result.payload.codeOfConduct,
              commitment: {
                ...prevData.codeOfConduct.commitment,
                ...result.payload.codeOfConduct?.commitment,
              },
            },
          }));
        }
      } catch (error) {
        console.error("Error fetching intake form:", error);
      }
    };

    fetchData();
  }, [dispatch, id, isAuthenticated]);
  //   const [currentSection, setCurrentSection] = useState(0);

  // Handles form data changes
  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    const keys = name.split(".");

    setFormData((prevFormData) => {
      let data = { ...prevFormData };

      keys.reduce((acc, key, idx) => {
        if (idx === keys.length - 1) {
          acc[key] = type === "checkbox" ? checked : value;
        }
        return acc[key];
      }, data);

      return data;
    });
  };

  const sectionDataMapping = [
    { key: "client", label: "Client Information" },
    {
      key: ["parentGuardian1", "parentGuardian2"],
      label: "Parent/Guardian Information",
    },
    { key: "siblings", label: "Siblings Information" },
    { key: "medicalHistory", label: "Medical History" },
    { key: "dailyProgram", label: "Daily Program" },
    { key: "preschoolLanguage", label: "Preschool Language" },
    { key: "articulationConcerns", label: "Articulation Concerns" },
    { key: "languageConcerns", label: "Language Concerns" },
    { key: "voiceConcerns", label: "Voice Concerns" },
    { key: "fluencyConcerns", label: "Fluency/Stuttering Concerns" },
    { key: "hearingLossConcerns", label: "Hearing Loss Concerns" },
    { key: "academicSocialImpact", label: "Academic/Social Impact" },
    { key: "educationHistory", label: "Education History" },
    {
      key: "postAppointmentExpectations",
      label: "Post-Appointment Expectations",
    },
    { key: "consentAgreements", label: "Consent Agreements" },
    { key: "limitsOfConfidentiality", label: "Limits of Confidentiality" },
    {
      key: "cancellationPolicy",
      label: "Cancellation and Missed Appointment Policy",
    },
    {
      key: "preAuthorizedPayment",
      label: "Pre-authorized Credit Card Payment",
    },
    { key: "codeOfConduct", label: "Code of Conduct" },
  ];

  const logSectionData = (sectionIndex) => {
    const { key, label } = sectionDataMapping[sectionIndex];
    if (Array.isArray(key)) {
      key.forEach((k) => console.log(`${label} - ${k}:`, formData[k]));
    } else {
      console.log(`${label}:`, formData[key]);
    }
  };

  // Start the intake form
  const handleStartIntake = async () => {
    setCurrentSection(0);
  };

  const handleNext = async () => {
    if (intakeId) {
      // Log the current section data
      logSectionData(currentSection);

      if (isAuthenticated) {
        await dispatch(updateIntakeForm({ id: id, updatedData: formData }));
      } else {
        await dispatch(
          updateUnauthenticatedIntakeForm({ id: id, updatedData: formData })
        );
      }
    }

    if (isLastSection) {
      // All sections completed
      setCompleted(true);
    } else {
      setCurrentSection((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setCurrentSection((prev) => prev - 1);
  };

  const sections = [
    <ClientInformationSection
      formData={formData}
      handleChange={handleChange}
    />,
    <ParentGuardianSection formData={formData} handleChange={handleChange} />,
    <SiblingsSection formData={formData} handleChange={handleChange} />,
    <MedicalHistorySection formData={formData} handleChange={handleChange} />,
    <DailyProgramSection formData={formData} handleChange={handleChange} />,
    <PreschoolLanguageSection
      formData={formData}
      handleChange={handleChange}
    />,
    <ArticulationSection formData={formData} handleChange={handleChange} />,
    <LanguageConcernsSection formData={formData} handleChange={handleChange} />,
    <VoiceConcernsSection formData={formData} handleChange={handleChange} />,
    <FluencyConcernsSection formData={formData} handleChange={handleChange} />,
    <HearingLossSection formData={formData} handleChange={handleChange} />,
    <AcademicImpactSection formData={formData} handleChange={handleChange} />,
    <EducationHistorySection formData={formData} handleChange={handleChange} />,
    <PostAppointmentSection formData={formData} handleChange={handleChange} />,
    <ConsentAgreementsSection
      formData={formData}
      handleChange={handleChange}
    />,
    <LimitsConfidentialitySection
      formData={formData}
      handleChange={handleChange}
    />,
    <CancellationPolicySection
      formData={formData}
      handleChange={handleChange}
    />,
    <PreAuthorizedPaymentSection
      formData={formData}
      handleChange={handleChange}
    />,
    <CodeOfConductSection formData={formData} handleChange={handleChange} />,
  ];

  const isLastSection = currentSection === sections.length - 1;
  const progress = ((currentSection + 1) / sections.length) * 100;

  if (loading) return <p>Loading intake form...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!intakeForm) return <p>Intake form not found.</p>;

  return (
    <div className="container mx-auto p-4">
      {/* Logo Section */}
      <div className="flex justify-center items-center mb-6">
        <img
          className="h-24 w-auto"
          src="https://www.hearsay.ca/wp-content/uploads/2022/03/hearsay_logo_fnl_c.jpg"
          alt="HearSay Speech and Hearing Centre"
        />
      </div>
      {currentSection === -1 ? (
        <BeginIntakeSection onStart={handleStartIntake} />
      ) : completed ? (
        <div className="text-center mt-10">
          <h2 className="text-2xl font-semibold">Thank you!</h2>
          <p className="mt-4">
            Your responses have been saved. We will be in touch shortly.
          </p>
          <p className="mt-4">You can close this tab. </p>
        </div>
      ) : (
        <>
          <ProgressBar progress={progress} />
          <div className="flex justify-between mt-4">
            <button
              onClick={handleBack}
              disabled={currentSection === 0}
              className="btn">
              Back
            </button>
            <button onClick={handleNext} className="btn">
              {isLastSection ? "Submit" : "Next"}
            </button>
          </div>
          <div className="mt-10">{sections[currentSection]}</div>
        </>
      )}
    </div>
  );
};

export default IntakeForm;
