import { createSlice } from "@reduxjs/toolkit";
import {
  fetchQboConnectionStatus,
  startQboOAuth,
  disconnectQbo,
  fetchQboInvoices,
} from "../thunks/qboThunk";

const qboSlice = createSlice({
  name: "qbo",
  initialState: {
    isConnected: null, // null = loading, true = connected, false = disconnected
    status: "idle",
    error: null,
    invoices: [], // To store fetched invoices
    pagination: {
      totalInvoices: 0,
      currentPage: 1,
      totalPages: 1,
      pageSize: 10,
    },
  },
  reducers: {
    // Add any additional reducers if needed
  },
  extraReducers: (builder) => {
    builder
      // Fetch QuickBooks Connection Status
      .addCase(fetchQboConnectionStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchQboConnectionStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isConnected = action.payload.connected;
      })
      .addCase(fetchQboConnectionStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Start QuickBooks OAuth
      .addCase(startQboOAuth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(startQboOAuth.fulfilled, (state) => {
        state.status = "succeeded";
        // No state changes needed, OAuth flow happens via redirect
      })
      .addCase(startQboOAuth.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Disconnect QuickBooks
      .addCase(disconnectQbo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(disconnectQbo.fulfilled, (state) => {
        state.status = "succeeded";
        state.isConnected = false; // Update state to reflect disconnected status
      })
      .addCase(disconnectQbo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Fetch QuickBooks Invoices
      .addCase(fetchQboInvoices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchQboInvoices.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.invoices = action.payload.invoices;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchQboInvoices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default qboSlice.reducer;
