import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUserAsync, logoutUserAsync } from '../store/thunks/authThunk';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, isAuthenticated, error } = useSelector((state) => state.auth);

    useEffect(() => {
        if (error && error.includes('Unauthorized')) {
            handleUserLogout();
        }
    }, [error]);

    const handleUserLoginAsync = async (userData) => {
        try {
            localStorage.removeItem("persist:ClinicFlow");
            await dispatch(loginUserAsync(userData));
            navigate('/dashboard');
        } catch (error) {
            console.error('Error logging in:', error);
            throw error;
        }
    };

    const handleUserLogout = async () => {
        try {
            console.log("LOGOUT USER: " + JSON.stringify(user));
            await dispatch(logoutUserAsync(user));
            localStorage.removeItem("persist:ClinicFlow");
            navigate('/login'); // Navigate to login page on logout
        } catch (error) {
            console.error('Error logging out:', error);
            throw error;
        }
    };

    return (
        <AuthContext.Provider
            value={{ isAuthenticated, user, handleUserLoginAsync, handleUserLogout }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
