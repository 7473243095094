import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import QuickAddClientModal from "../../modals/QuickAddClientModal";

function IntakeDetailsModal({ intake, onClose }) {
  const [showQuickAddModal, setShowQuickAddModal] = useState(false);

  const handleApproveIntake = () => {
    setShowQuickAddModal(true);
  };

  const closeQuickAddModal = () => {
    setShowQuickAddModal(false);
  };

  const renderValue = (value) => {
    if (typeof value === "boolean") {
      return value ? "Yes" : "No";
    }
    if (value === null || value === undefined || value === "") {
      return "N/A";
    }
    if (typeof value === "object") {
      return (
        <div className="pl-4">
          {Object.entries(value || {}).map(([nestedKey, nestedValue]) => (
            <div key={nestedKey} className="flex">
              <span className="text-sm font-medium text-gray-700 capitalize">
                {nestedKey.replace(/([A-Z])/g, " $1").toLowerCase()}:
              </span>
              <span className="ml-2 text-sm text-gray-600">
                {renderValue(nestedValue)}
              </span>
            </div>
          ))}
        </div>
      );
    }
    return value;
  };

  const renderSection = (title, data) => {
    if (!data || Object.keys(data).length === 0) {
      return (
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-4">{title}</h3>
          <p className="text-sm text-gray-500">No data available</p>
        </div>
      );
    }

    return (
      <div>
        <h3 className="text-lg font-semibold text-gray-900 mb-4">{title}</h3>
        <div className="grid grid-cols-3 gap-4">
          {Object.entries(data).map(([key, value]) => (
            <div key={key} className="flex flex-col">
              <span className="text-sm font-medium text-gray-700 capitalize">
                {key.replace(/([A-Z])/g, " $1").toLowerCase()}
              </span>
              <span className="text-sm text-gray-600">
                {renderValue(value)}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  if (!intake) {
    return null;
  }

  return (
    <>
      <Transition.Root show={true} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-6 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-8">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 sm:mt-0 sm:ml-4">
                      <Dialog.Title
                        as="h3"
                        className="text-2xl font-medium leading-6 text-gray-900 flex items-center justify-between">
                        <span>Intake Details</span>
                      </Dialog.Title>

                      <p className="mt-2 text-sm text-gray-500">
                        View the intake form at:{" "}
                        {intake?._id && (
                          <a
                            href={`${window.location.origin}/intake/${intake._id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-sm text-indigo-600 hover:underline">
                            {`${window.location.origin}/intake/${intake._id}`}
                          </a>
                        )}
                      </p>
                      <p className="mt-2 text-sm text-gray-500">
                        Review all intake information below.
                      </p>
                    </div>
                  </div>
                  <div className="mt-6 space-y-8">
                    {renderSection("Client Information", intake.client)}
                    {renderSection("Parent/Guardian 1", intake.parentGuardian1)}
                    {renderSection("Parent/Guardian 2", intake.parentGuardian2)}
                    {renderSection("Medical History", intake.medicalHistory)}
                    {renderSection("Daily Program", intake.dailyProgram)}
                    {renderSection(
                      "Preschool Language",
                      intake.preschoolLanguage
                    )}
                    {renderSection(
                      "Articulation Concerns",
                      intake.articulationConcerns
                    )}
                    {renderSection(
                      "Language Concerns",
                      intake.languageConcerns
                    )}
                    {renderSection("Voice Concerns", intake.voiceConcerns)}
                    {renderSection("Fluency Concerns", intake.fluencyConcerns)}
                    {renderSection(
                      "Hearing Loss Concerns",
                      intake.hearingLossConcerns
                    )}
                    {renderSection(
                      "Academic/Social Impact",
                      intake.academicSocialImpact
                    )}
                    {renderSection(
                      "Education History",
                      intake.educationHistory
                    )}
                    {renderSection("Post Appointment Expectations", {
                      expectations: intake.postAppointmentExpectations,
                    })}
                    {renderSection(
                      "Consent Agreements",
                      intake.consentAgreements
                    )}
                    {renderSection(
                      "Limits of Confidentiality",
                      intake.limitsOfConfidentiality
                    )}
                    {renderSection(
                      "Cancellation Policy",
                      intake.cancellationPolicy
                    )}
                    {renderSection(
                      "Pre-Authorized Payment",
                      intake.preAuthorizedPayment
                    )}
                    {renderSection("Code of Conduct", intake.codeOfConduct)}
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleApproveIntake}>
                      Approve Intake and Create Client
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={onClose}>
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {showQuickAddModal && (
        <QuickAddClientModal
          onClose={closeQuickAddModal}
          intakeClient={intake?.client || {}} // Pass client details or empty object
        />
      )}
    </>
  );
}

export default IntakeDetailsModal;
