import { createSlice } from "@reduxjs/toolkit";
import {
  incrementPhoneCall,
  decrementPhoneCall,
  fetchPhoneCalls,
} from "../thunks/phoneCallThunk";

const phoneCallSlice = createSlice({
  name: "phoneCalls",
  initialState: {
    phoneCalls: {}, // Store phone call data by date
    loading: false,
    error: null,
  },
  reducers: {
    resetPhoneCalls: (state) => {
      state.phoneCalls = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPhoneCalls.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPhoneCalls.fulfilled, (state, action) => {
        state.loading = false;
        const { date, count, auditTrail } = action.payload;
        // Store the call information for the specific date
        state.phoneCalls[date] = { count, auditTrail };
      })
      .addCase(fetchPhoneCalls.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(incrementPhoneCall.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(incrementPhoneCall.fulfilled, (state, action) => {
        state.loading = false;
        const { date, count, auditTrail } = action.payload;
        state.phoneCalls[date] = { count, auditTrail };
      })
      .addCase(incrementPhoneCall.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(decrementPhoneCall.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(decrementPhoneCall.fulfilled, (state, action) => {
        state.loading = false;
        const { date, count, auditTrail } = action.payload;
        state.phoneCalls[date] = { count, auditTrail };
      })
      .addCase(decrementPhoneCall.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetPhoneCalls } = phoneCallSlice.actions;
export default phoneCallSlice.reducer;
