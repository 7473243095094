import { createSlice } from "@reduxjs/toolkit";
import {
  fetchIntakeForms,
  fetchIntakeFormById,
  createIntakeForm,
  updateIntakeForm,
  deleteIntakeForm,
} from "../thunks/intakeThunk";

const initialState = {
  allIntakeForms: [],
  intakeForm: null,
  loading: false,
  error: null,
};

const intakeSlice = createSlice({
  name: "intake",
  initialState,
  reducers: {
    setIntakeForms: (state, action) => {
      state.allIntakeForms = action.payload;
      state.loading = false;
      state.error = null;
    },
    setIntakeForm: (state, action) => {
      state.intakeForm = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Intake Forms
      .addCase(fetchIntakeForms.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIntakeForms.fulfilled, (state, action) => {
        state.allIntakeForms = action.payload;
        state.loading = false;
      })
      .addCase(fetchIntakeForms.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      // Fetch Single Intake Form
      .addCase(fetchIntakeFormById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIntakeFormById.fulfilled, (state, action) => {
        state.intakeForm = action.payload;
        state.loading = false;
      })
      .addCase(fetchIntakeFormById.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      // Create Intake Form
      .addCase(createIntakeForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createIntakeForm.fulfilled, (state, action) => {
        console.log("Payload in fulfilled:", action.payload); // Debugging
        state.allIntakeForms.push(action.payload);
        state.loading = false;
      })
      .addCase(createIntakeForm.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      // // Update Intake Form
      // .addCase(updateIntakeForm.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(updateIntakeForm.fulfilled, (state, action) => {
      //   const index = state.intakeForms.findIndex(
      //     (form) => form._id === action.payload._id
      //   );
      //   if (index !== -1) {
      //     state.intakeForms[index] = action.payload;
      //   }
      //   state.loading = false;
      // })
      // .addCase(updateIntakeForm.rejected, (state, action) => {
      //   state.error = action.error.message;
      //   state.loading = false;
      // })

      // Delete Intake Form
      .addCase(deleteIntakeForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteIntakeForm.fulfilled, (state, action) => {
        state.allIntakeForms = state.allIntakeForms.filter(
          (form) => form._id !== action.payload
        );
        state.loading = false;
      })
      .addCase(deleteIntakeForm.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export const { setIntakeForms, setIntakeForm, setLoading, setError } =
  intakeSlice.actions;

export default intakeSlice.reducer;
