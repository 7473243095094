import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ClinicScheduleModal from "../pages/schedulePage/ClinicScheduleModal";
import FullScreenSchedule from "../pages/schedulePage/FullScreenSchedule";
import QuickAddAppointmentModal from ".//QuickAddAppointmentModal";
import QuickAddClientModal from "./QuickAddClientModal";

const QuickAddModal = ({ onClose, activeModal }) => {
  return (
    <>
      {activeModal === "client" && <QuickAddClientModal onClose={onClose} />}

      {activeModal === "appointment" && (
        <QuickAddAppointmentModal onClose={onClose} />
      )}

      {activeModal === "schedule" && (
        <FullScreenSchedule
          isOpen={activeModal === "schedule"}
          onClose={() => onClose()}>
          <DndProvider backend={HTML5Backend}>
            <ClinicScheduleModal setModalOpen={onClose} />
          </DndProvider>
        </FullScreenSchedule>
      )}
    </>
  );
};

export default QuickAddModal;
