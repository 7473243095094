import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../SoapForm.css";
import {
  saveSoapNote,
  updateSoapNote,
  completeSoapNote,
  approveSoapNote,
  fetchAppointmentById,
} from "../../store/thunks/appointmentsThunk";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const SOAPForm = ({ onClose, appointment }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  console.log("this appt in soapform", appointment);

  const [isNewSoapNote, setIsNewSoapNote] = useState(true); // Initialize as true

  const [isFreeform, setIsFreeform] = useState(false);
  const [soapData, setSoapData] = useState([]);
  const [structuredData, setStructuredData] = useState({
    subjective: "",
    objective: "",
    assessment: "",
    plan: "",
  });
  const [isSignedOff, setIsSignedOff] = useState(false);

  useEffect(() => {
    if (appointment && appointment.soapNote) {
      setIsNewSoapNote(appointment.soapNote.length === 0);
    }
  }, [appointment]);

  useEffect(() => {
    if (
      appointment &&
      appointment.soapNote &&
      appointment.soapNote.length > 0
    ) {
      const latestNote = appointment.soapNote[appointment.soapNote.length - 1];
      setIsSignedOff(!!latestNote.signedOffBy);

      if (latestNote.templateUsed === "freeform") {
        setIsFreeform(true);
        const freeformFields = Object.entries(latestNote.noteData).map(
          ([name, value]) => ({ id: Date.now() + Math.random(), name, value })
        );
        setSoapData(freeformFields);
      } else if (latestNote.templateUsed === "structured") {
        setIsFreeform(false);
        setStructuredData(latestNote.noteData || {});
      }
    }
  }, [appointment]);

  const handleAddField = () => {
    setSoapData((prev) => [...prev, { id: Date.now(), name: "", value: "" }]);
  };

  const handleSave = async (event) => {
    event.preventDefault();

    const note = isFreeform
      ? {
          noteData: soapData.reduce((acc, field) => {
            acc[field.name] = field.value;
            return acc;
          }, {}),
          createdBy: user._id,
          templateUsed: "freeform",
        }
      : {
          noteData: structuredData,
          createdBy: user._id,
          templateUsed: "structured",
        };

    try {
      if (isNewSoapNote) {
        await dispatch(saveSoapNote([appointment, user, note])).unwrap();
        const shouldClose = window.confirm(
          "SOAP Note Saved. Do you want to close this popup?"
        );
        if (shouldClose) {
          onClose();
        }
      } else {
        await dispatch(updateSoapNote([appointment, user, note])).unwrap();
      }

      await dispatch(fetchAppointmentById(appointment._id)).unwrap();

      // Alert the user after updating
      if (!isNewSoapNote) {
        const shouldClose = window.confirm(
          "SOAP Note Updated. Do you want to close this popup?"
        );
        if (shouldClose) {
          onClose();
        }
      }
    } catch (error) {
      console.error("Save/Update failed:", error);
      window.alert(
        "An error occurred while saving/updating the SOAP Note. Please try again."
      );
    }
  };

  const handleCompleteSoapNote = async () => {
    const confirmed = window.confirm(
      "Complete SOAP Note? Once marked as complete, the SOAP Note cannot be edited except by SLP's."
    );
    if (!confirmed) return;

    const note = isFreeform
      ? {
          noteData: soapData.reduce((acc, field) => {
            acc[field.name] = field.value;
            return acc;
          }, {}),
          createdBy: user._id,
          templateUsed: "freeform",
        }
      : {
          noteData: structuredData,
          createdBy: user._id,
          templateUsed: "structured",
        };

    try {
      await dispatch(updateSoapNote([appointment, user, note])).unwrap();
      await dispatch(completeSoapNote([appointment, user])).unwrap();
      await dispatch(fetchAppointmentById(appointment._id)).unwrap();

      const shouldClose = window.confirm(
        "SOAP Note Completed. Do you want to close this popup?"
      );
      if (shouldClose) {
        onClose();
      }
    } catch (error) {
      console.error("Error completing SOAP note:", error);
      window.alert(
        "An error occurred while completing the SOAP Note. Please try again."
      );
    }
  };

  const handleApprove = async () => {
    const confirmed = window.confirm(
      "This action cannot be undone. Once approved, the SOAP Note cannot be edited."
    );
    if (!confirmed) return;

    try {
      const finalNote = {
        noteData: isFreeform
          ? soapData.reduce((acc, field) => {
              acc[field.name] = field.value;
              return acc;
            }, {})
          : structuredData,
        templateUsed: isFreeform ? "freeform" : "structured",
      };

      console.log("Final Note to Approve:", finalNote);

      await dispatch(
        approveSoapNote({
          appointment,
          user,
          finalNote,
        })
      ).unwrap();
      await dispatch(fetchAppointmentById(appointment._id)).unwrap();
      window.alert("SOAP Note Approved. You can close this popup.");
      // onClose();
    } catch (error) {
      console.error("Approve SOAP Note failed:", error);
    }
  };

  const canApproveNote =
    appointment.soapNoteCompleted &&
    (user.specialization?.includes("SLP") ||
      user._id === appointment.client?.supervisingClinician?.current);

  const canEditNote = !appointment.soapNoteCompleted || canApproveNote;

  // Render buttons dynamically
  const renderButtons = () => {
    if (appointment.soapNoteFinalized) {
      return null;
    }

    return (
      <div className="mt-6 flex flex-col gap-y-4">
        {appointment.soapNoteCompleted ? (
          <div className="flex justify-start">
            <button
              type="button"
              onClick={handleApprove}
              disabled={!canApproveNote}
              className={`rounded-md px-3 py-2 text-sm font-semibold ${
                canApproveNote
                  ? "bg-indigo-600 text-white hover:bg-indigo-500"
                  : "bg-gray-300 text-gray-500"
              }`}>
              Approve SOAP Note (SLP ONLY)
            </button>
          </div>
        ) : (
          <div className="flex justify-end gap-x-4">
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
              disabled={!canEditNote}>
              {isNewSoapNote ? "Save SOAP Note" : "Update SOAP Note"}
            </button>
            {!isNewSoapNote && !appointment.soapNoteCompleted && (
              <button
                type="button"
                onClick={handleCompleteSoapNote}
                className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500">
                Complete SOAP Note
              </button>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-70 overflow-y-auto">
      <div className="fixed inset-0 bg-gray-900 opacity-50" onClick={onClose} />
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full mx-4 relative z-70 overflow-y-auto max-h-[90vh]">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
          <XMarkIcon className="h-6 w-6 text-gray-800" />
        </button>

        {/* Client and Appointment Information */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div className="flex">
            <label className="block text-sm font-semibold leading-6 text-gray-900">
              CLIENT NAME:
            </label>
            <p className="text-gray-900 sm:text-sm sm:leading-6 px-3">
              {appointment.client.firstName + " " + appointment.client.lastName}
            </p>
          </div>
          <div className="flex">
            <label className="block text-sm font-semibold leading-6 text-gray-900">
              D.O.B (YYYY-MM-DD):
            </label>
            <p className="text-gray-900 sm:text-sm sm:leading-6 px-3">
              {appointment.client.dateOfBirth.split("T")[0]}
            </p>
          </div>

          <div className="flex">
            <label className="block text-sm font-semibold leading-6 text-gray-900">
              TREATING CLINICIAN:
            </label>
            <p className="text-gray-900 sm:text-sm sm:leading-6 px-3">
              {appointment.clinician.firstName +
                " " +
                appointment.clinician.lastName}
            </p>
          </div>
          <div className="flex">
            <label className="block text-sm font-semibold leading-6 text-gray-900">
              SUPERVISING CLINICIAN:
            </label>
            <p className="text-gray-900 sm:text-sm sm:leading-6 px-3">
              {appointment.supervisingClinician
                ? appointment.supervisingClinician.firstName +
                  " " +
                  appointment.supervisingClinician.lastName
                : "N/A"}
            </p>
          </div>
          <div className="flex">
            <label className="block text-sm font-semibold leading-6 text-gray-900">
              SESSION DATE:
            </label>
            <p className="text-gray-900 sm:text-sm sm:leading-6 px-3">
              {appointment.start
                ? new Date(appointment.start).toLocaleString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "2-digit",
                    hour12: true,
                  })
                : "N/A"}
            </p>
          </div>
          <div className="flex">
            <label className="block text-sm font-semibold leading-6 text-gray-900">
              SESSION DURATION:
            </label>
            <p className="text-gray-900 sm:text-sm sm:leading-6 px-3">
              {appointment.end && appointment.start
                ? Math.round(
                    (new Date(appointment.end) - new Date(appointment.start)) /
                      60000
                  ) + " min"
                : "N/A"}
            </p>
          </div>
        </div>

        <form onSubmit={handleSave}>
          <div className="flex flex-col border-b border-gray-900/10 pb-6 mb-6 gap-y-4">
            <h2 className="text-2xl font-semibold leading-7 text-gray-900">
              SOAP Note
            </h2>
            <p className="text-sm text-gray-600">
              You are viewing the{" "}
              <b>{isFreeform ? `freeform` : `structured`}</b> SOAP Form. Once a
              SOAP note is created, the form cannot be changed.
            </p>
            <p className="text-sm text-gray-600">
              More formats will come in the future.
            </p>
            <div className="flex justify-around">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  checked={!isFreeform}
                  onChange={() => setIsFreeform(!isFreeform)}
                  className="form-checkbox h-4 w-4 text-indigo-600"
                  disabled={!isNewSoapNote || appointment.soapNoteCompleted}
                />
                <span className="ml-2 text-sm text-gray-700">
                  Structured Mode
                </span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  checked={isFreeform}
                  onChange={() => setIsFreeform(!isFreeform)}
                  className="form-checkbox h-4 w-4 text-indigo-600"
                  disabled={!isNewSoapNote || appointment.soapNoteCompleted}
                />
                <span className="ml-2 text-sm text-gray-700">
                  Freeform Mode
                </span>
              </label>
            </div>
          </div>

          {isFreeform
            ? soapData.map((field, index) => (
                <div className="mb-6 flex flex-col gap-2" key={field.id}>
                  <label className="block text-sm font-semibold text-gray-900">
                    Field Name {index + 1}
                  </label>
                  <input
                    type="text"
                    placeholder="Field Name"
                    value={field.name}
                    onChange={(e) =>
                      setSoapData((prev) =>
                        prev.map((item) =>
                          item.id === field.id
                            ? { ...item, name: e.target.value }
                            : item
                        )
                      )
                    }
                    disabled={!canEditNote}
                    className="block w-full rounded-md border-gray-300"
                  />
                  <ReactQuill
                    value={field.value}
                    onChange={(content) =>
                      setSoapData((prev) =>
                        prev.map((item) =>
                          item.id === field.id
                            ? { ...item, value: content }
                            : item
                        )
                      )
                    }
                    readOnly={!canEditNote}
                    theme="snow"
                    className="w-full"
                  />
                </div>
              ))
            : Object.entries(structuredData).map(([key, value]) => (
                <div key={key} className="mb-6">
                  <label className="block text-sm font-semibold text-gray-900 mb-2">
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </label>
                  <ReactQuill
                    value={value}
                    onChange={(content) =>
                      setStructuredData((prev) => ({
                        ...prev,
                        [key]: content,
                      }))
                    }
                    readOnly={!canEditNote}
                    theme="snow"
                    className="w-full"
                  />
                </div>
              ))}
          {/* {isFreeform
            ? soapData.map((field, index) => (
                <div className="mb-6 flex gap-2 items-center" key={field.id}>
                  <input
                    type="text"
                    placeholder={`Field Name ${index + 1}`}
                    value={field.name}
                    onChange={(e) =>
                      setSoapData((prev) =>
                        prev.map((item) =>
                          item.id === field.id
                            ? { ...item, name: e.target.value }
                            : item
                        )
                      )
                    }
                    disabled={!canEditNote}
                    className="block w-1/3 rounded-md border-gray-300"
                  />
                  <textarea
                    rows="2"
                    placeholder="Field Value"
                    value={field.value}
                    onChange={(e) =>
                      setSoapData((prev) =>
                        prev.map((item) =>
                          item.id === field.id
                            ? { ...item, value: e.target.value }
                            : item
                        )
                      )
                    }
                    disabled={!canEditNote}
                    className="block w-2/3 rounded-md border-gray-300"
                  />
                </div>
              ))
            : Object.entries(structuredData).map(([key, value]) => (
                <div key={key} className="mb-6">
                  <label className="block text-sm font-semibold text-gray-900">
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </label>
                  <textarea
                    rows="4"
                    value={value}
                    onChange={(e) =>
                      setStructuredData((prev) => ({
                        ...prev,
                        [key]: e.target.value,
                      }))
                    }
                    disabled={!canEditNote}
                    className="block w-full rounded-md border-gray-300"
                  />
                </div>
              ))} */}

          {isFreeform && canEditNote && !appointment.soapNoteCompleted && (
            <button
              type="button"
              onClick={handleAddField}
              className="mb-4 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500">
              Add Field
            </button>
          )}

          {renderButtons()}
        </form>
      </div>
    </div>
  );
};

export default SOAPForm;
