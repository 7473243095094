import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPayments } from "../../store/thunks/paymentsThunk";
import SearchDropdown from "../../applicationUi/components/SearchDropdown";
import InvoicePaymentModal from "../../modals/InvoicePaymentModal";
import Table from "../../applicationUi/components/Table";
import QuickBooksLogo from "../../assets/quickbooks-1.svg";

const PaymentsPage = () => {
  const dispatch = useDispatch();

  // Safeguard Redux state with null-safe defaults
  const payments = useSelector((state) => state.payments?.payments || []);
  const isLoading = useSelector((state) => state.payments?.status === "loading");
  const currentPage = useSelector((state) => state.payments?.currentPage || 1);
  const totalPages = useSelector((state) => state.payments?.totalPages || 1);
  const totalPayments = useSelector((state) => state.payments?.totalPayments || 0);
  const error = useSelector((state) => state.payments?.error || null);

  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  // Logging for debugging
  console.log("currentPage", currentPage);
  console.log("totalPages", totalPages);
  console.log("totalPayments", totalPayments);
  console.log("payments", payments);

  useEffect(() => {
    dispatch(fetchAllPayments({ page: currentPage }));
  }, [dispatch, currentPage]);

  const handleRowClick = (clickedPaymentId) => {
    const payment = payments.find((pay) => pay?._id === clickedPaymentId);
    if (payment) {
      setSelectedPayment(payment);
      setShowPaymentModal(true);
    }
  };

  const handleModalClose = () => {
    setShowPaymentModal(false);
    setSelectedPayment(null);
  };

  const handlePageChange = (newPage) => {
    dispatch(fetchAllPayments({ page: newPage }));
  };

  if (error) {
    return <div>Error: {typeof error === "string" ? error : "An unknown error occurred."}</div>;
  }

  return (
    <div>
      {isLoading ? (
        <p>Loading payments...</p>
      ) : (
        <div>
          <div className="py-4">
            <SearchDropdown
              prefix=":payment:"
              displayFields={[
                "invoiceId.invoiceId",
                "paymentMethod",
                "amount",
                "invoiceId.client.fullName",
              ]}
              onSelection={(selectedPayment) => {
                setSelectedPayment(selectedPayment);
                setShowPaymentModal(true);
              }}
              placeholder={
                "Search for a payment by invoice ID, client name, or payment method"
              }
            />
          </div>

          <Table
            columns={[
              {
                header: "Invoice ID",
                accessor: (row) => row?.invoiceId?.invoiceId || "N/A",
              },
              {
                header: "Client Name",
                accessor: (row) =>
                  row?.invoiceId?.client
                    ? `${row.invoiceId.client.firstName} ${row.invoiceId.client.lastName}`
                    : "N/A",
              },
              {
                header: "Amount",
                accessor: (row) => `$${row?.amount?.toFixed(2) || "0.00"}`,
              },
              {
                header: "Payment Date",
                accessor: (row) => {
                  if (!row?.paymentDate) return "N/A";
                  const date = new Date(row.paymentDate);
                  return date.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  });
                },
              },
              {
                header: "Payment Method",
                accessor: (row) => row?.paymentMethod || "N/A",
              },
              {
                header: "QuickBooks",
                accessor: (row) => (
                  row?.QBOPaymentId ? (
                    <img
                      src={QuickBooksLogo}
                      alt="QuickBooks"
                      className="ml-2 h-5 w-5"
                      title="Loaded to QuickBooks"
                    />
                  ) : null
                ),
              },
            ]}
            data={payments}
            onRowClick={handleRowClick}
            title="Payments"
            description={`List of all payments (${totalPayments} total)`}
            showPagination={true}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />

          {showPaymentModal && selectedPayment && (
            <InvoicePaymentModal
              payment={selectedPayment || {}}
              onClose={handleModalClose}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentsPage;
