import React, { useEffect, useState, useMemo } from "react";
import AddUser from "./AddUser";
import Table from "../../applicationUi/components/Table";
import { fetchUsers, fetchUserById } from "../../store/thunks/userThunk";
import { useDispatch, useSelector } from "react-redux";
import UserDetails from "./UserDetails";
import { logError, logInfo } from "../../utils/logger";

function HRPage({ clickedItem }) {
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditUserOpen, setIsEditUserOpen] = useState(false);

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users || []);

  console.log("users", users);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleUserAdded = () => {
    dispatch(fetchUsers());
    setIsAddUserOpen(false);
  };

  useEffect(() => {
    logInfo("HRPage component rendered", {
      componentName: "HRPage",
      usersCount: users.length,
      isAddUserOpen: isAddUserOpen,
      isEditUserOpen: isEditUserOpen,
      selectedUserId: selectedUser?._id,
    });
  }, [users, isAddUserOpen, isEditUserOpen, selectedUser]);

  useEffect(() => {
    if (clickedItem && clickedItem._id) {
      logInfo("Fetching specific user", { userId: clickedItem._id });
      dispatch(fetchUserById(clickedItem._id))
        .unwrap()
        .then((user) => {
          logInfo("Specific user fetched successfully", {
            userId: user._id,
          });
          setSelectedUser(user);
        })
        .catch((error) => {
          logError("Failed to fetch specific user", {
            error,
            userId: clickedItem._id,
          });
          console.error("Failed to fetch specific user:", error);
        });
    } else {
      logInfo("Fetching users");
      dispatch(fetchUsers())
        .unwrap()
        .then(() => {
          logInfo("Users fetched successfully", {
            usersCount: users.length,
          });
        })
        .catch((error) => {
          logError("Failed to fetch users", { error });
          console.error("Failed to fetch users:", error);
        });
    }
  }, [dispatch, clickedItem]);

  const openAddUser = () => setIsAddUserOpen(true);
  const closeAddUser = () => setIsAddUserOpen(false);

  const openEditUser = (user) => {
    setSelectedUser(user);
    setIsEditUserOpen(true);
  };

  const closeEditUser = () => {
    setIsEditUserOpen(false);
    setSelectedUser(null);
  };

  const handleUserClick = (userId) => {
    console.log("Clicked user ID", userId);
    const user = users.find((u) => u._id === userId);
    if (user) {
      openEditUser(user);
    }
  };

  const columns = useMemo(
    () => [
      { header: "First Name", accessor: "firstName" },
      { header: "Last Name", accessor: "lastName" },
      { header: "Role", accessor: "role" },
      { header: "Email", accessor: "email" },
      { header: "Phone", accessor: "phone" },
      { header: "Clinic Name", accessor: "defaultLocation.name" },
      { header: "Qualifications", accessor: "qualifications" },
    ],
    []
  );

  // Filter users by their status
  const activeUsers = useMemo(
    () => users.filter((user) => user.userStatus === "active"),
    [users]
  );

  const inactiveUsers = useMemo(
    () => users.filter((user) => user.userStatus === "inactive"),
    [users]
  );

  return (
    <>
      {selectedUser ? (
        <UserDetails user={selectedUser} onClose={closeEditUser} />
      ) : (
        <div>
          <div className="mb-10"></div>
          {!isAddUserOpen && !isEditUserOpen && (
            <>
              <Table
                columns={columns}
                data={activeUsers}
                onRowClick={handleUserClick}
                actions={[
                  {
                    label: "Add User",
                    onClick: openAddUser,
                  },
                ]}
                title="Active Users"
                description="A list of all active users connected to your account."
              />
              <div className="py-6">
                <Table
                  columns={columns}
                  data={inactiveUsers}
                  onRowClick={handleUserClick}
                  title="Inactive Users"
                  description="A list of all inactive users connected to your account."
                />
              </div>
            </>
          )}
        </div>
      )}

      {isAddUserOpen && (
        <AddUser onClose={closeAddUser} onUserAdded={handleUserAdded} />
      )}
    </>
  );
}

export default HRPage;
