import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClinicServices, deleteClinicService } from '../../store/thunks/clinicThunk';
import Table from '../../applicationUi/components/Table';
import CircularButton from '../../applicationUi/components/CircularButton';
import AddServiceToClinicModal from './AddServiceToClinicModal';
import { logInfo, logError } from '../../utils/logger';

export default function ClinicServices({ clinic, onUpdateClinic }) {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingService, setEditingService] = useState(null);
    const { services, status, error } = useSelector((state) => state.clinics.selectedClinic);

    useEffect(() => {
        if (clinic?._id) {
            dispatch(fetchClinicServices(clinic._id))
                .unwrap()
                .then(() => {
                    logInfo('Clinic services fetched successfully', { clinicId: clinic._id });
                })
                .catch((error) => {
                    logError('Failed to fetch clinic services', { error, clinicId: clinic._id });
                });
        }
    }, [dispatch, clinic]);

    const handleDeleteService = (serviceId) => {
        if (window.confirm('Are you sure you want to delete this service?')) {
            dispatch(deleteClinicService({ clinicId: clinic._id, serviceId }))
                .unwrap()
                .then(() => {
                    logInfo('Service deleted successfully', { clinicId: clinic._id, serviceId });
                })
                .catch((error) => {
                    logError('Failed to delete service', { error, clinicId: clinic._id, serviceId });
                });
        }
    };

    const columns = [
        { header: 'Name', accessor: 'name' },
        { header: 'Description', accessor: 'description' },
        { header: 'Duration (minutes)', accessor: 'duration' },
        { header: 'Price ($)', accessor: 'price' },
        {
            header: 'Actions',
            accessor: '_id',
            Cell: ({ value, row }) => (
                <div>
                    <CircularButton onClick={() => { setEditingService(row.original); setIsModalOpen(true); }} variant="secondary">
                        Edit
                    </CircularButton>
                    <CircularButton onClick={() => handleDeleteService(value)} variant="danger">
                        Delete
                    </CircularButton>
                </div>
            ),
        },
    ];

    if (status === 'loading') {
        return <div>Loading services...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Clinic Services</h2>
                <CircularButton onClick={() => setIsModalOpen(true)}>Add Service</CircularButton>
            </div>
            <Table 
                columns={[
                    ...columns.slice(0, -1),
                    {
                        header: 'Actions',
                        accessor: '_id',
                        Cell: ({ value, row }) => (
                            <div>
                                <CircularButton onClick={() => { setEditingService(row.original); setIsModalOpen(true); }} variant="secondary">
                                    Edit
                                </CircularButton>
                                <CircularButton onClick={() => handleDeleteService(value)} variant="danger">
                                    Delete
                                </CircularButton>
                            </div>
                        ),
                    },
                ]} 
                data={services || []} 
            />
            <AddServiceToClinicModal
                isOpen={isModalOpen}
                onClose={() => { setIsModalOpen(false); setEditingService(null); }}
                clinicId={clinic._id}
                editingService={editingService}
            />
        </div>
    );
}
