import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

// Fetch QuickBooks connection status
export const fetchQboConnectionStatus = createAsyncThunk(
  "qbo/fetchConnectionStatus",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(`${baseURL}/api/qbConnected`, config);
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Start QuickBooks OAuth connection
export const startQboOAuth = createAsyncThunk(
  "qbo/startOAuth",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(`${baseURL}/api/startQuickBooksOAuth`, config);

      if (response.data?.authUrl) {
        window.location.href = response.data.authUrl; // Redirect to QuickBooks OAuth URL
        return { status: "redirecting" }; // Optional return for tracking
      } else {
        throw new Error("Auth URL not provided in response");
      }
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Disconnect QuickBooks
export const disconnectQbo = createAsyncThunk(
  "qbo/disconnect",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(`${baseURL}/api/revokeQboConnection`, config);
      return response.data; // Return success response if needed
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Fetch QuickBooks data (example: fetch invoices)
export const fetchQboInvoices = createAsyncThunk(
  "qbo/fetchInvoices",
  async ({ page = 1, limit = 10 }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/qbInvoices?page=${page}&limit=${limit}`,
        config
      );
      return {
        invoices: response.data.invoices,
        pagination: response.data.pagination,
      };
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);
