import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { XMarkIcon } from '@heroicons/react/24/outline';
import SearchDropdown from '../applicationUi/components/SearchDropdown';
import { updateClient } from '../store/thunks/clientsThunk';
import { fetchClinicians } from '../store/thunks/cliniciansThunk';

export default function SetCliniciansModal({ message, onClose, client }) {
  console.log("this is the client", client);
  const dispatch = useDispatch();
  const [treatingClinician, setTreatingClinician] = useState(client?.treatingClinician?.current || null);
  const [supervisingClinician, setSupervisingClinician] = useState(client?.supervisingClinician?.current || null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate that both clinicians are selected
    if (!treatingClinician || !supervisingClinician) {
      alert('Please select both treating and supervising clinicians');
      return;
    }

    try {
      // Update client with new clinician assignments
      await dispatch(updateClient({
        id: client._id,
        clientData: {
          treatingClinician: {
            current: treatingClinician._id
          },
          supervisingClinician: {
            current: supervisingClinician._id
          }
        }
      }));

      onClose();
    } catch (error) {
      console.error('Error updating clinicians:', error);
      alert('Failed to update clinicians. Please try again.');
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center px-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <div className="absolute right-0 top-0 pr-4 pt-4">
            <button
              onClick={onClose}
              className="rounded-md bg-white text-gray-400 hover:text-gray-500"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>

          <div className="sm:flex sm:items-start">
            <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
              <h3 className="text-lg font-semibold leading-6 text-gray-900 mb-4">
                Set Client Clinicians
              </h3>

              {message && (
                <div className="mb-4 text-sm text-gray-600">
                  {message}
                </div>
              )}

              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Treating Clinician
                  </label>
                  <SearchDropdown
                    prefix=":user:"
                    displayFields={["firstName", "lastName", "email"]}
                    onSelection={(user) => setTreatingClinician(user)}
                    placeholder={"Search for a treating clinician"}
                    selectedValue={treatingClinician}
                  />
                  {treatingClinician && (
                    <div className="mt-1 text-base text-red-500">
                      Selected Treating Clinician: {treatingClinician.firstName} {treatingClinician.lastName}
                    </div>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Supervising Clinician
                  </label>
                  <SearchDropdown
                    prefix=":user:"
                    displayFields={["firstName", "lastName", "email"]}
                    onSelection={(user) => setSupervisingClinician(user)}
                    placeholder={"Search for a supervising clinician"}
                    selectedValue={supervisingClinician}
                  />
                  {supervisingClinician && (
                    <div className="mt-1 text-base text-red-500">
                      Selected Supervising Clinician: {supervisingClinician.firstName} {supervisingClinician.lastName}
                    </div>
                  )}
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                  >
                    Save Changes
                  </button>
                  <button
                    type="button"
                    onClick={onClose}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
