import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUploads,
  fetchUploadsWithDetails,
  fetchFile,
  syncUploads,
  checkFilePassword,
  recordPasswordAccess,
} from "../../store/thunks/uploadsThunk";
import FileExplorer from "../../applicationUi/components/FileExplorer";

export default function ClientUploads({ client, clientId }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const actualClientId = client?._id || clientId;
  const [revealedPasswordFiles, setRevealedPasswordFiles] = useState({});
  const [uploadsWithDetails, setUploadsWithDetails] = useState([]); // Store the files as an array
  const [existingFolders, setExistingFolders] = useState([]); // Existing folder state

  useEffect(() => {
    if (actualClientId) {
      dispatch(fetchUploads(actualClientId))
        .unwrap()
        .then((result) => {
          console.log("fetchUploads IN UPLOADS FROM THUNK:", result);
        })
        .catch((error) => {
          console.error("Failed to fetch uploads:", error);
        });
    }
  }, [dispatch, actualClientId]);

  useEffect(() => {
    if (actualClientId) {
      // Dispatch the syncUploads thunk first to ensure the database and filesystem are in sync
      dispatch(syncUploads(actualClientId))
        .unwrap()
        .then(() => {
          // Once sync is complete, fetch uploads with details
          dispatch(fetchUploadsWithDetails(actualClientId))
            .unwrap()
            .then((result) => {
              console.log(
                "fetchUploadsWithDetails IN UPLOADS FROM THUNK:",
                result
              );
              if (result?.files && Array.isArray(result.files)) {
                setUploadsWithDetails(result.files);

                // Extract folder names from the file paths and set state
                const folderSet = new Set();
                result.files.forEach((file) => {
                  const folderName = file.path.split("/")[0];
                  folderSet.add(folderName);
                });
                setExistingFolders(Array.from(folderSet));
              }
            })
            .catch((error) => {
              console.error("Failed to fetch uploads with details:", error);
            });
        })
        .catch((error) => {
          console.error("Failed to sync uploads:", error);
        });
    }
  }, [dispatch, actualClientId]);

  const handleFileClick = (fileName) => {
    const sanitizedFileName = fileName.replace(/^\//, "");

    dispatch(
      fetchFile({ clientId: actualClientId, fileName: sanitizedFileName })
    )
      .unwrap()
      .then((result) => {
        const url = URL.createObjectURL(
          new Blob([result.fileBlob], { type: result.fileType })
        );

        const newTab = window.open(url, "_blank");
        if (newTab) {
          newTab.document.write(
            `<iframe src="${url}" style="width:100%; height:100%;" frameborder="0"></iframe>`
          );
          URL.revokeObjectURL(url);
        }
      })
      .catch((err) => {
        console.error("Failed to fetch file:", err);
      });
  };

  const renderFiles = (files) => {
    if (!files || files.length === 0) {
      return <p>No files found.</p>;
    }

    const fileTree = {};

    files.forEach((file) => {
      const parts = file.path.split("/");
      let current = fileTree;

      parts.forEach((part, index) => {
        if (!current[part]) {
          current[part] = {
            name: part,
            isPasswordProtected: file.isPasswordProtected || false,
            password: file.isPasswordProtected ? file.password : null,
          };
        }
        current = current[part];
      });
    });

    return (
      <FileExplorer
        files={uploadsWithDetails} // Now an array of file objects
        clientId={actualClientId}
        onFileClick={handleFileClick}
      />

      // <FileExplorer
      //   files={fileTree}
      //   clientId={actualClientId}
      //   onFileClick={handleFileClick}
      //   onPasswordRequired={handlePasswordRequired}
      //   onRevealPassword={handleRevealPassword}
      //   revealedPasswordFiles={revealedPasswordFiles}
      // />
    );
  };

  const handleRevealPassword = (fileName) => {
    const file = uploadsWithDetails.find((file) => file.path === fileName);
    if (file && file.isPasswordProtected) {
      setRevealedPasswordFiles((prevState) => ({
        ...prevState,
        [fileName]: file.password,
      }));
    } else {
      console.error(
        "Failed to retrieve password or file is not password protected"
      );
    }
  };

  const handlePasswordRequired = (fileName) => {
    const password = prompt("Enter password to view file:");
    if (password) {
      dispatch(
        checkFilePassword({ clientId: actualClientId, fileName, password })
      )
        .unwrap()
        .then((result) => {
          if (result.success) {
            handleFileClick(fileName);
            recordPasswordView(fileName);
          } else {
            alert("Incorrect password");
          }
        })
        .catch((err) => {
          console.error("Failed to check password:", err);
        });
    }
  };

  const recordPasswordView = (fileName) => {
    const userId = user._id;
    dispatch(
      recordPasswordAccess({ clientId: actualClientId, fileName, userId })
    );
  };

  return (
    <div>
      <h3 className="text-sm font-medium text-gray-700 pb-4">
        Uploaded Documents
      </h3>
      {uploadsWithDetails.length === 0 ? (
        <p className="text-sm font-medium text-gray-700">No Documents Exist...</p>
      ) : (
        renderFiles(uploadsWithDetails)
      )}
    </div>
  );
}
