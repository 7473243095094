import TextAreaField from "../../applicationUi/components/intake/TextAreaField";

const PostAppointmentSection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold">I - Post Appointment Expectations</h2>
    <TextAreaField
      label="What are you hoping we will be able to provide to you after this appointment with us?"
      name="postAppointmentExpectations"
      value={formData.postAppointmentExpectations}
      onChange={handleChange}
      placeholder="e.g. recommendation for home management, service at this clinic, referral to another agency"
    />
  </section>
);

export default PostAppointmentSection;
