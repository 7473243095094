import InputField from "../../applicationUi/components/intake/InputField";

const SiblingsSection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold">Siblings</h2>
    <InputField
      label="Siblings (Names & Ages)"
      name="siblings"
      value={formData.siblings}
      onChange={handleChange}
    />
  </section>
);

export default SiblingsSection;
