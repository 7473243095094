const RadioGroup = ({ label, name, options, selectedValue, onChange }) => (
  <div className="pb-4">
    <label className="block text-gray-700">{label}</label>
    <div className="flex space-x-4">
      {options.map((option) => (
        <label key={option.value} className="flex items-center">
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={onChange}
            className="mr-2"
          />
          {option.label}
        </label>
      ))}
    </div>
  </div>
);
export default RadioGroup;
