import { useSelector } from "react-redux";
import { calculateAge } from "../../../utils/calculateAge";

export const AppointmentClientInfo = ({ title = "Client" }) => {
  const selectedAppointment = useSelector(
    (state) => state.appointments.selectedAppointment
  );
  const client = selectedAppointment?.client || {};
  const address = client?.address || {};

  // console.log("client in apptclientinfo", client);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      timeZone: "UTC", // Force UTC time zone to avoid local time zone shifts
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <>
      <div className="border border-gray-200 rounded-md px-4 py-4">
        <div className="mt-2">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Client Info
          </h3>
          <dl className={`grid gap-x-2  grid-cols-1 sm:grid-cols-3`}>
            <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Client Name
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">{`${selectedAppointment.client?.firstName} ${selectedAppointment.client?.lastName}`}</dd>
            </div>
            <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Email
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                {selectedAppointment.client?.email}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Phone Numbers
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                {selectedAppointment.client?.phoneNumbers &&
                selectedAppointment.client?.phoneNumbers.length > 0
                  ? selectedAppointment.client?.phoneNumbers.map((phone) => (
                      <div key={phone._id}>
                        {phone.number} ({phone.type})
                      </div>
                    ))
                  : "N/A"}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Date of Birth
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                {formatDate(selectedAppointment.client?.dateOfBirth)}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Age
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                {calculateAge(selectedAppointment.client?.dateOfBirth)}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};
