import InputField from "../../applicationUi/components/intake/InputField";
import SelectField from "../../applicationUi/components/intake/SelectField";

const ClientInformationSection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold">Client Information</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <InputField
        label="First Name"
        name="client.firstName"
        value={formData.client.firstName}
        onChange={handleChange}
      />
      <InputField
        label="Last Name"
        name="client.lastName"
        value={formData.client.lastName}
        onChange={handleChange}
      />
      <SelectField
        label="Gender"
        name="client.gender"
        value={formData.client.gender}
        onChange={handleChange}
        options={[
          { value: "", label: "Select Gender" },
          { value: "male", label: "Male" },
          { value: "female", label: "Female" },
          { value: "transgender", label: "Transgender" },
          { value: "genderqueer", label: "Genderqueer" },
          { value: "non-binary", label: "Non-binary" },
          { value: "agender", label: "Agender" },
          { value: "bigender", label: "Bigender" },
          { value: "other", label: "Other" },
        ]}
      />
      <SelectField
        label="Pronouns"
        name="client.pronouns"
        value={formData.client.pronouns}
        onChange={handleChange}
        options={[
          { value: "", label: "Select Pronouns" },
          { value: "he/him", label: "He/Him" },
          { value: "she/her", label: "She/Her" },
          { value: "they/them", label: "They/Them" },
          { value: "xe/xem", label: "Xe/Xem" },
          { value: "ze/zir", label: "Ze/Zir" },
          { value: "fae/faer", label: "Fae/Faer" },
          { value: "ey/em", label: "Ey/Em" },
          { value: "ve/ver", label: "Ve/Ver" },
          { value: "he/they", label: "He/They" },
          { value: "she/they", label: "She/They" },
          { value: "other", label: "Other" },
        ]}
      />
      <InputField
        label="Date of Birth"
        name="client.dob"
        value={formData.client.dob}
        type="date"
        onChange={handleChange}
      />
      <InputField
        label="Doctor's Name"
        name="client.doctorName"
        value={formData.client.doctorName}
        onChange={handleChange}
      />
      <InputField
        label="Doctor's Phone"
        name="client.doctorPhone"
        value={formData.client.doctorPhone}
        onChange={handleChange}
      />
      <InputField
        label="Referral Source"
        name="client.referralSource"
        value={formData.client.referralSource}
        onChange={handleChange}
      />
      <InputField
        label="Referral Reason"
        name="client.referralReason"
        value={formData.client.referralReason}
        onChange={handleChange}
      />
    </div>

    {/* Address Section */}
    <div className="mt-10">
      <h3 className="text-md font-semibold mb-4">Client Address Information</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <InputField
          label="Apartment/Unit"
          name="client.address.aptUnit"
          value={formData.client.address.aptUnit}
          onChange={handleChange}
        />
        <InputField
          label="Street Number"
          name="client.address.streetNumber"
          value={formData.client.address.streetNumber}
          onChange={handleChange}
        />
        <InputField
          label="Street Name"
          name="client.address.streetName"
          value={formData.client.address.streetName}
          onChange={handleChange}
        />
        <InputField
          label="City"
          name="client.address.city"
          value={formData.client.address.city}
          onChange={handleChange}
        />
        <InputField
          label="Province"
          name="client.address.province"
          value={formData.client.address.province}
          onChange={handleChange}
        />
        <InputField
          label="Postal Code"
          name="client.address.postalCode"
          value={formData.client.address.postalCode}
          onChange={handleChange}
        />
        <InputField
          label="Country"
          name="client.address.country"
          value={formData.client.address.country}
          onChange={handleChange}
        />
      </div>
    </div>
  </section>
);

export default ClientInformationSection;
