import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import CurrentDateDisplay from "./scheduleComponents/currentDateDisplay";
import CurrentTime from "./scheduleComponents/currentTime";
import DateNavigator from "./scheduleComponents/dateNavigator";
import DurationSelector from "./scheduleComponents/durationSelector";
import { fetchCliniciansByClinicId } from "../../store/thunks/cliniciansThunk";
import {
  createAppointment,
  fetchAppointmentsByDate,
} from "../../store/thunks/appointmentsThunk";
import { fetchMeetingsByDate } from "../../store/thunks/meetingsThunk";
import ClinicianSchedule from "./scheduleComponents/clinicianSchedule";
import { Switch } from "@headlessui/react";
import { fetchClinics } from "../../store/thunks/clinicThunk";
import MultiSelect from "../../applicationUi/components/MultiSelect";
import { fetchNotes } from "../../store/thunks/notesThunk";
import {
  clearAppointmentUpdated,
  clearSelectedAppointment,
  setSelectedAppointment,
} from "../../store/slices/appointmentsSlice";
import { clearCurrentInvoice } from "../../store/slices/invoiceSlice";
import {
  setSelectedClinic,
  clearSelectedClinic,
} from "../../store/slices/clinicSlice";
import AppointmentSummary from "../../applicationUi/components/appointments/AppointmentSummary";
import { resetMeetingUpdated } from "../../store/slices/meetingSlice";
import { clearAppointmentPayments, clearInvoicePayments } from "../../store/slices/paymentsSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ClinicScheduleModal({
  setModalOpen,
  onTimeSlotSelect,
  selectedClient,
  selectedService,
  selectedProduct,
  selectedClinician,
  rescheduleData, // Ensure this prop is being passed correctly
}) {
  const dispatch = useDispatch();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [showDurationSelector, setShowDurationSelector] = useState(false);
  const [selectorPosition, setSelectorPosition] = useState({ x: 0, y: 0 });
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [showWorkingHours, setShowWorkingHours] = useState(true);
  const [isWideSidePanelOpen, setIsWideSidePanelOpen] = useState(false);
  const [rescheduleDataState, setRescheduleDataState] =
    useState(rescheduleData);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [showSLP, setShowSLP] = useState(false);
  const [showCDA, setShowCDA] = useState(false);
  const [selectedClinicians, setSelectedClinicians] = useState([]);
  const container = useRef(null);

  const appointments = useSelector(
    (state) => state.appointments.scheduleAppointments || []
  );
  const user = useSelector((state) => state.auth.user || []);
  const clinics = useSelector((state) => state.clinics.clinics || []);
  const clinicians = useSelector((state) => state.clinicians.clinicians || []);
  const notes = useSelector((state) => state.notes.notes || []);
  const meetings = useSelector((state) => state.meetings.meetings || []);
  const selectedAppointment = useSelector(
    (state) => state.appointments.selectedAppointment
  );
  const selectedClinic = useSelector((state) => state.clinics.selectedClinic);
  const appointmentUpdated = useSelector(
    (state) => state.appointments.appointmentUpdated
  );
  const meetingUpdated = useSelector((state) => state.meetings.meetingUpdated);

  useEffect(() => {
    dispatch(fetchClinics());
  }, [dispatch]);

  useEffect(() => {
    if (selectedClinic) {
      dispatch(fetchCliniciansByClinicId(selectedClinic._id));
      dispatch(
        fetchAppointmentsByDate({
          date: currentDate,
          clinicId: selectedClinic._id,
        })
      );
      dispatch(
        fetchMeetingsByDate({ date: currentDate, clinicId: selectedClinic._id })
      );
      dispatch(fetchNotes(currentDate));
      dispatch(clearAppointmentUpdated());
      dispatch(resetMeetingUpdated());
    }
  }, [
    dispatch,
    selectedClinic,
    currentDate,
    appointmentUpdated,
    meetingUpdated,
    isWideSidePanelOpen,
  ]);

  const handleNoteUpdate = useCallback(() => {
    dispatch(fetchNotes(currentDate));
  }, [dispatch, currentDate]);

  const handleNoteDelete = useCallback(() => {
    dispatch(fetchNotes(currentDate));
  }, [dispatch, currentDate]);

  const getClinicianDailyNote = useCallback(
    (clinicianId) => {
      if (!Array.isArray(notes) || notes.length === 0) {
        return ""; // Return empty string if notes is not an array or has no notes
      }

      const clinicianNote = notes.find(
        (item) => item.clinicianId === clinicianId
      );
      console.log("clinicianNote", clinicianNote);
      // Return the note or an empty string
      return clinicianNote ? clinicianNote.notes[0] : "";
    },
    [notes]
  );

  const handleSelectClinician = (selectedClinicianIds) => {
    setSelectedClinicians(selectedClinicianIds);
    console.log("Selected Clinicians:", selectedClinicianIds);
  };

  const onPrevDay = () => {
    setCurrentDate(
      (prevDate) =>
        new Date(
          prevDate.getFullYear(),
          prevDate.getMonth(),
          prevDate.getDate() - 1
        )
    );
  };

  const onToday = () => {
    setCurrentDate(new Date());
  };

  const onNextDay = () => {
    setCurrentDate(
      (prevDate) =>
        new Date(
          prevDate.getFullYear(),
          prevDate.getMonth(),
          prevDate.getDate() + 1
        )
    );
    // refetchAppointments(); // Ensure appointments are refreshed
  };

  const onPrevWeek = () => {
    setCurrentDate(
      (prevDate) =>
        new Date(
          prevDate.getFullYear(),
          prevDate.getMonth(),
          prevDate.getDate() - 7
        )
    );
    // refetchAppointments(); // Ensure appointments are refreshed
  };

  const onNextWeek = () => {
    setCurrentDate(
      (prevDate) =>
        new Date(
          prevDate.getFullYear(),
          prevDate.getMonth(),
          prevDate.getDate() + 7
        )
    );
    // refetchAppointments(); // Ensure appointments are refreshed
  };

  const onDateChange = (date) => {
    setCurrentDate(date);
    // refetchAppointments(); // Ensure appointments are refreshed
  };

  // EXISTING AND FUNCTIONAL HANDLE DURATION SELECT
  const handleDurationSelect = (duration) => {
    setShowDurationSelector(false);
    const title = rescheduleDataState
      ? rescheduleDataState.title
      : prompt("Enter the appointment title:");
    const patientName = rescheduleDataState
      ? `${rescheduleDataState.client.firstName} ${rescheduleDataState.client.lastName}`
      : prompt("Enter the patient's name:");
    if (title && patientName) {
      const startTime = selectedTimeSlot;
      const endTime = calculateEndTime(startTime, parseInt(duration));
      const newAppointment = {
        ...rescheduleDataState,
        clinic: selectedClinician.clinic,
        clinician: selectedClinician._id,
        client: selectedClient._id,
        title,
        start: new Date(
          `${currentDate.toDateString()} ${startTime}`
        ).toISOString(),
        end: new Date(`${currentDate.toDateString()} ${endTime}`).toISOString(),
        service: selectedService._id,
        status: ["Booked"],
        history: [
          {
            status: "Booked",
            comments: `Created for ${patientName}`,
            user: user._id,
          },
          ...(rescheduleDataState
            ? [
                {
                  status: "Changed",
                  comments: "Rescheduled",
                  timestamp: new Date().toISOString(),
                  user: user._id,
                },
              ]
            : []),
        ],
      };
      console.log("New Appointment Created:", newAppointment);
      dispatch(createAppointment(newAppointment)).then(() => {
        // refetchAppointments();
      });
    }
    setRescheduleDataState(null);
  };

  const calculateEndTime = (startTime, duration) => {
    const [hour, minute] = startTime.split(":").map(Number);
    const time = new Date();
    time.setHours(hour, minute + duration, 0);
    return `${time.getHours()}:${time
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  };

  const handleCancel = () => {
    setShowDurationSelector(false);
  };

  const handleMeetingUpdate = () => {
    dispatch(
      fetchMeetingsByDate({ date: currentDate, clinicId: selectedClinic._id })
    );
  };

  const handleAppointmentClick = (appointment) => {
    console.log("Appointment before dispatch:", appointment); // Validate here
    dispatch(setSelectedAppointment(appointment));
    setIsWideSidePanelOpen(true);
  };

  const handleCloseWideSidePanel = () => {
    setIsWideSidePanelOpen(false);
    console.log("WideSidePanel closed");
  };

  const handleReschedule = (appointment) => {
    console.log("handleReschedule called with appointment:", appointment);
    setRescheduleDataState(appointment);
    console.log("rescheduleDataState set to:", rescheduleDataState);
    // setIsWideSidePanelOpen(false);
    // setIsWideSidePanelOpen(true); // Close the side panel
    // console.log("isWideSidePanelOpen set to false");
    // setModalOpen(true);
    // console.log("setModalOpen called with true");
  };

  const filteredClinicians = useMemo(() => {
    const cliniciansToFilter =
      selectedClinicians.length > 0 ? selectedClinicians : clinicians;
    return cliniciansToFilter.filter((clinician) => {
      const nameMatch = `${clinician.firstName} ${clinician.lastName}`
        .toLowerCase()
        .includes(filterText.toLowerCase());
      const specializationMatch =
        (!showSLP && !showCDA) || // Show all when both are unchecked
        (showSLP && clinician.specialization.includes("SLP")) ||
        (showCDA && clinician.specialization.includes("CDA"));
      return nameMatch && specializationMatch;
    });
  }, [selectedClinicians, clinicians, filterText, showSLP, showCDA]);

  const handleScroll = (event) => {
    setScrollLeft(event.target.scrollLeft);
  };

  const handleClinicChange = (e) => {
    const clinic = clinics.find((c) => c._id === e.target.value);
    setSelectedClinic(clinic);
    // Fetch appointments for the new selected clinic
    dispatch(
      fetchAppointmentsByDate({
        date: currentDate,
        clinicId: clinic._id,
      })
    );
  };

  // console.log("meetings", meetings);

  return (
    <div className="flex h-full flex-col bg-gray-100">
      {showDurationSelector && (
        <DurationSelector
          x={selectorPosition.x}
          y={selectorPosition.y}
          onSelect={handleDurationSelect}
          onCancel={handleCancel}
        />
      )}
      {/* Header */}
      <header className="flex items-center justify-between border-b-2 border-gray-300 p-4">
        <div className="flex items-center">
          <select
            value={selectedClinic?._id || ""}
            onChange={handleClinicChange}
            className="mr-4 p-2 border rounded">
            {clinics.map((clinic) => (
              <option key={clinic._id} value={clinic._id}>
                {clinic.name}
              </option>
            ))}
          </select>
          <MultiSelect
            options={clinicians.map((clinician) => ({
              value: clinician._id,
              label: `${clinician.firstName} ${clinician.lastName}`,
            }))}
            selectedValues={selectedClinicians.map((clinician) => ({
              value: clinician._id,
              label: `${clinician.firstName} ${clinician.lastName}`,
            }))}
            onChange={(selectedList) => {
              const selectedClinicianObjects = selectedList
                .map((item) =>
                  clinicians.find((clinician) => clinician._id === item.value)
                )
                .filter(Boolean);
              handleSelectClinician(selectedClinicianObjects);
            }}
            placeholder="Select clinicians..."
            className="w-full"
          />
          <div className="flex items-center ml-4">
            <label className="inline-flex items-center mr-4">
              <input
                type="checkbox"
                checked={showSLP}
                onChange={() => setShowSLP(!showSLP)}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span className="ml-2 text-gray-700">SLP</span>
            </label>
            <label className="inline-flex items-center mr-4">
              <input
                type="checkbox"
                checked={showCDA}
                onChange={() => setShowCDA(!showCDA)}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span className="ml-2 text-gray-700">CDA</span>
            </label>
          </div>
          <div className="flex items-center pl-4">
            <Switch
              checked={showWorkingHours}
              onChange={setShowWorkingHours}
              className={classNames(
                showWorkingHours ? "bg-indigo-600" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
              )}>
              <span className="sr-only">Show working hours</span>
              <span
                className={classNames(
                  showWorkingHours ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
            <span className="ml-3 text-sm font-medium text-gray-900">
              Working Hours (8AM - 8PM)
            </span>
          </div>
        </div>

        <CurrentTime />
        <div className="flex items-center">
          <CurrentDateDisplay currentDate={currentDate} />
          <DateNavigator
            onPrevDay={onPrevDay}
            onToday={onToday}
            onNextDay={onNextDay}
            onPrevWeek={onPrevWeek}
            onNextWeek={onNextWeek}
            onDateChange={onDateChange}
          />
          <button
            className="ml-2 p-2 rounded-full hover:bg-gray-200 focus:outline-none"
            onClick={() => setModalOpen(false)}>
            <span className="sr-only">Close</span>
            &times;
          </button>
        </div>
      </header>

      <div className="overflow-auto" onScroll={handleScroll}>
        <div
          className="isolate flex flex-auto bg-white"
          style={{ minWidth: `${filteredClinicians.length * 320}px` }}>
          {filteredClinicians.map((clinician, index) => (
            <div
              ref={container}
              className="flex flex-auto flex-col min-w-80"
              key={index}>
              <ClinicianSchedule
                clinician={clinician}
                appointments={appointments}
                selectedClient={selectedClient}
                selectedService={selectedService}
                selectedClinician={selectedClinician}
                currentDate={currentDate}
                handleAppointmentClick={handleAppointmentClick}
                showWorkingHours={showWorkingHours}
                onReschedule={handleReschedule}
                rescheduleData={rescheduleData}
                scrollLeft={scrollLeft}
                clinic={selectedClinic}
                clinicWorkingHours={selectedClinic?.workingHours}
                dailyNote={getClinicianDailyNote(clinician._id)}
                handleNoteUpdate={handleNoteUpdate}
                handleNoteDelete={handleNoteDelete}
                meetings={meetings.filter((meeting) =>
                  meeting.attendees.some(
                    (attendee) => attendee._id === clinician._id
                  )
                )}
                handleMeetingUpdate={handleMeetingUpdate}
              />
            </div>
          ))}
        </div>
      </div>

      {selectedAppointment && (
        <AppointmentSummary
          title={"Appointment Summary"}
          open={isWideSidePanelOpen}
          onClose={handleCloseWideSidePanel}
          onReschedule={handleReschedule}
        />
      )}
    </div>
  );
}
