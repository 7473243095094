import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

// Helper to generate API config
const getApiConfig = (getState) => {
  const token = getState().auth.token;
  return generateConfig({ token });
};

// Fetch all meetings
export const fetchMeetings = createAsyncThunk(
  "meetings/fetchMeetings",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { baseURL, config } = getApiConfig(getState);
      const response = await axios.get(`${baseURL}/api/meetings`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Fetch meetings by date
export const fetchMeetingsByDate = createAsyncThunk(
  "meetings/fetchByDate",
  async ({ date, clinicId }, { rejectWithValue, getState }) => {
    try {
      const { baseURL, config } = getApiConfig(getState);
      const requestBody = { requestedDate: new Date(date).toISOString() };
      if (clinicId) requestBody.clinicId = clinicId;

      const response = await axios.post(
        `${baseURL}/api/meetings/date`,
        requestBody,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

// Fetch meetings for the current week
export const fetchMeetingsForCurrentWeek = createAsyncThunk(
  "meetings/fetchMeetingsForCurrentWeek",
  async ({ startDate, endDate }, { rejectWithValue, getState }) => {
    try {
      const { baseURL, config } = getApiConfig(getState);

      const response = await axios.get(`${baseURL}/api/meetings/week`, {
        ...config,
        params: { startDate, endDate },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Check for meeting conflicts
export const checkMeetingConflicts = createAsyncThunk(
  "meetings/checkConflicts",
  async (meetingData, { rejectWithValue, getState }) => {
    try {
      const { baseURL, config } = getApiConfig(getState);

      const response = await axios.post(
        `${baseURL}/api/meetings/conflict-check`,
        meetingData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "An error occurred while checking conflicts."
      );
    }
  }
);

// Create a new meeting
export const createMeeting = createAsyncThunk(
  "meetings/createMeeting",
  async (meetingData, { rejectWithValue, getState }) => {
    try {
      const { baseURL, config } = getApiConfig(getState);

      const response = await axios.post(
        `${baseURL}/api/meetings`,
        meetingData,
        config
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
// Update a meeting
export const updateMeetingThunk = createAsyncThunk(
  "meetings/updateMeeting",
  async ({ id, updatedData }, { rejectWithValue, getState }) => {
    try {
      const { baseURL, config } = getApiConfig(getState);
      const response = await axios.put(
        `${baseURL}/api/meetings/${id}`,
        updatedData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Delete a meeting
export const deleteMeetingThunk = createAsyncThunk(
  "meetings/deleteMeeting",
  async (id, { rejectWithValue, getState }) => {
    try {
      const { baseURL, config } = getApiConfig(getState);
      await axios.delete(`${baseURL}/api/meetings/${id}`, config);
      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Fetch a single meeting by ID
export const fetchMeetingById = createAsyncThunk(
  "meetings/fetchMeetingById",
  async (id, { rejectWithValue, getState }) => {
    try {
      const { baseURL, config } = getApiConfig(getState);
      const response = await axios.get(`${baseURL}/api/meetings/${id}`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
