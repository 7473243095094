import React, { useEffect } from "react";
import { PhoneIcon, PlusIcon, MinusIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementPhoneCall,
  decrementPhoneCall,
  fetchPhoneCalls,
} from "../../store/thunks/phoneCallThunk";

export default function IncrementButton() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { phoneCalls, loading, error } = useSelector(
    (state) => state.phoneCalls
  );

  const date = new Date().toISOString().split("T")[0];
  const numCallsToday = phoneCalls[date]?.count || 0;

  useEffect(() => {
    dispatch(fetchPhoneCalls(date));
  }, [date, dispatch]);

  const handleIncrement = async () => {
    await dispatch(incrementPhoneCall(user._id));
    dispatch(fetchPhoneCalls(date));
  };

  const handleDecrement = async () => {
    if (numCallsToday > 0) {
      await dispatch(decrementPhoneCall(user._id));
      dispatch(fetchPhoneCalls(date));
    }
  };

  return (
    <div
      className="flex items-center bg-indigo-600 p-2 text-white rounded-full shadow-sm hover:bg-indigo-500"
      title={`Number of received phone calls today: ${numCallsToday}`} // Tooltip text
    >
      <div className="flex items-center">
        <div onClick={handleDecrement} className="mr-1 cursor-pointer p-1">
          <MinusIcon className="h-4 w-4" />
        </div>
        <PhoneIcon className="h-5 w-5" />
        <span className="ml-1 mr-1">{loading ? "..." : numCallsToday}</span>
        <div onClick={handleIncrement} className="ml-1 cursor-pointer p-1">
          <PlusIcon className="h-4 w-4" />
        </div>
      </div>
    </div>
  );
}
