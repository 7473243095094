import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchClientJournal } from "../../store/thunks/clientJournalThunk";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import AppointmentSummary from "../../applicationUi/components/appointments/AppointmentSummary";
import { setSelectedAppointment } from "../../store/slices/appointmentsSlice";
import CondensedTable from "../../applicationUi/components/CondensedTable";

function ClientJournal({ client }) {
  const dispatch = useDispatch();
  const [journal, setJournal] = useState(null);
  const [status, setStatus] = useState("idle");
  const [activeSection, setActiveSection] = useState(null);
  const [sortOrder, setSortOrder] = useState("Newest first");
  const [isAppointmentSummaryOpen, setIsAppointmentSummaryOpen] =
    useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  useEffect(() => {
    if (client._id) {
      setStatus("loading");
      dispatch(fetchClientJournal(client._id))
        .then((response) => {
          setJournal(response.payload); // Assumes fetchClientJournal returns data in response.payload
          setStatus("succeeded");
        })
        .catch(() => setStatus("failed"));
    }
  }, [dispatch, client._id]);

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const formatDate = (date) =>
    new Date(date).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleAppointmentClick = (appointment) => {
    console.log("received this in handleAppointmentClick", appointment);
    setSelectedAppointment(appointment);
    setIsAppointmentSummaryOpen(true);
  };

  const handleCloseAppointmentSummary = () => {
    setSelectedAppointment(null);
    setIsAppointmentSummaryOpen(false);
  };

  const sortItems = (items, dateField) => {
    return [...items].sort((a, b) => {
      const dateA = new Date(a[dateField]);
      const dateB = new Date(b[dateField]);
      return sortOrder === "Oldest first" ? dateA - dateB : dateB - dateA;
    });
  };

  if (status === "loading") {
    return <p>Loading client journal...</p>;
  }

  if (status === "failed" || !journal) {
    return <p>No journal data available.</p>;
  }

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="flex flex-row justify-between">
        <h2 className="text-lg font-bold mb-4">
          Client Journal for {client.firstName} {client.lastName}
        </h2>

        {/* Sort Menu */}
        <div className="mb-4 flex items-center">
          <span className="text-sm font-medium text-gray-900 mr-2">
            Sort by
          </span>
          <select
            id="sortOrder"
            name="sortOrder"
            value={sortOrder}
            onChange={handleSortChange}
            className="rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm">
            <option>Oldest first</option>
            <option>Newest first</option>
          </select>
        </div>
      </div>

      <CondensedTable
        journalData={journal}
        sortOrder={sortOrder}
        onClick={handleAppointmentClick}
      />

      {/* Appointment Summary Modal */}
      {selectedAppointment && (
        <AppointmentSummary
          open={isAppointmentSummaryOpen}
          onClose={handleCloseAppointmentSummary}
          appointment={selectedAppointment}
          onReschedule={(appt) => console.log("Reschedule:", appt)}
        />
      )}
    </div>
  );
}

export default ClientJournal;
