import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

// Thunk to create a note
export const createNote = createAsyncThunk(
  "notes/createNote",
  async ({ content, date, clinicianId }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      // Proceed to create the note
      const response = await axios.post(
        `${baseURL}/api/notes`,
        { content, date, clinicianId },
        config
      );
      return response.data; // Adjust this according to your response structure
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

// Thunk to fetch notes
export const fetchNotes = createAsyncThunk(
  "notes/fetchNotes",
  async (currentDate, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const formattedDate = new Date(currentDate).toISOString(); // Format the input date to ISO8601
      const response = await axios.get(
        `${baseURL}/api/notes?date=${formattedDate}`,
        config
      );
      // console.log("response.data", response.data);

      return response.data || []; // Ensure we return an empty array if no notes exist
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

// Thunk to update a note
export const updateNote = createAsyncThunk(
  "notes/updateNote",
  async ({ id, content, date, clinicianId }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.put(
        `${baseURL}/api/notes/${id}`,
        { content, date, clinicianId },
        config
      );
      return response.data.note;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

// Thunk to delete a note
export const deleteNote = createAsyncThunk(
  "notes/deleteNote",
  async (noteId, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      await axios.delete(`${baseURL}/api/notes/${noteId}`, config);
      return noteId;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);
