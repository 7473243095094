import RadioGroup from "../../applicationUi/components/intake/RadioGroup";
import SelectField from "../../applicationUi/components/intake/SelectField";
import TextAreaField from "../../applicationUi/components/intake/TextAreaField";

const ArticulationSection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold">B - Articulation/Pronunciation</h2>

    {/* Difficulty Pronouncing Sounds/Words */}
    <RadioGroup
      label="Does your child have difficulty pronouncing sounds/words?"
      name="articulationConcerns.difficultyPronouncing"
      options={[
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
      ]}
      selectedValue={formData.articulationConcerns.difficultyPronouncing}
      onChange={handleChange}
    />

    {/* If No, move to Section C */}
    {formData.articulationConcerns.difficultyPronouncing === "no" && (
      <p className="text-sm text-gray-500">Please proceed to Section C.</p>
    )}

    {/* Conditional Rendering: Show only if 'yes' is selected */}
    {formData.articulationConcerns.difficultyPronouncing === "yes" && (
      <>
        {/* How well do the following people understand what your child is saying? */}
        <div className="pb-4">
          <label>
            How well do the following people understand what your child is
            saying?
          </label>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Yourself */}
            <SelectField
              label="Yourself"
              name="articulationConcerns.understoodBySelf"
              value={formData.articulationConcerns.understoodBySelf}
              onChange={handleChange}
              options={[
                { value: "", label: "-- Select --" },
                { value: "all-of-the-time", label: "All of the time" },
                { value: "most-of-the-time", label: "Most of the time" },
                { value: "some-of-the-time", label: "Some of the time" },
                { value: "very-little", label: "Very little" },
              ]}
            />

            {/* Others */}
            <SelectField
              label="Others"
              name="articulationConcerns.understoodByOthers"
              value={formData.articulationConcerns.understoodByOthers}
              onChange={handleChange}
              options={[
                { value: "", label: "-- Select --" },
                { value: "all-of-the-time", label: "All of the time" },
                { value: "most-of-the-time", label: "Most of the time" },
                { value: "some-of-the-time", label: "Some of the time" },
                { value: "very-little", label: "Very little" },
              ]}
            />

            {/* Peers */}
            <SelectField
              label="Peers"
              name="articulationConcerns.understoodByPeers"
              value={formData.articulationConcerns.understoodByPeers}
              onChange={handleChange}
              options={[
                { value: "", label: "-- Select --" },
                { value: "all-of-the-time", label: "All of the time" },
                { value: "most-of-the-time", label: "Most of the time" },
                { value: "some-of-the-time", label: "Some of the time" },
                { value: "very-little", label: "Very little" },
              ]}
            />
          </div>
        </div>

        {/* What sounds/words does your child have difficulty pronouncing? */}
        <TextAreaField
          label="What sounds/words does your child have difficulty pronouncing?"
          name="articulationConcerns.difficultWords"
          value={formData.articulationConcerns.difficultWords}
          onChange={handleChange}
        />
      </>
    )}
  </section>
);

export default ArticulationSection;
