import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { updateNote, createNote, deleteNote } from "../store/thunks/notesThunk";

const NoteModal = ({
    isOpen,
    onClose,
    dailyNote,
    onSave,
    onDelete,
    clinician,
    calendarDate,
}) => {
    const dispatch = useDispatch();
    const [noteText, setNoteText] = useState(
        dailyNote ? dailyNote.content : ""
    );
    const [error, setError] = useState(null);

    // Create a note, only 1 allowed per day
    const handleSave = async () => {
        try {
            // Set date to midnight in local time
            const currentDate = new Date(calendarDate);
            currentDate.setHours(0, 0, 0, 0); // Set to local midnight
            const localMidnight = currentDate.toISOString(); // Format to ISO string

            let result;

            if (dailyNote) {
                result = await dispatch(
                    updateNote({
                        id: dailyNote._id,
                        content: noteText,
                        date: localMidnight,
                        clinicianId: clinician._id,
                    })
                ).unwrap();
            } else {
                result = await dispatch(
                    createNote({
                        content: noteText,
                        date: localMidnight,
                        clinicianId: clinician._id,
                    })
                ).unwrap();
            }

            onSave(result);
            onClose();
        } catch (err) {
            console.error("Error saving note:", err); // Log the error
            setError(err.message || "An error occurred while saving the note.");
        }
    };

    const handleDelete = async () => {
        try {
            if (dailyNote) {
                await dispatch(deleteNote(dailyNote._id)).unwrap();
                onDelete();
                onClose();
            }
        } catch (err) {
            console.error("Error deleting note:", err);
            setError(
                err.message || "An error occurred while deleting the note."
            );
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            className="fixed inset-0 z-50 overflow-y-auto"
        >
            <div className="flex items-center justify-center min-h-screen">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                <div className="bg-white rounded-lg p-6 w-96 z-50">
                    <Dialog.Title className="text-lg font-semibold">
                        Edit Note
                    </Dialog.Title>
                    <textarea
                        className="mt-2 w-full h-24 border border-gray-300 rounded p-2"
                        value={noteText}
                        onChange={(e) => setNoteText(e.target.value)}
                    />
                    {error && (
                        <p className="text-red-500 text-sm mt-2">{error}</p>
                    )}
                    <div className="mt-4 flex justify-between">
                        <button
                            className="bg-red-500 text-white rounded px-4 py-2"
                            onClick={handleDelete}
                            disabled={!dailyNote}
                        >
                            Delete
                        </button>
                        <div>
                            <button
                                className="mr-2 text-gray-600"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-blue-500 text-white rounded px-4 py-2"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default NoteModal;
