import { createSlice } from '@reduxjs/toolkit';
import { fetchClinics, fetchClinicById, createClinic, updateClinic, deleteClinic } from '../thunks/clinicThunk';

export const clinicSlice = createSlice({
  name: 'clinics',
  initialState: {
    clinics: [],
    status: "idle",
    error: null,
    selectedClinic: null,
    pagination: {
        totalClients: 0,
        currentPage: 1,
        totalPages: 1,
        pageSize: 20,
      },
  },
  reducers: {
    setSelectedClinic: (state, action) => {
      state.selectedClinic = action.payload;
    },
    clearSelectedClinic: (state) => {
      state.selectedClinic = null;
    },
  }, // You might add other reducers here for local state changes
  extraReducers(builder) {
    builder
      // Fetch all clinics
      .addCase(fetchClinics.pending, (state) => {
        state.status = 'loading';
        state.error = null; // Clear any previous error     
      })
      .addCase(fetchClinics.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clinics = action.payload;
        if (action.payload.length > 0) {  
          state.selectedClinic = action.payload[0];
        }
        state.error = null; // Clear any previous error
      })
      .addCase(fetchClinics.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Fetch single clinic
      .addCase(fetchClinicById.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchClinicById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selectedClinic = action.payload;
        state.error = null;
      })
      .addCase(fetchClinicById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Create clinic
      .addCase(createClinic.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(createClinic.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clinics.push(action.payload);
        state.error = null;
      })
      .addCase(createClinic.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Update clinic
      .addCase(updateClinic.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateClinic.fulfilled, (state, action) => { 
        state.status = 'succeeded';
        const index = state.clinics.findIndex(clinic => clinic._id === action.payload._id);
        if (index !== -1) {
          state.clinics[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(updateClinic.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Delete clinic
      .addCase(deleteClinic.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(deleteClinic.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clinics = state.clinics.filter(clinic => clinic._id !== action.payload);
        state.error = null;
      })
      .addCase(deleteClinic.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setSelectedClinic, clearSelectedClinic } = clinicSlice.actions;
export default clinicSlice.reducer;
