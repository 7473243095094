import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { recordPasswordAccess } from "../../store/thunks/uploadsThunk"; // Adjust the path to your thunk
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

const FileExplorer = ({ files, clientId, onFileClick }) => {
  const [revealedPasswords, setRevealedPasswords] = useState({});
  const [loading, setLoading] = useState({});
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleFileClick = (fileName) => {
    onFileClick(fileName);
  };

  const handleTogglePassword = async (
    filePath,
    password,
    isPasswordProtected
  ) => {
    if (!isPasswordProtected) return;

    if (revealedPasswords[filePath]) {
      // Hide password if it's already revealed
      setRevealedPasswords((prev) => ({
        ...prev,
        [filePath]: null,
      }));
    } else {
      // Show password after successful password access recording
      setLoading((prev) => ({
        ...prev,
        [filePath]: true,
      }));

      try {
        const response = await dispatch(
          recordPasswordAccess({
            clientId,
            fileName: filePath,
            userId: user._id,
          })
        ).unwrap();

        if (response.success) {
          setRevealedPasswords((prev) => ({
            ...prev,
            [filePath]: password,
          }));
        }
      } catch (error) {
        console.error("Error recording password access:", error);
      } finally {
        setLoading((prev) => ({
          ...prev,
          [filePath]: false,
        }));
      }
    }
  };

  const renderRows = () => {
    return files.map((file, index) => {
      const fileType = file.path.split(".").pop();
      const folder = file.path.split("/")[0];
      const isPasswordProtected = file.isPasswordProtected;
      const isPasswordRevealed = revealedPasswords[file.path];
      const isLoading = loading[file.path];

      return (
        <tr key={file.path} className="border-b">
          <td className="py-2 px-4 flex items-center">
            <span
              className="text-blue-600 hover:underline cursor-pointer"
              onClick={() => handleFileClick(file.path)}>
              {file.path.split("/").pop()}
            </span>
            {isPasswordProtected && (
              <>
                <button
                  onClick={() =>
                    handleTogglePassword(
                      file.path,
                      file.password,
                      isPasswordProtected
                    )
                  }
                  disabled={isLoading}
                  className="ml-4 text-xs text-gray-500 hover:text-gray-700">
                  {isPasswordRevealed ? (
                    <EyeSlashIcon className="w-4 h-4" />
                  ) : isLoading ? (
                    "Loading..."
                  ) : (
                    <EyeIcon className="w-4 h-4" />
                  )}
                </button>
                {isPasswordRevealed && (
                  <span className="text-xs text-gray-500 ml-2">
                    {file.password}
                  </span>
                )}
              </>
            )}
          </td>
          <td className="py-2 px-4">{folder}</td>
          <td className="py-2 px-4">
            {new Date(file.createdAt).toLocaleString()}
          </td>
          <td className="py-2 px-4">
            {file.uploadedBy ? file.uploadedBy.name : "Unknown"}
          </td>
          <td className="py-2 px-4">{fileType}</td>
        </tr>
      );
    });
  };

  return (
    <div className="p-4">
      <table className="min-w-full bg-white">
        <thead>
          <tr className="w-full bg-gray-100 text-gray-600">
            <th className="text-left py-2 px-4">File Name</th>{" "}
            <th className="text-left py-2 px-4">Folder</th>
            <th className="text-left py-2 px-4">Upload Date</th>
            <th className="text-left py-2 px-4">Uploaded By</th>
            <th className="text-left py-2 px-4">File Type</th>
          </tr>
        </thead>
        <tbody>
          {files && files.length > 0 ? (
            renderRows()
          ) : (
            <tr>
              <td colSpan="5" className="text-center py-4">
                No files available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FileExplorer;
