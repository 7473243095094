import React from 'react';

const DurationSelector = ({ x, y, onSelect, onCancel }) => {
    const durations = [15, 30, 45, 60];

    return (
        <div 
            className="absolute bg-white shadow-lg rounded-md p-2 z-50"
            style={{ top: `${y}px`, left: `${x}px`, zIndex: 1100 }} // Ensure zIndex is high enough to overlay
        >
            {/* Optionally display the x and y coordinates on the UI for debugging */}
            <div className="text-xs text-gray-500 mb-2">
                Positioned at: X: {x}, Y: {y}
            </div>
            {durations.map(duration => (
                <div
                    key={duration}
                    className="cursor-pointer hover:bg-blue-100 p-2 text-sm"
                    onClick={() => onSelect(duration)}
                >
                    {duration} minutes
                </div>
            ))}
            <div className="cursor-pointer hover:bg-red-100 p-2 text-sm text-red-600" onClick={onCancel}>
                Cancel
            </div>
        </div>
    );
};

export default DurationSelector;
