import React, { useState, useEffect } from "react";
import InvoiceGenerator from "../../applicationUi/components/InvoiceGenerator";
import { useDispatch } from "react-redux";
import { createPayroll, updatePayroll } from "../../store/thunks/payrollThunk";
import { setSelectedAppointment } from "../../store/slices/appointmentsSlice";
import { setCurrentInvoice } from "../../store/slices/invoiceSlice";
import AppointmentSummary from "../../applicationUi/components/appointments/AppointmentSummary";

function PayrollDetails({
  payrollId,
  appointment,
  invoice,
  selectedPeriod,
  selectedPeriodDates,
  submitBy,
  payBy,
  refetchInvoices,
  onRefresh,
}) {
  console.log("invoice", invoice);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showInvoiceGenerator, setShowInvoiceGenerator] = useState(false);
  const [isWideSidePanelOpen, setIsWideSidePanelOpen] = useState(false);

  const [payPercentage] = useState(
    appointment.clinician.defaultPayPercentage || 45
  );
  const [calculatedAmount, setCalculatedAmount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (invoice) {
      setCalculatedAmount(((invoice.amount * payPercentage) / 100).toFixed(2));
    }
  }, [invoice, payPercentage]);

  const handleInvoiceClick = (invoice) => {
    setSelectedInvoice(invoice);
    dispatch(setCurrentInvoice(invoice));
    setShowInvoiceGenerator(true);
  };

  console.log("and then selectedInvoice", selectedInvoice);

  const handleAppointmentClick = () => {
    dispatch(setSelectedAppointment(appointment));
    setIsWideSidePanelOpen(true);
  };

  const handleCloseWideSidePanel = () => {
    setIsWideSidePanelOpen(false);
    onRefresh();
  };

  const handleSavePayroll = async () => {
    setIsLoading(true);
    setError(null);
    setSuccess(null);

    const payrollData = {
      appointmentId: appointment._id,
      clinicianId: appointment.clinician._id,
      invoiceId: invoice ? invoice._id : null,
      originalAmount: invoice ? invoice.amount : 0,
      percentReceived: appointment.clinician.defaultPayPercentage || 45,
      payrollAmount: parseFloat(
        (invoice.amount * appointment.clinician.defaultPayPercentage) / 100
      ).toFixed(2),
      payPeriod: selectedPeriod,
      payPeriodDates: selectedPeriodDates,
      submitBy: submitBy,
      payBy: payBy,
      status: "Pending",
    };

    console.log("payrollData", payrollData);

    try {
      const response = await dispatch(createPayroll(payrollData)).unwrap();

      // Assuming response has a message property
      setSuccess(response.message || "Payroll saved successfully!");
    } catch (err) {
      if (err.status === 409) {
        // Handle 409 Conflict specifically
        setError(
          err.message || "Payroll entry already exists for this appointment"
        );
      } else {
        // Handle other errors
        setError(err.message || "Failed to save payroll. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isCompleted =
    appointment.status.includes("Soap Notes Complete") &&
    invoice &&
    invoice.status.toLowerCase() === "paid";

  return (
    <div className="px-4 sm:px-0">
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          {/* Appointment Details */}
          <div className="my-4">
            <div className="flex items-start justify-between">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Appointment Information
              </h3>
            </div>

            <div
              className="bg-indigo-100 rounded-lg shadow-lg p-1 my-4 cursor-pointer hover:bg-indigo-300 transition duration-200"
              onClick={handleAppointmentClick}>
              <div className="bg-white px-4 py-6 rounded-tl rounded-tr sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Clinician
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                  {appointment.clinician
                    ? `${appointment.clinician.firstName} ${appointment.clinician.lastName}`
                    : "Clinician info unavailable"}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Client
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                  {appointment.client
                    ? `${appointment.client.firstName} ${appointment.client.lastName}`
                    : "Client info unavailable"}
                </dd>
              </div>
              <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Service
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                  {appointment.title || "Service info unavailable"}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 rounded-bl rounded-br sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Service Date
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                  {appointment.start
                    ? new Intl.DateTimeFormat("en-US", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      }).format(new Date(appointment.start))
                    : "Date unavailable"}
                </dd>
              </div>{" "}
              <div className="bg-gray-50 px-4 py-6 rounded-bl rounded-br sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Status
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                  {Array.isArray(appointment.status) ? (
                    appointment.status.join(", ")
                  ) : (
                    <span>{appointment.status}</span> // Fallback in case it's not an array
                  )}
                </dd>
              </div>
            </div>
          </div>

          {/* Invoice Details & Invoice Generator */}
          <div className="my-4">
            <div className="flex items-start justify-between">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Invoice Information
              </h3>
            </div>

            <div
              className="bg-indigo-100 rounded-lg shadow-lg p-1 my-4 cursor-pointer hover:bg-indigo-300 transition duration-200"
              onClick={() => handleInvoiceClick(invoice)}>
              {invoice ? (
                <>
                  <div className="bg-white px-4 py-6 rounded-tl rounded-tr sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Invoice ID
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                      {invoice.invoiceId}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Invoice Amount
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                      ${invoice.amount.toFixed(2)}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-6 rounded-bl rounded-br sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Invoice Status
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                      {invoice.status}
                    </dd>
                  </div>
                </>
              ) : appointment.status.some((status) =>
                  status.toLowerCase().includes("no fee")
                ) ? (
                <div className="bg-white px-4 py-6 rounded-lg text-center">
                  Invoice cannot be generated for "
                  {appointment.status.join(", ")}" status
                </div>
              ) : (
                <div className="bg-white px-4 py-6 rounded-lg text-center">
                  No invoice available
                </div>
              )}
            </div>

            {showInvoiceGenerator && selectedInvoice && (
              <InvoiceGenerator data={selectedInvoice} />
            )}
          </div>
        </dl>
      </div>

      {isWideSidePanelOpen && (
        <AppointmentSummary
          title="Appointment Summary"
          open={isWideSidePanelOpen}
          onClose={handleCloseWideSidePanel}
          // appointment={appointment}
        />
      )}

      {!isCompleted && (
        <div className="mt-6 flex justify-end">
          <button
            onClick={handleSavePayroll}
            className={`rounded-md px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
              isLoading || !(invoice && invoice.status.toLowerCase() === "paid")
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
            }`}
            disabled={
              isLoading || !(invoice && invoice.status.toLowerCase() === "paid")
            }>
            {isLoading ? "Saving..." : "Save to Payroll"}
          </button>

          {success && <div className="mt-4 text-green-600">{success}</div>}
          {error && <div className="mt-4 text-red-600">{error}</div>}
        </div>
      )}
    </div>
  );
}

export default PayrollDetails;
